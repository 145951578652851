import _ from "lodash"
import React from "react"
import { Row, Col } from "react-bootstrap"
import PropTypes from 'prop-types'
import { useWindowSize } from "../hooks/useWindowSize";

import { checkProp } from "../utils"

import "./Scenarios.scss"

// Climate Scenario detail tab contents.
const Scenarios = (props) => {
    const { analysis } = props
    const windowSize = useWindowSize();

    const onMobile = windowSize.width < 640

    // TODO: This generic header information below might need to change if the IPCC scenario changes
    return (
        <div className="Scenarios">
            <dl>
                <dt>IPCC Scenario</dt>
                <dd>Representative Concentration Pathway (RCP) 8.5</dd>
                <dt>Equivalent Range</dt>
                <dd>3 - 5.5°C anthropogenic warming</dd>
            </dl>

            <div className="model-metadata">
                <h5>Hazards</h5>

                {analysis.metadata && (
                    <div className="hazard-metadata-table">
                        {
                            onMobile
                                ? <Row className="hazard-metadata-header" key="header">
                                    <Col xs={5} className="hazard-header-cell" key="hazard">
                                        <h6>Hazard</h6>
                                    </Col>
                                    <Col xs={7} className="hazard-header-cell" key="detail">
                                        <h6>Details</h6>
                                    </Col>
                                </Row>
                                : <Row className="hazard-metadata-header" key="header">
                                    <Col xs={3} className="hazard-header-cell" key="hazard">
                                        <h6>Hazard</h6>
                                    </Col>
                                    <Col xs={3} className="hazard-header-cell" key="dataset">
                                        <h6>Dataset</h6>
                                    </Col>
                                    <Col xs={6} className="hazard-header-cell" key="detail">
                                        <h6>Details</h6>
                                    </Col>
                                </Row>
                        }
                        {Object.keys(analysis.metadata).map((haz) => (
                            <HazardMetadataRow
                                haz={haz}
                                metadata={analysis.metadata[haz]}
                                onMobile={onMobile}
                                key={haz}
                            />
                        ))}
                    </div>
                )}
                {!analysis.metadata && (
                    <div className="no-metadata">No hazard metadata available.</div>
                )}
            </div>

            {/* Explainer text */}
            <p className="small">
                The IPCC and other organisations produce emissions scenarios which are modelled
                by climate scientists. These models are used in Climate Risk Engine
                calculations. The scenario used in this analysis was chosen for asset
                stress-testing under a high emission, business-as-usual greenhouse gas emissions
                trajectory (RCP8.5) - which is also most consistent with current global
                emissions behaviour. A sea level rise projection has been selected which is at
                the upper end of IPCC allowances and about 90cm below the NOAA extreme sea level
                rise scenarios for 2100.
            </p>
            <p className="small">
                Neither XDI nor any other modelling organisations whose data has been used
                represents that any particular scenario is likely, or otherwise makes comment on
                the probability of its occurrence (which is inherently subject to future
                variables such as, for example, different greenhouse gas emission scenarios,
                atmospheric sensitivity and response, adaptation pathways, building standards
                and planning regimes). See Methods Outline for more information.
            </p>
        </div>
    )
}

Scenarios.propTypes = {
    analysis: PropTypes.object
}

// Quick function to format the sub-rows for projection/historical/context data.
const sub_row = (section, section_name, list_data, singleCell) => {
    const details = Object.keys(list_data).map((key) => (
        <React.Fragment key={key}>
            <dt>{key}</dt>
            <dd>{list_data[key]}</dd>
        </React.Fragment>
    ));

    return (
        <Row className={section + "-detail section-detail"}>
            {
                singleCell
                    ? <Col xs={12} className="detail-value">
                        <dl>
                            <dt>Dataset</dt>
                            <dd>{ section_name }</dd>
                            { details }
                        </dl>
                    </Col>
                    : <>
                        <Col xs={4} className="detail-name">
                            {section_name}
                        </Col>
                        <Col xs={8} className="detail-value">
                            <dl>{ details }</dl>
                        </Col>
                    </>
            }
        </Row>
    )
}

// Individual hazard metadata row
const HazardMetadataRow = (props) => {
    const { haz, metadata, onMobile } = props

    let haz_name = checkProp(metadata, "description") ? metadata.description : haz

    if (checkProp(metadata, "no_calc_reason")) {
        return (
            <Row className="hazard-metadata" key={haz}>
                <Col xs={onMobile ? 5 : 3} className="hazard-metadata-name">
                    <h6>{haz_name}</h6>
                </Col>
                <Col xs={onMobile ? 7 : 9} className="hazard-metadata-details">
                    {sub_row("failure", "Not Calculated", {
                        Reason: metadata["no_calc_reason"],
                    }, onMobile)}
                </Col>
            </Row>
        )
    }

    let details = {}
    if (checkProp(metadata, "projection")) {
        let mdata = metadata.projection
        details["projection"] = {}
        if (checkProp(mdata, "description")) {
            details["projection"]["Description"] = mdata.description
        }
        if (checkProp(mdata, "source")) {
            details["projection"]["Source"] = mdata.source
        }

        if (checkProp(metadata.projection, "metadata")) {
            mdata = metadata.projection.metadata
            if (checkProp(mdata, "model_type")) {
                details["projection"][mdata.model_type] = mdata.model_name
                if (mdata.model_type == "GCM" && checkProp(mdata, "GCM:ensemble_member")) {
                    details["projection"][mdata.model_type] +=
                        ", " + mdata["GCM:ensemble_member"]
                }

                if (checkProp(mdata, "downscaling_method")) {
                    // Add RCM/Statistical downscaling info
                    details["projection"][mdata.downscaling_method] = mdata.downscaling_model
                    if (mdata.downscaling_method == "RCM" && checkProp(mdata, "RCM:version")) {
                        details["projection"][mdata.downscaling_method] +=
                            ", " + mdata["RCM:version"]
                    }
                }
            } else if (checkProp(mdata, "GCM")) {
                // TODO: Delete this once new Ana is in place and Repo data is updated.
                console.log(`Asset ${haz} metadata is in an old format (GCM)`)
                details["projection"]["GCM"] = mdata.GCM
                if (checkProp(mdata, "ensemble_member")) {
                    details["projection"]["GCM"] += ", " + mdata.ensemble_member
                }
                details["projection"]["RCM"] = mdata.RCM
                if (checkProp(mdata, "RCM_version")) {
                    details["projection"]["RCM"] += ", " + mdata.RCM_version
                }
            }

            if (checkProp(mdata, "dataset")) {
                details["projection"]["Dataset"] = mdata.dataset
            }
            if (checkProp(mdata, "domain")) {
                details["projection"]["Domain"] = mdata.domain
            }
        }
    }

    if (checkProp(metadata, "historical")) {
        let mdata = metadata.historical
        details["historical"] = {}
        if (checkProp(mdata, "description")) {
            details["historical"]["Description"] = mdata.description
        }
        if (checkProp(mdata, "source")) {
            details["historical"]["Source"] = mdata.source
        }
    }

    if (checkProp(metadata, "context")) {
        details["context"] = {}
        // Context is a dictionary keyed by context type, with a sub-dictionary keyed by layer name.
        // We might want to add more detail here at some point
        _.forOwn(metadata.context, (cmeta_list) => {
            _.forOwn(cmeta_list, (mdata) => {
                details.context[mdata.description] = mdata.name
            })
        })
    }

    if (Object.keys(details).length === 0) {
        // Bad metadata for ${haz}
        return (
            onMobile
                ? <Row className="hazard-metadata" key={haz}>
                    <Col xs={4} className="hazard-metadata-name">
                        <h6>{haz_name}</h6>
                    </Col>
                    <Col xs={6} className="hazard-metadata-details">
                        Metadata missing. Re-run analysis to update metadata.
                    </Col>
                </Row>
                : <Row className="hazard-metadata" key={haz}>
                    <Col xs={3} className="hazard-metadata-name">
                        <h6>{haz_name}</h6>
                    </Col>
                    <Col xs={3} className="hazard-metadata-details">
                        Metadata missing
                    </Col>
                    <Col xs={6} className="hazard-metadata-details">
                        Re-run analysis to update metadata
                    </Col>
                </Row>
        )
    }

    return (
        <Row className="hazard-metadata" key={haz}>
            <Col xs={onMobile ? 5 : 3} className="hazard-metadata-name">
                <h6>{haz_name}</h6>
            </Col>
            <Col xs={onMobile ? 7 : 9} className="hazard-metadata-details">
                {details.projection &&
                    sub_row("projection", "Projection", details.projection, onMobile)}
                {details.historical &&
                    sub_row("historical", "Bias correction", details.historical, onMobile)}
                {details.context && sub_row("context", "Context data", details.context, onMobile)}
            </Col>
        </Row>
    )
}

HazardMetadataRow.propTypes = {
    haz: PropTypes.string,
    metadata: PropTypes.object,
    onMobile: PropTypes.bool
}


export default Scenarios
