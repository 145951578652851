import { Rectangle } from "cesium";

export async function fetchAddressesByCoordinates({ latitude, longitude }) {
    const url = `https://dev.virtualearth.net/REST/v1/Locations/${latitude},${longitude}?o=json&key=${crConstants.BingMapsKey}`
    const data = await jQuery.ajax({ url });
    console.log(url);
    let results = data?.resourceSets?.[0]?.resources || [];

    return results.reduce((results, { bbox, name }) => {
        const [south, west, north, east] = bbox;
        return [
            ...results,
            {
                destination: Rectangle.fromDegrees(west, south, east, north),
                displayName: name,
            }
        ];
    }, []);
}

 export function parseCoordinates(text) {
    text = text.trim();

    // Adjust for space-divided coordinates
    if (text.indexOf(",") === -1) {
        text = text.replace(" ", ",");
    }

    // Bail out if not in correct structure
    if (text.indexOf(",") === -1) {
        return null;
    }

    const segments = text.split(",");

    if (segments.length !== 2) {
        return null;
    }

    const latitude = parseFloat(segments[0]);
    const longitude = parseFloat(segments[1]);

    if (isNaN(latitude)
        || isNaN(longitude)
        || Math.abs(latitude) > 90
        || Math.abs(longitude) > 180
    ) {
        return null;
    }

    return { latitude, longitude };
}