import React, { useEffect } from "react"
import ReactGA from "react-ga"

import { hubSpotTracking } from "../../CR-Components/hubSpot.js"

const Terms = () => {
    useEffect(() => {
        if (crConstants.production) {
            ReactGA.modalview("/terms_and_conditions")
            window.document.title = "Terms and Conditions"
            hubSpotTracking("setPath", "/terms_and_conditions", "trackPageView")
        }
    }, [])

    return (
        <div className="info-page">
            <div className="container" style={{ paddingBottom: "10%", paddingTop: "4%" }}>
                <img
                    src="/static/images/xdi_logo.png"
                    width="30%"
                    align="right"
                    alt="XDI - The Cross Dependency Initiative"
                />
                <h1 lang="en-AU" className="western">
                    {" "}
                    Terms &amp; Conditions
                </h1>
                <h2>1 THIS IS A BINDING AGREEMENT </h2>
                <p>
                    between you (User, you) and XDI Pty Ltd trading, ABN 12 617 424 398 (us). These
                    Terms of Service (together with the documents referred to in them) set out the
                    terms and conditions on which you may access the Site and Services.
                </p>
                <p>
                    You should read these Terms of Service carefully and understand them before
                    using the Services. By using any of the Services, you confirm that you accept,
                    and will abide by, these Terms of Service. If you do not agree to these Terms of
                    Service, you must not use the Services.
                </p>
                <br />
                <h2>2 DEFINITIONS</h2>
                <p>
                    In these Terms of Service:
                    <br />
                    <b>EasyXDI </b>
                    means the software-based tool, accessible via the Site, as updated from time to
                    time.
                </p>
                <p>
                    <b>Asset </b>
                    means a geospatially fixed physical object – including land - physical asset
                    located at a physical location submitted.
                </p>
                <p>
                    <b>Climate Risk Engine </b>
                    means the software and servers that are provided by Climate Risk pty ltd and
                    accessed by the Site and provide computation services as updated from time to
                    time.
                </p>
                <p>
                    <b>Confidential Information </b>
                    of a disclosing party means: (a) information that is by its nature confidential;
                    <br />
                    (b) information that is designated by the disclosing party as confidential; and
                    <br />
                    (c) information the receiving party knows, or ought to know, is confidential,
                    and includes our proprietary tools and methodologies, but excludes information
                    which is or becomes generally available to the public (other than as a result of
                    a breach of these Terms of Service), is independently developed by the
                    recipient, or is required by law to be disclosed.
                </p>
                <p>
                    <b>Intellectual Property Rights </b>
                    means all intellectual property rights, including patents, copyright, registered
                    and unregistered designs, registered and unregistered trade marks, service
                    marks, trade names and any right to have Confidential Information kept
                    confidential (together with any application or right to apply for registration
                    of any such rights).
                </p>
                <p>
                    <b>ISP</b>&nbsp;means an internet service provider.
                </p>
                <p>
                    <b>Methodology Statement&nbsp;</b>
                    means the document referred to a ‘Scientific Methods – Outline’ located on this
                    Site that specifies algorithms, data set types and data selection methodologies
                    that are applied by the Climate Risk Engine, as updated from time to time.
                    <br />
                </p>
                <p>
                    <b>Modelling Outputs&nbsp;</b>
                    means any results, reports, data, materials or outputs of any kind generated by{" "}
                    <b>EasyXDI</b> through the application of the Climate Risk Engines.
                </p>
                <p>
                    <b>Product</b>
                    means any report, information, service or advice directly derived from the
                    Climate Risk Engines, or produced by Climate Risk Pty Ltd, and provided via this
                    Site.
                </p>
                <p>
                    <b>Property</b>means the real property such as land, a structure or fixed
                    physical asset located at a physical location submitted for analysis via this
                    Site by the User.
                    <br />
                </p>
                <p>
                    <b>Services</b> means the services we provide via the Site, including access to
                    the Climate Risk Engine.
                </p>
                <p>
                    <b>EasyXDI, Climate Risk Engines </b>
                    Engines and associated Modelling Outputs, reports and results, as more fully
                    described in the Scientific Methods on the EasyXDI Site.
                    <br />
                </p>
                <p>
                    <b>Site</b>&nbsp means the website located at https://easy.xdi.systems
                </p>
                <p>
                    <b>Third Party Data&nbsp;</b>means any data or other information of any kind
                    (whether historical, current or forward-looking in nature) that has not been
                    produced, generated or published by us or by Climate Risk Engines. This
                    includes, without limitation, the third party datasets referred to in the
                    Methodology Statement.
                </p>
                <p>
                    <b>We or Us </b>
                    means <b>XDI pty ltd.</b>.
                </p>
                <p>
                    <b>User</b> means any person who accesses or uses any of the Products or
                    Services on this site .
                </p>
                <p>
                    <b>User Account&nbsp;</b>means an account registered by a User on the Site in
                    accordance with section 2.
                </p>
                <h2>3 Registration of User Account</h2>
                <h3>3.1 Registration.&nbsp;</h3>
                <p>
                    You may have to register a User Account in order to be granted access to some of
                    the Services..
                </p>
                <h3>3.2 Accuracy.&nbsp;</h3>
                <p>
                    When registering as a User of the Site, you must provide us with accurate,
                    complete and up-to-date information (as required during the registration
                    process) so that we can provide our Services to you. If we do not receive this
                    information, we may not register you as a User of the Site. Unless otherwise
                    stated for specific services on the site, you cannot use the Products and
                    Services on this Site if you are not a registered User. It is your
                    responsibility to inform us of any changes to the information that you provide.
                    You may do this at any time by accessing your User Account on the Site.
                </p>
                <h3>3.3 Obligations.&nbsp;</h3>
                <p>
                    We may, in our absolute discretion, refuse registration, or close your User
                    Account, if:
                </p>
                <p>
                    (a) you breach these Terms of Service;
                    <br />
                    (b) you open or attempt to open a User Account using incomplete or falsified
                    details, or otherwise in a fraudulent or illegal way; or
                    <br />
                    (c) the closure of your User Account is necessary to comply with our obligations
                    at law.
                </p>{" "}
                <br />
                <h3>3.4 Responsibility and security.&nbsp;</h3>
                <p>You acknowledge and agree that:</p>
                <p>
                    (a) you are solely responsible for all activities that occur on your User
                    Account;
                    <br />
                    (b) your password is confidential; and
                    <br />
                    (c) you will keep your password secure and not disclose it to any other person,
                    nor will you permit your username and password to be given to or used by any
                    other person.
                </p>
                <p>You must notify us if the confidentiality of your password is compromised.</p>
                <h2>4 Services</h2>
                <h3>4.1 Products</h3>
                <p lang="en-AU" className="western" style={{ marginBottom: "0cm" }}>
                    We may, at our sole discretion, provide Users with access to the Products and
                    Services listed on our Site. You acknowledge and agree that:
                </p>
                <p>
                    (a) no forecast or prediction:&nbsp;the Products and Services do not purport to
                    generate, nor do the Modelling Outputs comprise, statements of fact, forecasts
                    or predictions. Rather, the Services comprise a ‘point in time' analysis of
                    physical, social, economic or commercial variables that are based on assumptions
                    made by You, Us or third parties and are inherently dynamic and variable over
                    time. We do not make or imply any representation regarding the actual
                    likelihood, risk or expectation of any future matter. To the extent that any
                    statements made or information provided in the provision of the Services might
                    be considered forward-looking in nature, they are subject to unknowns, risks,
                    variables and uncertainties that could cause actual results to differ
                    materially. You must not place reliance on any such forward-looking statements,
                    which reflect at set of assumptions only as of the date of modelling. We are in
                    no way obliged to revise, or publicly release the results of any revision to,
                    the Products or Services;
                </p>
                <p>
                    <br />
                    (b) no financial advice:&nbsp;the Services do not comprise, constitute or
                    provide, nor should they be relied upon as, investment or financial advice,
                    credit ratings, an advertisement, an invitation, a confirmation, an offer or a
                    solicitation, or recommendation, to buy or sell any security or other financial,
                    credit or lending product, or to engage in any investment activity, or an offer
                    of any financial service. The Services do not quantify risk to an Asset or
                    Property (or any part thereof), nor make any representation in regards to the
                    performance, strategy, prospects, creditworthiness or risk associated with any
                    investment, nor their suitability for purchase, holding or sale in the context
                    of any particular portfolio. Your access to the Products and Services, as well
                    as any Modelling Outputs, are provided with the understanding and expectation
                    that each User will, with due care, conduct their own investigation and
                    evaluation of each security or other instrument that is under consideration for
                    purchase, holding, maintenance, renewal, rebuild or sale;
                </p>
                <p>
                    <br />
                    (c) Third Party Data:&nbsp;the Products and Services will apply one or more sets
                    of Third Party Data, as set out in the Methodology Statement. The choice of any
                    Third Party Data should not be taken as any endorsement of those data set(s),
                    model(s) or scenario(s), nor any statement as to the accuracy or completeness of
                    those scenario(s)' methodologies or assumptions, nor as a general preference of
                    those scenarios over any other scientific data set(s), models or scenario(s).
                    The analysis provided by the Products and Services may be carried out using
                    other scientific data set(s), model(s) or scenario(s), and Users must form their
                    own view as to the emissions and global warming scenario(s), trajectory(s),
                    downscaling(s) and model(s) that are most appropriate to their circumstances. No
                    explicit or implicit assumption is made in relation to current or future
                    climate-related policies of any government at international, national or
                    sub-national level.
                </p>
                <h3>4.2 Our obligations</h3>
                <p>
                    (a) Accessing the Site.&nbsp;Our Site is made available free of charge on the
                    terms and conditions set out in these Terms of Service. However, you are
                    responsible for making all arrangements necessary for you to have access to our
                    Site.
                    <br />
                    (b) Site availability. We do not guarantee that our Site or any content on it,
                    or the Products and Services, will always be available. Access to our Site is
                    permitted on a temporary basis and we may suspend, withdraw, discontinue or
                    change all or any part of our Site without notice or liability. We will not be
                    liable to you if for any reasons our Site is unavailable at any time or for any
                    period. We do not warrant the validity and accuracy of information on the Site,
                    the Products and Services, third party websites linked to in the Site, or that
                    the Site will be kept up-to-date. You acknowledge that the Site is provided to
                    you over the internet and relies on unrelated third parties such as Site hosting
                    and telecommunications providers, the activities of which are outside of our
                    control. We will take reasonable steps to ensure that the Site, the Services,
                    and any information it holds on you, are kept secure. However, due to the nature
                    of the internet, we do not represent or warrant to you that:
                </p>
                <p>
                    (i) your use of the Site and/ or the Services will be uninterrupted, timely,
                    secure or free from error at all times, nor that it will provide specific
                    results from your use of the Site.;
                    <br />
                    (ii) any information obtained or downloaded by you as a result of your use of
                    the Site or the Services (including via third party website links) will be
                    accurate, reliable or free of viruses or contamination or destructive features;
                    and
                    <br />
                    (iii) there will not be any defects in the operation or functionality of the
                    Site or any other software provided to you as part of the Site.
                </p>
                <h3> 4.3 Your obligations.</h3>
                <p>
                    You acknowledge and agree that:
                    <br />
                    (a) to use and access the Services, you must have access to:
                </p>
                <p>
                    (i) a valid User Account on the Site; and
                    <br />
                    (ii) an account for the provision of ISP services with an ISP.
                    <br />
                    Your ISP (and not us) is responsible for the provision of ISP services pursuant
                    to any agreement between you and that ISP in respect of those services. You are
                    responsible, and are solely liable, for any account charges or other costs
                    incurred in relation to the ISP services, including the use of the Services
                    using the ISP services.
                </p>
                <p>
                    (b) Property: to use and access the Services, you must provide information
                    relevant to the application of the Products and Services on the Site including,
                    without limitation, the Property. You must ensure that all such information is
                    accurate and complete. Without limiting in any way the other disclaimers,
                    limitations and exclusions set out in these Terms of Service, we will not be
                    liable for any loss or damage arising from any inaccuracy, omission or other
                    defect in any such information.
                    <br />
                    (c) compliance: you must comply with our reasonable directions in relation to
                    your access to or use of the Services. In addition, you must use the Services in
                    good faith, and must at all times comply with these Terms of Service and all
                    applicable laws, statutes and regulations in all jurisdictions that relate to
                    your access to or use of the Site and the Services (including all applicable
                    privacy laws).
                    <br />
                    (d) no reliance: You must not make any decision relating to the purchase,
                    holding or sale of any property or physical asset in reliance on the outputs
                    generated by the Products and Services on this Site . You undertake that you
                    will, with due care, conduct your own investigation and evaluation of any
                    property under consideration for purchase, holding or sale.
                </p>
                <h2>5.Restrictions on Use</h2>
                <h3>5.1 Restrictions.&nbsp;</h3>
                <p>You must not, without our prior written permission:</p>
                <p>
                    (a) use the Services for any purpose other than as permitted by these Terms of
                    Service;
                    <br />
                    (b) remove or tamper with any copyright notices on the Site or the Services;
                    <br />
                    (c) disassemble, decompile, modify, reverse engineer, reproduce, store in a
                    database or retrieval system, distribute or create derivative works or
                    functionally equivalent software to, the Services (or part thereof) (except to
                    the extent permitted by any applicable law which is incapable of exclusion by
                    agreement between the parties);
                    <br />
                    (d) copy or download, in a systematic manner, any content, graphics, video, text
                    or animation from the Site or the Services, or communicate or otherwise
                    distribute such systematically obtained content, graphics, video, text or
                    animation; or
                    <br />
                    (e) incorporate the Services in any product to be made available commercially
                    (unless we expressly agree otherwise with you).
                </p>
                <h3>5.2 Disruption.&nbsp;</h3>
                <p>
                    You must not, directly or indirectly, introduce or permit the introduction of,
                    any virus, worm, Trojan or other malicious code into the Site or the Services,
                    or in any other manner whatsoever corrupt, degrade or disrupt the Site or the
                    Services. You must not attempt to gain unauthorised access to our Site, the
                    server on which our Site is stored or any server, computer or database connected
                    to our Site. You must not attack our Site via a denial-of-service attack or a
                    distributed denial-of service attack. By breaching this provision, you commit a
                    criminal offence under Australian Law, the Computer Misuse Act 1990. We will
                    report any such breach to the relevant law enforcement authorities and we will
                    co-operate with those authorities by disclosing your identity to them. In the
                    event of such a breach, your right to use our Site and the Services will cease
                    immediately.
                </p>
                <h3>5.3 Unlawful, unauthorised or dangerous use.&nbsp;</h3>
                <p>
                    You must not (nor permit a third party to) submit any data or information to the
                    Site or the Services, nor otherwise use the Site or the Services:
                </p>
                <p>
                    (a) to engage in any activity which breaches any law, infringes a third party’s
                    rights, or in a manner which interferes with the rights of any other person;
                    <br />
                    (b) to inappropriately use or infringe our Intellectual Property Rights
                    (including trade marks and copyright) or the Intellectual Property Rights of any
                    third party;
                    <br />
                    (c) in any way that is defamatory, obscene, misleading or deceptive or otherwise
                    illegal; or
                    <br />
                    (d) in any way that constitutes misuse, or resale or other commercial use, of
                    the Services or any associated materials.
                </p>
                <h3>5.4 Misuse of Services.&nbsp;</h3>
                <p>
                    Without limiting section 4.3, you must not use the Site or the Services for any
                    purpose other than as described under these Terms of Service.
                </p>
                <h2>6. INTELLECTUAL PROPERTY RIGHTS, CONFIDENTIALITY AND PRIVACY</h2>
                <h3>6.1 Site and Services.&nbsp;</h3>
                <p>
                    All rights, title and interest (including all Intellectual Property Rights) in
                    the Site and the Services vest absolutely in us and/or our third party
                    licensors. The only rights you have in respect of the Site and the Services are
                    those granted to you under these Terms of Service.
                </p>
                <h3>6.2 Modelling Outputs.&nbsp;</h3>
                <p>We:</p>
                <p>
                    (a) retain all rights, title and interest (including all Intellectual Property
                    Rights) in the Modelling Outputs; and
                    <br />
                    (b) grant the User a non-exclusive and perpetual licence to use the Modelling
                    Outputs.
                </p>
                <h3>6.3 Property.&nbsp;</h3>
                <p>
                    You grant us a perpetual, irrevocable, worldwide, non-exclusive, royalty-free
                    licence to retain any results or analysis and to analyse any data information
                    created on our systems, including for our commercial purposes.
                </p>
                <h3>6.4 Confidential Information.</h3>
                <p>
                    Neither party may disclose to any third party, without the prior written consent
                    of the other party, any Confidential Information received from the other party
                    for the purposes of providing or receiving the Services. This section 5.4 does
                    not prevent us from exercising the licence granted under section 5.3.
                </p>
                <h3>6.5 Permitted disclosure.&nbsp;</h3>
                <p>
                    Nothing in sections 5.3 or 5.4 limits our ability to disclose that we have
                    supplied Services to you.
                    <br />
                </p>
                <h3>6.6 Privacy</h3>
                <p>
                    Our privacy policy relating to information collected by us may be accessed by
                    following the link labelled 'Privacy Policy' on the Site, and forms part of
                    these Terms of Service.
                </p>
                <h2>7. Liability</h2>
                <h3>7.1 No guarantees.&nbsp;</h3>
                <p>
                    The Site, and all Services provided or made available through the Site, are made
                    available to you on an 'as is' basis, and to the maximum extent permitted by
                    law, we exclude all conditions, warranties, representations or other terms,
                    whether express or implied, which may apply to the Site and the Services.
                </p>
                <h3>7.2 Exclusion of Liability (non-consumers).</h3>
                <p>
                    To the extent that a User is not a consumer within the meaning of the Australian
                    Consumer Law (contained in Schedule 2 to the Competition and Consumer Act 2010
                    (Cth)) we exclude liability for all direct, indirect or consequential
                    liabilities, losses, damages, costs and expenses (including, without limitation,
                    loss of profits, sales, business or revenue, business interruption, loss of
                    anticipated savings, loss of business opportunity, damage to goodwill or
                    reputation, loss of data, or loss of use of data), and whether arising in
                    contract, tort (including negligence), for breach of statutory duty or
                    otherwise, suffered or incurred by any person:
                </p>
                <p>
                    (a) in connection with or in any way relating to the Site or the Services,
                    including:
                </p>
                <p>
                    (i) in connection with the use of, or reliance on, any Modelling Outputs;
                    <br />
                    (ii) in connection with any disruption to or unavailability or failure of the
                    Site or Services or interference with or damage to computer systems or other
                    electronic devices;
                    <br />
                    (iii) in connection with errors, omissions or inaccuracies contained in any
                    information published on the Site or supplied by us to you via email or
                    otherwise;
                    <br />
                    (iv) as a result of any fraudulent use, misuse or misappropriation of your User
                    Account; or
                    <br />
                    (v) as a result of any act committed by another person in connection with your
                    (or another User's) use of the Site or the Services;
                </p>
                <p>
                    (b) arising from any circumstance beyond our control; or
                    <br />
                    (c) otherwise under or in connection with these Terms of Service.
                </p>
                <h3>7.3 Indemnity (non-consumers).&nbsp;</h3>
                <p>
                    Subject to section 6.2 and to the extent that you are not a consumer within the
                    meaning of the Australian Consumer Law, you hold safe and indemnify us (and all
                    of our subsidiaries, employees, officers, agents and associated entities)
                    against all loss, actions, proceedings, cost and expenses (including legal
                    fees), claims and damages (Damages) arising directly or indirectly out of or in
                    connection with:
                </p>
                <p>
                    (a) your access to or use of the Site or the Services;
                    <br />
                    (b) a breach by you of these Terms of Service;
                    <br />
                    (c) any negligent or fraudulent act, error or omission by you;
                    <br />
                    (d) the use of your User Account by you or any third party;
                    <br />
                    (e) the publication or transmission of any information that you provide to us
                    (including any Damages sustained or incurred by us in connection with complaints
                    or claims relating to any such information);
                    <br />
                    or (f) any claim by a third party against us relating to your use of the Site or
                    Services (or use by any person who accesses the Site or Services using your User
                    Account).
                </p>
                <h3>7.4 Exclusion of liability (consumers).</h3>
                <p>
                    To the extent that you acquire goods or services from us as a consumer within
                    the meaning of the Australian Consumer Law (contained in Schedule 2 to the
                    Competition and Consumer Act 2010 (Cth)), you may have certain rights and
                    remedies (including, without limitation, consumer guarantee rights) that cannot
                    be excluded, restricted or modified by agreement. Nothing in section 6 operates
                    to exclude, restrict or modify the application of any implied condition or
                    warranty, provision, the exercise of any right or remedy, or the imposition of
                    any liability under the Australian Consumer Law or any other statute where to do
                    so would:
                </p>
                <p>
                    (a) contravene that statute; or
                    <br />
                    (b) cause any term of this agreement to be void,
                </p>
                <p>(each a Non-excludable Obligation).</p>
                <h3>7.5 Non-Excludable Obligations</h3>
                <p>
                    In relation to Non-excludable Obligations&nbsp;(other than a guarantee as to
                    title, encumbrances or quiet possession conferred by the Australian Consumer
                    Law), except for goods or services of a kind ordinarily acquired for personal,
                    domestic or household use or consumption (in respect of which our liability is
                    not so limited under this section 6.5), our liability to you for a failure to
                    comply with any Non excludable Obligation is limited to:
                </p>
                <p>
                    (a) in the case of services, the cost of supplying the services again or payment
                    of the cost of having the services supplied again; and
                    <br />
                    (b) in the case of goods, the cost of replacing the goods, supplying equivalent
                    goods or having the goods repaired, or payment of the cost of replacing the
                    goods, supplying equivalent goods or having the goods repaired.
                </p>
                <h3>7.6 Exception.&nbsp;</h3>
                <p>
                    Nothing in these Terms of Service excludes or limits our liability for death or
                    personal injury arising from our negligence, or our fraud or fraudulent
                    misrepresentation, or any liability that cannot be excluded or limited by law.
                </p>
                <h2>8. TERMINATION</h2>
                <h3>8.1 Termination for breach.</h3>
                <p>
                    Where you have breached any provision of these Terms of Service, and without
                    prejudice to any other rights or remedies, we may by written notice immediately
                    terminate these Terms of Service or the provision of any Services, without any
                    liability to you. This will include termination of your User Account.&nbsp;
                </p>
                <h3>8.2 Termination without cause.&nbsp;</h3>
                <p>
                    At our sole discretion and without prejudice to any other remedies available to
                    us, we may terminate your access to and use of the Site or the Services
                    (including your User Account) without cause by giving you 14 days' prior written
                    notice. You may close your User Account at any time via the Site.
                </p>
                <h3>8.3 Consequence of termination.&nbsp;</h3>
                <p>
                    On termination of these Terms of Service for any reason, or should you close
                    your User Account, you will no longer have access to your User Account (or any
                    data contained within your User Account), and subject to section 5.3, we will
                    erase any data contained within your User Account in accordance with our data
                    erasure policies and processes.&nbsp;
                </p>
                <h3>8.4 Survival.&nbsp;</h3>
                <p>
                    Section 6, this section 7, and section 8, survive termination of these Terms of
                    Service.
                </p>
                <h2>9. GENERAL</h2>
                <h3>9.1 Assignment.</h3>
                <p>
                    You may not assign your rights under these Terms of Service, or attempt or
                    purport to do so, without our prior written consent (which may be given or
                    withheld in our absolute discretion).
                </p>
                <h3>9.2 Force majeure.&nbsp; </h3>
                <p>
                    Neither party will be liable to the other for any failure to fulfil, or delay in
                    fulfilling, its obligations caused by circumstances outside of its reasonable
                    control (including illness of our consultants).
                </p>
                <h3>9.3 Governing law.&nbsp;</h3>
                <p>
                    These Terms of Service are governed by and interpreted in accordance with the
                    laws of the state of New South Wales, Australia, and all parties submit to the
                    exclusive jurisdiction of the courts of New South Wales.
                </p>
                <h3>9.4 Severability.&nbsp;</h3>
                <p>
                    If any provision, or part of a provision, of these Terms of Service is found to
                    be illegal or unenforceable it will be severed from the agreement, and the
                    remainder of these Terms of Service will be construed as if that provision or
                    part did not form part of these Terms of Service. The previous sentence will not
                    apply if the provision or part to be severed constitutes a material and
                    fundamental element of the agreement between the parties.
                </p>
                <h3>9.5 Variation.&nbsp;</h3>
                <p>
                    We reserve the right to revise and amend the Terms of Service in our discretion,
                    as follows:
                </p>
                <p>
                    (a) if we consider that the change is likely to benefit you or have a neutral or
                    minor detrimental impact on you, we may make any changes immediately without
                    notifying you except by publishing the amended Terms of Service (as applicable)
                    on the Site;
                    <br />
                    (b) if we consider that the change is likely to have a significant detrimental
                    impact on you, we will make the change after we have notified you of the change
                    (solely by using the email address you have provided) and will display a notice
                    on the Site describing the change.
                </p>
                <p>
                    Your continued use of the Site after an amendment will mean that you agree to
                    that amendment. You must stop using the Site if you do not agree to an
                    amendment.
                </p>
                <h3>9.6 Entire agreement.&nbsp;</h3>
                <p>
                    These Terms of Service are the entire agreement between the parties on its
                    subject matter and supersede any previous arrangements, agreements,
                    representations, understandings or statements (whether verbally, in writing or
                    in another format).
                </p>
            </div>
            <div
                className="footer"
                style={{ verticalAlign: "middle", textAlign: "center", marginTop: "5em" }}>
                <p>Brought to you by:</p>
                <img src="/static/images/cr_logo.png" width="20%" alt="Climate Risk Pty Ltd" />
                <img
                    src="/static/images/xdi_logo.png"
                    width="30%"
                    alt="XDI - The Cross Dependency Initiative"
                />
            </div>
        </div>
    )
}

export default Terms
