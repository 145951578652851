import React from "react"
import { Modal } from "react-bootstrap"
import classNames from "classnames"
import PropTypes from "prop-types"
import _ from "lodash"

import { deleteAnalysis, getAnalyses } from "../CoreFiles/analyses"
import { goHome, replaceLocationHashParams, parseHashParams } from "../CoreFiles/history"
import { getAnalysisRevisionTitle } from "../analyses"
import appState from '../../CR-Components/CoreFiles/state.js'

import { hubSpotTracking } from "../hubSpot.js"
import PurchaseModal from "../PurchaseModal/PurchaseModal.jsx"
import ThankYouPurchase from "../ThankYouPurchase/ThankYouPurchase.jsx"
import CopyLinkModal from "../CopyLinkModal/CopyLinkModal.jsx"
import "./AnalysisModal.scss"

class AnalysisModal extends React.Component {
    static propTypes = {
        analyses: PropTypes.object,
        analysisId: PropTypes.string,
        onSelect: PropTypes.func,
        show: PropTypes.bool,
        closeModal: PropTypes.func,
    }
    constructor(props) {
        super(props)
        const analyses = getAnalyses()
        const grouped = _.groupBy(
            Object.values(analyses),
            (analysis) => analysis.groupId || analysis._id
        )
        const count = Object.keys(grouped).length
        this.state = {
            count: count,
            grouped: grouped,
            showPurchaseModal: false,
            analysis: null,
            analysisAddress: null,
            analysisArchetype: null,
            purchased: false,
            showCopyLinkModal: false,
        }
    }

    /**
     *
     * Deletes saved analysis
     *
     * @param {number} index - analysis Index
     * @param {object} group - analysis Group ID
     * @param {string} id - analysis id
     */
    onClickDelete = async (index, group, id) => {
        await deleteAnalysis(id)
        console.log(this.state.grouped, index, group, id)
        let grouped = this.state.grouped
        grouped = _.omit(grouped, id)
        console.log(grouped)
        this.setState({
            grouped: grouped,
        })
    }

    // TODO: make this function work the same in easy/cv
    /**
     *
     * Initiates purchase from Modal
     *
     * @param {object} analysis - analysis to purchase
     */
    onClickPurchase = (analysis) => {
        // eslint-disable-next-line no-undef
        if (crConstants.ClimateValuation) {
            goHome()
            this.setState(
                {
                    analysisAddress: analysis.properties.address,
                    analysisArchetype: analysis.properties.archetype,
                    analysis: analysis,
                }
            )
        } else {
            this.setState({
                showPurchaseModal: true,
                analysisAddress: analysis.properties.address,
                analysisArchetype: analysis.properties.archetype,
                analysis: analysis,
            })
        }
        replaceLocationHashParams({
            analysisId: analysis._id,
            analysisAddress: analysis.properties.address,
            analysisArchetype: analysis.properties.archetype,
        })
    }

    // TODO add purchase: false to push hash?
    async handleCloseThanks() {
        replaceLocationHashParams({ thanks: false, purchase: true })
    }

    paymentCompleted = () => {
        this.setState({
            showPurchaseModal: false,
            analysis: null,
            purchased: true,
            //grouped: grouped
        })
        // TODO: add purchase: true?
        replaceLocationHashParams({ thanks: true, purchase: true })
    }

    updateAnalysisDetails = () => {
        const analyses = getAnalyses()
        const grouped = _.groupBy(
            Object.values(analyses),
            (analysis) => analysis.groupId || analysis._id
        )

        this.setState({
            grouped: grouped,
        })
    }

    handleCheckThanks() {
        const { thanks } = parseHashParams({ thanks })
        if (thanks) {
            return true
        }
        return false
    }

    async UNSAFE_componentWillReceiveProps(newProps) {
        this.updateAnalysisDetails()
    }

    /**
     *
     * Copies analysis shareable link to clipboard
     *
     * @param {string} id - analysis id
     */
    async onClickShare(id) {
        const el = document.createElement("textarea")
        // eslint-disable-next-line no-undef
        el.value = `${crConstants.domain}/?analysisId=` + id
        document.body.appendChild(el)
        el.select()
        document.execCommand("copy")
        document.body.removeChild(el)

        await this.setState({ showCopyLinkModal: true })

        setTimeout(() => {
            this.setState({ showCopyLinkModal: false })
        }, 1000)
    }

    render() {
        // eslint-disable-next-line no-undef
        const CV = `${crConstants.ClimateValuation}` === "true"
        const { analysisId, onSelect, show, closeModal } = this.props
        const {
            count,
            grouped,
            showPurchaseModal,
            analysis,
            analysisAddress,
            analysisArchetype,
            purchased,
            showCopyLinkModal,
        } = this.state

        var newGrouped = {}
        for (var x in grouped) {
            if (grouped[x][0]["adaptationOptions"] === undefined) {
                newGrouped = { ...newGrouped, [x]: grouped[x] }
            }
        }

        const thanks = this.handleCheckThanks()

        return (
            <Modal
                show={show}
                onHide={closeModal}
                size="lg"
                className="AnalysisModal"
                onEntered={() => {
                    window.document.title = "Saved Analyses"
                    hubSpotTracking("setPath", "/my-analyses", "trackPageView")
                }}>
                <Modal.Header className={CV ? "cv-modal-header" : ""} closeButton>
                    <Modal.Title>Saved Analyses</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    {showCopyLinkModal && (
                        <CopyLinkModal
                            show={showCopyLinkModal}
                            onHide={() => this.setState({ showCopyLinkModal: false })}
                        />
                    )}
                    {count == 0 && <div className="no-results">No analyses saved yet</div>}

                    {count > 0 &&
                        Object.values(newGrouped).map((group, groupId) => {
                            return (
                                <div className="row AnalysisModal" key={"group-" + group[0]._id}>
                                    <div className="my-2">
                                        <div className="analysis-group-header">
                                            <div style={{color: "#525252"}}>{group[0].title}</div>
                                            <div>{group[0].inputs.asset.properties.address}</div>
                                        </div>

                                        {group.map((analysis, index) => (
                                            <div
                                                key={analysis._id}
                                                className={classNames(
                                                    "analysis-group-item",
                                                    analysis._id == analysisId && "selected"
                                                )}>
                                                <span className="revision-text-secondary">
                                                    {getAnalysisRevisionTitle(
                                                        analysis,
                                                        index,
                                                        group.length
                                                    )}
                                                </span>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="my-2 button-container">
                                        {/* View Analysis Button */}
                                        <a
                                            className={`btn ${
                                                CV ? "btn-orange" : "btn-primary"
                                            }  text-white`}
                                            onClick={() => onSelect(group[0]._id, group[0])}>
                                            View Analysis
                                        </a>

                                        {/*share buttons not currently in use*/}
                                        {/*share button to share analysis*/}
                                        {/* <a
                                            className={`btn btn-secondary text-white ${
                                                CV ? "cv-btn-secondary" : " "
                                            } `}
                                            onClick={() => this.onClickShare(group[0]._id)}>
                                            <i className="material-icons">share</i>
                                        </a> */}

                                        {/* Delete button */}
                                        {!CV && (
                                            <React.Fragment>
                                                {group[0].payment && group[0].payment.date ? (
                                                    <a
                                                        className="btn btn-secondary text-white disabled"
                                                        disabled>
                                                        <i className="material-icons">delete</i>
                                                    </a>
                                                ) : (
                                                    <a
                                                        className={`btn btn-secondary text-white`}
                                                        onClick={() =>
                                                            this.onClickDelete(
                                                                groupId,
                                                                group[0]._id,
                                                                group[0].groupId
                                                            )
                                                        }>
                                                        <i className="material-icons">delete</i>
                                                    </a>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            )
                        })}

                    {showPurchaseModal && (
                        <PurchaseModal
                            onCancel={() => this.setState({ showPurchaseModal: false })}
                            analysisAddress={analysisAddress}
                            analysisArchetype={analysisArchetype}
                            paymentCompleted={this.paymentCompleted}
                            analysis={analysis}
                        />
                    )}

                    {thanks && purchased && !CV && (
                        <ThankYouPurchase onCancel={() => this.handleCloseThanks()} />
                    )}
                </Modal.Body>
            </Modal>
        )
    }
}

export default AnalysisModal
