import React from "react"
import autoBind from "react-autobind"
import { Modal } from "react-bootstrap"
// import DropIn from "braintree-web-drop-in-react"
import { DropIn } from "./DropIn.jsx"
import PropTypes from "prop-types"
import ReactGA from "react-ga"

import ElementsAccordion from "../UI-Utils/ElementsAccordion.jsx"
import { hubSpotTracking } from "../hubSpot.js"
import {
    saveAnalysis,
    confirmAirwallexAnalysisPurchase,
} from "../CoreFiles/analyses"
import { replaceLocationHashParams, goToAnalysisId } from "../CoreFiles/history"
import { once } from "lodash"

const PRICE = 1035

class PurchaseModal extends React.Component {
    static propTypes = {
        analysis: PropTypes.object,
        paymentCompleted: PropTypes.func,
        onCancel: PropTypes.func,
        analysisAddress: PropTypes.string,
        analysisArchetype: PropTypes.string,
    }

    instance
    _isMounted = false

    paypal = {
        flow: "checkout",
        amount: `${PRICE}`,
        currency: "USD",
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            error: null,
            paymentNonce: null,
            purchaseButtonText: "Confirm Payment Details",
            disabledPurchaseButton: true,
            showPurchaseButton: true,
            intentId: null,
        }
        autoBind(this)
    }
    /**
     *
     * @param {string} methodSelected
     */
    onPaymentOptionSelected(methodSelected) {
        if (methodSelected.paymentOption == "paypal") this.setState({ showPurchaseButton: false })
        else this.setState({ showPurchaseButton: true })
        let isPaymentMethodRequestable = this.instance.isPaymentMethodRequestable()
        this.handlePaymentMethodRequestable(isPaymentMethodRequestable)
    }

    /**
     *
     * @param {boolean} isPaymentMethodRequestable
     */
    async handlePaymentMethodRequestable(isPaymentMethodRequestable) {
        if (isPaymentMethodRequestable) {
            this.setState({
                purchaseButtonText: "Submit Purchase",
                disabledPurchaseButton: false,
                error: null,
                showPurchaseButton: true,
            })
        } else
            this.setState({
                purchaseButtonText: "Confirm Payment Details",
                disabledPurchaseButton: true,
            })
    }

    onNoPaymentMethodRequestable() {
        this.instance.clearSelectedPaymentMethod()
        this.handlePaymentMethodRequestable(false)
    }

    componentDidMount() {
        this._isMounted = true
        this.sendToPaypal()
    }
    componentWillUnmount() {
        this._isMounted = false
    }

    async sendToPaypal() {
        try {
            let analysis = this.props.analysis
            if (analysis && analysis.payment && analysis.payment.date) {
                this.setState({ loadingResults: false, purchase: false })
                replaceLocationHashParams({ thanks: false })
                goToAnalysisId(analysis._id)
                return
            }

            // This shouldn't be possible...
            if (!analysis) {
                console.log("You have reached an unreachable state!")
            }

            if (!analysis._id) {
                analysis = await saveAnalysis(analysis)
                goToAnalysisId(analysis._id)
            }

            if (this._isMounted === true) {
                this.setState({ purchase: false })
                if (!crConstants.ClimateValuation) {
                    ReactGA.event({
                        category: "Purchase",
                        action: "Infrastructure",
                        label: "Purchase",
                        value: PRICE,
                    })
                }
                hubSpotTracking("setPath", "/purchase-submit", "trackPageView")
                window.document.title = "Submit Purchase"
            }
        } catch (e) {
            if (this._isMounted === true) this.setState({ error: e })
        }
    }


    async confirmPaymentIntent() {
        try {
            let analysis = this.props.analysis
            if (analysis) {
                const result = await confirmAirwallexAnalysisPurchase(
                    analysis, this.state.intentId, true
                )
                if (result._id) {
                    this.props.paymentCompleted()
                } else {
                    this.setState({ error: result.message, loading: false })
                }
            }
        } catch (e) {
            // TODO: use throwEastException from utils here
            this.setState({ error: e, loading: false, disabledPurchaseButton: true })
        }
    }

    render() {
        const { onCancel, analysisAddress, analysisArchetype } = this.props
        const { properties, inputs, metadata } = this.props.analysis

        const {
            error,
        } = this.state

        return (
            <Modal
                show={true}
                onHide={onCancel}
                className="PurchaseModal"
                onEntered={() => {
                    window.document.title = "Purchase Summary"
                    hubSpotTracking("setPath", "/purchase", "trackPageView")
                }}>
                <Modal.Header closeButton>Purchase Summary</Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>
                            Purchase a commercial analysis for access to all EasyXDI's features:
                        </strong>
                    </p>
                    <ul>
                        <li>Commercial Use</li>
                        <li>Asset Customisation</li>
                        <li>Configure asset life and analyisis time span</li>
                        <li>View individual hazard contribution to risk rating</li>
                        <li>Printable report</li>
                    </ul>
                    <p>
                        An EasyXDI report contains all the information you have seen in your
                        analysis results with additional information such as maps, explanations and
                        definitions. Just log in to EasyXDI to view or print your purchased report.
                    </p>
                    <p className="archetypeBlurb">
                        The analysis has been performed using the following specifications. These
                        settings can be changed to closer match those of the actual asset. To access
                        this option you will need to purchase a commercial report. Once payment is
                        complete, settings can be reconfigured twice.
                    </p>
                    <div className="addressBox">
                        <h5 className="address">{analysisAddress}</h5>
                        <div className="design-specs">
                            <h5>{properties.archetype}</h5>
                            <dl>
                                <dt>
                                    Wind speed design threshold:{" "}
                                    <span className="design-list">
                                        1 in {Math.round(1 / inputs.asset.properties.windThreshold)} year
                                    </span>
                                </dt>
                                <dt>
                                    Heat threshold:{" "}
                                    <span className="design-list">
                                        {metadata.heat.threshold_temperature ? metadata.heat.threshold_temperature : inputs.asset.properties.heatThreshold}°C
                                    </span>
                                </dt>
                                <dt>
                                    Ground height:{" "}
                                    <span className="design-list">
                                        {inputs.asset.properties.heightAboveGround} metres
                                    </span>
                                </dt>
                            </dl>
                        </div>

                        <div className="elements">
                            <ElementsAccordion
                                elementMaterials={inputs.asset.properties.elementMaterials}
                                analysisArchetype={analysisArchetype}
                            />
                        </div>
                    </div>

                    <h5 className="total">
                        Commercial Report: <span className="usd">USD</span>${PRICE}
                    </h5>
                    <div className="payments">
                        <DropIn
                            id="purchase-modal-drop-in"
                            onIntent={({ intent_id }) => this.setState({
                                intentId: intent_id,
                            })}
                            onSuccess={once(this.confirmPaymentIntent)}
                        />
                    </div>

                    {!!error && <div className="error">{error}</div>}
                </Modal.Body>
            </Modal>
        )
    }
}

export default PurchaseModal
