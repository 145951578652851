import React, { useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import PropTypes from "prop-types"
import ReactGA from "react-ga";

import "./Ratings.scss"

const Ratings = (props) => {

    useEffect(() => {
        if (crConstants.production) ReactGA.modalview("/analysis-result/ratings-tab")
    }, [])

    // TODO: These functions are duplicated throughout the codebase.
    // Move them all to single functions in CR-Components
    /**
     * Calculates rating at a given year
     *
     * @param {string} year - Year of of rating
     *
     * @returns {string} - A, B, or C
     */
    const calculateRating = (year) => {
        const yearIndex = year ? year - props.analysis.inputs.scenario.startYear : 0
        const riskFraction = 100 * props.analysis.properties.totalRisk[yearIndex]

        if (riskFraction < 0.2) return "A"
        if (riskFraction < 1) return "B"
        return "C"
    }

    /**
     * Calculates the year the highest risk is reached in
     * the analysis
     *
     * @returns {number} - Calendar year of highest rating
     */
    const calcHighestYear = () => {
        const totalRisk = props.analysis.properties.totalRisk
        const highestReached = Math.max(...totalRisk)

        const yearIndex = totalRisk.indexOf(highestReached)

        return props.analysis.inputs.scenario.startYear + yearIndex
    }

    const { analysis, fieldDefaultValues } = props
    const { assetLifeEnd } = fieldDefaultValues
    const presentRating = calculateRating()
    const highestYear = calcHighestYear()
    const selectedYearRating = calculateRating(highestYear)

    const ratingPhrase = {
        A: "Low",
        B: "Medium",
        C: "High",
    }

    return (
        <div className="Ratings">
            <header>
                <h2>XDI Asset Rating</h2>
            </header>

            <Row>
                <Col>
                    <p>
                        <strong>
                            A representative asset ({analysis.inputs.archetype.service}:{" "}
                            {analysis.inputs.archetype.name}) at the selected location has a{" "}
                            <strong>{ratingPhrase[selectedYearRating]}</strong> XDI Risk Rating over
                            the expected asset lifetime (ending {assetLifeEnd}). The highest level
                            of risk (%VAR) is reached in {highestYear}.
                        </strong>
                    </p>
                </Col>
            </Row>

            <Row className="rating-letter-row">
                <Col>
                    <div className="rating-header">
                        <div className={`rating now rating-colour-${presentRating.toLowerCase()}`}>
                            <div className="rating-letter">{presentRating}</div>
                            <div className="rating-year">{analysis.inputs.scenario.startYear}</div>
                        </div>
                        <div className="rating-separator">→</div>
                        <div className={`rating future rating-colour-${selectedYearRating.toLowerCase()}`}>
                            <div className="rating-letter">{selectedYearRating}</div>
                            <div className="rating-year">{highestYear}</div>
                        </div>
                    </div>
                    <p>For more details on the causes of risk see the Breakdown tab.</p>
                </Col>
                <Col>
                    <div className="rating-explainer-wrapper">
                        <p>
                            XDI Ratings are calculated from the annual Value At Risk (annual average
                            cost of damage divided by asset replacement cost) as follows:
                        </p>
                        <div className="rating-explainer">
                            <div>
                                <div className="rating-explainer-letter rating-colour-a">A</div>
                                <div className="rating-explainer-desc">
                                    {" "}
                                    <strong>Low Risk = %VAR &lt; 0.2</strong> May be insurable at
                                    reasonable cost.
                                </div>
                            </div>
                            <div>
                                <div className="rating-explainer-letter rating-colour-b">B</div>
                                <div className="rating-explainer-desc">
                                    {" "}
                                    <strong>Moderate Risk = 0.2 &lt; %VAR &lt; 1</strong> May lead
                                    to higher insurance costs.{" "}
                                </div>
                            </div>
                            <div>
                                <div className="rating-explainer-letter rating-colour-c">C</div>
                                <div className="rating-explainer-desc">
                                    {" "}
                                    <strong>High Risk = %VAR &gt; 1 </strong> Insurance may be high
                                    cost or unavailable unless adaptation actions are undertaken.
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <p>
                        The XDI Ratings follow the Federal Emergency Management Agency (FEMA)
                        designations that are used for pricing a large number of insurance premiums
                        in the USA. XDI has used FEMA's probability method and extended it to
                        include a wider set of hazards. The XDI Rating results therefore provide an
                        insight into the possible longer-term availability and cost of insurance.
                        For any real asset, availability and costs should be obtained from a
                        commercial insurance provider.
                    </p>
                </Col>
            </Row>
        </div>
    )
}

Ratings.propTypes = {
    analysis: PropTypes.object.isRequired,
    fieldDefaultValues: PropTypes.object.isRequired,
}

export default Ratings
