import React, { useEffect } from "react"
import PropTypes from "prop-types"
import ReactGA from "react-ga";

import {
    DrawMaxVARBreakdown,
    DrawAnnualVARBreakdown,
    DrawMaxFPBreakdown,
    DrawAnnualFPBreakdown,
    DrawPieVARBreakdown,
    DrawPieFPBreakdown,
} from "../../CR-Components/Charts/Breakdown-Charts.jsx"

import "./HazardBreakdown.scss"


const HazardBreakdown = (props) => {
    let { analysis, purchased, startYear, endYear } = props

    const hazards = analysis.inputs.scenario.hazards

    useEffect(() => {
        if (crConstants.production) ReactGA.modalview("/analysis-result/hazard-breakdown-tab")
    }, [])
    
    if (!purchased) {
        // TODO: Replace results with demo values
        // (don't change the analysis object provided in props!)
        analysis = {
            ...analysis,
            properties: {
                ...analysis.properties,
                riskHazards: [],
                failureHazards: [],
            },
        }
        for (let yi = 0; yi <= endYear - startYear; yi++) {
            const yearVals = []
            for (let hi = 0; hi < hazards.length; hi++) {
                yearVals.push(Math.pow(0.05 + yi * 0.002 + hi * 0.02, 2))
            }
            analysis.properties.riskHazards.push(yearVals)
            analysis.properties.failureHazards.push(yearVals)
        }
    }

    return (
        <div className="HazardBreakdown">
            <div className="tab-content">
                <header>
                    <h2>Maximum Value at Risk (VAR%) to Date, All Hazards</h2>
                </header>
                <p>
                    This chart shows the contribution of each hazard to the Maximum Value At Risk %.
                </p>
                <p>
                    <strong>Note:</strong>Our charts are interactive! Click on the legend to turn
                    Hazards on and off.
                </p>
            </div>

            <div className="chartContainer">
                {!purchased && <div className="demo-hazard-indicator">Example only</div>}

                <DrawMaxVARBreakdown
                    riskHazards={analysis.properties.riskHazards}
                    startYear={startYear}
                />
            </div>

            <div className="tab-content">
                <header>
                    <h2>Annual VAR%, All Hazards</h2>
                </header>
                <p>
                    This chart shows the contribution each hazard makes to Value At Risk % for each
                    year.
                </p>
            </div>

            <div className="chartContainer">
                {!purchased && <div className="demo-hazard-indicator">Example only</div>}

                <DrawAnnualVARBreakdown
                    riskHazards={analysis.properties.riskHazards}
                    startYear={startYear}
                />
            </div>

            <div className="tab-content">
                <header>
                    <h2>Pie Charts for Annual VAR% by hazard for Current Year, 2050, {endYear}</h2>
                </header>
                <p>
                    These charts show annual Value At Risk % divided into each hazard for three time
                    periods.
                </p>
            </div>

            <div className="pieContainer">
                {!purchased && <div className="demo-hazard-indicator">Example only</div>}

                <div className="pieChart">
                    <DrawPieVARBreakdown
                        title="Current"
                        year={startYear}
                        riskHazards={analysis.properties.riskHazards}
                        startYear={startYear}
                    />
                </div>

                <div className="pieChart">
                    <DrawPieVARBreakdown
                        year={2050}
                        riskHazards={analysis.properties.riskHazards}
                        startYear={startYear}
                    />
                </div>

                <div className="pieChart">
                    <DrawPieVARBreakdown
                        year={endYear}
                        riskHazards={analysis.properties.riskHazards}
                        startYear={startYear}
                    />
                </div>
            </div>

            <div className="tab-content">
                <header>
                    <h2>Maximum Failure Probability to Date, All Hazards</h2>
                </header>
                <p>
                    This chart shows the contribution of each hazard to the Maximum Failure
                    Probability.
                </p>
            </div>

            <div className="chartContainer">
                {!purchased && <div className="demo-hazard-indicator">Example only</div>}

                <DrawMaxFPBreakdown
                    failureHazards={analysis.properties.failureHazards}
                    startYear={startYear}
                />
            </div>

            <div className="tab-content">
                <header>
                    <h2>Annual Failure Probability, All Hazards</h2>
                </header>
                <p>
                    This chart shows the contribution each hazard makes to the annual failure
                    probability.
                </p>
            </div>

            <div className="chartContainer">
                {!purchased && <div className="demo-hazard-indicator">Example only</div>}

                <DrawAnnualFPBreakdown
                    failureHazards={analysis.properties.failureHazards}
                    startYear={startYear}
                />
            </div>

            <div className="tab-content">
                <header>
                    <h2>
                        Pie Charts for Annual Failure
                        Probability by hazard for Current Year, 2050,{" "}
                        {endYear}
                    </h2>
                </header>
                <p>
                    These charts show annual Failure Probability divided into each hazard for three
                    time periods.
                </p>
            </div>

            <div className="pieContainer">
                {!purchased && <div className="demo-hazard-indicator">Example only</div>}

                <div className="pieChart">
                    <DrawPieFPBreakdown
                        title="Current"
                        year={startYear}
                        failureHazards={analysis.properties.failureHazards}
                        startYear={startYear}
                    />
                </div>

                <div className="pieChart">
                    <DrawPieFPBreakdown
                        year={2050}
                        failureHazards={analysis.properties.failureHazards}
                        startYear={startYear}
                    />
                </div>

                <div className="pieChart">
                    <DrawPieFPBreakdown
                        year={endYear}
                        failureHazards={analysis.properties.failureHazards}
                        startYear={startYear}
                    />
                </div>
            </div>
        </div>
    )
}
HazardBreakdown.propTypes = {
    analysis: PropTypes.object,
    purchased: PropTypes.bool,
    startYear: PropTypes.number,
    endYear: PropTypes.number,
}

HazardBreakdown.defaultProps = {
    startYear: 2020,
    endYear: 2100,
}

export default HazardBreakdown
