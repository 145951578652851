import React, { useEffect } from "react"
import ReactGA from "react-ga"

import { hubSpotTracking } from "../../CR-Components/hubSpot.js"

const DataSources = () => {
    useEffect(() => {
        if (crConstants.production) {
            ReactGA.modalview("/data_sources")
            window.document.title = "Data Sources"
            hubSpotTracking("setPath", "/data_sources", "trackPageView")
        }
    }, [])

    return (
        <div className="info-page">
            <div className="container" style={{ paddingBottom: "10%", paddingTop: "4%" }}>
                <img
                    src="/static/images/xdi_logo.png"
                    width="30%"
                    align="right"
                    alt="XDI - The Cross Dependency Initiative"
                />

                <h1>References</h1>
                <ul className="list-unstyled">
                    <li>
                        {" "}
                        Abdus Salam International Centre for Theoretical Physics, Trieste, Italy
                    </li>
                    <li>
                        {" "}
                        Adapt NSW, NSW Government Office of Environment and Heritage, Australia
                    </li>
                    <li>
                        {" "}
                        Antarctic Climate and Ecosystems Cooperative Research Centre, Hobart,
                        Australia
                    </li>
                    <li>
                        {" "}
                        Australian Research Council Centre of Excellence for Climate System Science
                        and Climate Change Research Centre, University of New South Wales, Sydney,
                        Australia
                    </li>
                    <li>
                        {" "}
                        School of Biological, Earth and Environmental Sciences, University of New
                        South Wales, Sydney, Australia
                    </li>
                    <li>
                        {" "}
                        Meteorological Service of Canada, Department of Environment and Climate
                        Change, Canada
                    </li>
                    <li>
                        {" "}
                        Australian Bureau of Agricultural and Resource Economics and Sciences,
                        Canberra, Australia
                    </li>
                    <li> Australian Bureau of Meteorology, Canberra, Australia</li>
                    <li> Department of Agriculture and Water Resources, Canberra, Australia</li>
                    <li> Australian Government Department of Environment, Canberra, Australia</li>
                    <li> Australian Institute of Criminology (AIC), Canberra, Australia</li>
                    <li>
                        {" "}
                        Australian Rainfall and Runoff (ARR), Geosciences Australia, Canberra,
                        Australia
                    </li>
                    <li>
                        {" "}
                        Australian Soil Resource Information System (ASRIS), CSIRO, Canberra,
                        Australia
                    </li>
                    <li> BHP Group Limited, Melbourne, Australia</li>
                    <li>
                        {" "}
                        British Oceanographic Data Centre, National Science and Research Council, UK
                    </li>
                    <li> Bundaberg Regional Council, Australia</li>
                    <li>
                        {" "}
                        Bundesamt fur Seeschifffahrt und Hydrographie und Hydrographie, Germany
                    </li>
                    <li> Bushfire Cooperative Research Centre, Melbourne, Australia</li>
                    <li>
                        {" "}
                        Canadian Centre for Climate Modelling and Analysis, Environment and Climate
                        Change Canada, University of Victoria, Canada
                    </li>
                    <li> Canadian Electrical Association</li>
                    <li> Canadian Meteorological and Oceanographic Society</li>
                    <li>
                        {" "}
                        Center for Climate Systems Research, Columbia University Earth Institute,
                        New York, USA
                    </li>
                    <li>
                        {" "}
                        Center for Marine Technology and Engineering, Technical University of
                        Lisbon, Portugal
                    </li>
                    <li>
                        {" "}
                        Center for Sustainability and the Global Environment, Nelson Institute for
                        Env. Studies University of Wisconsin, USA
                    </li>
                    <li>
                        {" "}
                        Centre for Australian Weather and Climate Research, a partnership between
                        the Bureau of Meteorology and CSIRO, Melbourne, Australia
                    </li>
                    <li>
                        {" "}
                        Centre for Environmental Risk Management of Bushfires, University
                        Wollongong, Australia
                    </li>
                    <li>
                        {" "}
                        Civil and Environmental Engineering, The University of Western Ontario,
                        Canada
                    </li>
                    <li>
                        {" "}
                        Climate and Atmospheric Science Branch, NSW Office of Environment and
                        Heritage, Australia
                    </li>
                    <li>
                        {" "}
                        Climate Change Research Centre, University of New South Wales, Sydney,
                        Australia
                    </li>
                    <li> Climate Disclosure Standards Board (CDSB), London UK</li>
                    <li> Climate Impacts Group, University of Washington, USA</li>
                    <li>
                        {" "}
                        Climate Research Division, Environment and Climate Change Canada, University
                        of Victoria, Canada
                    </li>
                    <li>
                        {" "}
                        Coastal and Marine Unit, Office of Environment and Heritage, Newcastle,
                        Australia
                    </li>
                    <li> College of Marine Science, University of South Florida, Australia</li>
                    <li> Commonwealth Bank of Australia (CBA)</li>
                    <li>
                        {" "}
                        Coordinated Regional Climate Downscaling Experiment (CORDEX), World Climate
                        Research Programme
                    </li>
                    <li>
                        {" "}
                        Crompton Risk Frontiers, Natural Hazards Research Centre, Macquarie
                        University, Sydney, Australia
                    </li>
                    <li> CSIRO Marine and Atmospheric Research, Canberra, Australia</li>
                    <li> Danish Meteorological Institute, Denmark</li>
                    <li> Department of Applied Statistics, Johannes Kepler University Linz</li>
                    <li>
                        {" "}
                        Department of Atmospheric & Hydrologic Sciences, St. Cloud State University
                    </li>
                    <li>
                        {" "}
                        Department of Atmospheric and Environmental Sciences, State University of
                        New York, Albany, USA
                    </li>
                    <li>
                        {" "}
                        Department of Building Engineering, University of Manchester Institute of
                        Science and Technology, UK
                    </li>
                    <li>
                        {" "}
                        Department of Civil and Environmental Engineering, University of Western
                        Ontario, Canada
                    </li>
                    <li>
                        {" "}
                        Department of Civil Engineering, Institute of Science and Technology, Inonu
                        University, Engineering Faculty, Malatya, Turkey
                    </li>
                    <li>
                        {" "}
                        Department of Civil, Mining and Environmental, University of Wollongong,
                        Australia
                    </li>
                    <li>
                        {" "}
                        Department of Earth & Planetary Sciences, Rutgers Energy Institute and
                        Institute of Earth, Ocean & Atmospheric Sciences, Rutgers University–New
                        Brunswick, New Brunswick, USA
                    </li>
                    <li>
                        {" "}
                        Department of Earth and Planetary Science, University of California, USA
                    </li>
                    <li>
                        {" "}
                        Department of Forest and Natural Resources Management, State University of
                        New York, Albany, USA
                    </li>
                    <li> Department of Geographical Sciences, University of Maryland, USA</li>
                    <li> Department of Geosciences Oregon State University, USA</li>
                    <li> Department of Physics, University of Toronto, Canada</li>
                    <li> Department of Statistical Science, Duke University, USA</li>
                    <li> Earth System Dynamics</li>
                    <li> EIT Climate KIC, European Union</li>
                    <li> Encyclopaedia of Ocean Sciences</li>
                    <li>
                        {" "}
                        Environmental and Mining Engineering and the UWA Oceans Institute, The
                        University of Western Australia, Perth, Australia
                    </li>
                    <li> Environmental Finance</li>
                    <li> Environmental Hydraulics Institute, Universidad de Cantabria, Spain</li>
                    <li> Environmental Research Letters</li>
                    <li> European Bank for Reconstruction and Development</li>
                    <li> European Sea-Level Service, European Environment Agency</li>
                    <li> Faculty of Economics, University of Tokyo</li>
                    <li> Finnish Meteorological Institute</li>
                    <li> Fire and Biodiversity Consortium, Queensland, Australia</li>
                    <li>
                        {" "}
                        Geographic Information Science Center of Excellence, South Dakota State
                        University, USA
                    </li>
                    <li> Global Hydrology Research Center (GHRC), NASA, USA</li>
                    <li> Global Land and Analysis Discovery (GLAD), University of Maryland, USA</li>
                    <li> Goddard Earth Sciences Technology and Research, Columbia, USA</li>
                    <li>
                        {" "}
                        Department of Planning, Transport and Infrastructure, Government of South
                        Australia
                    </li>
                    <li>
                        {" "}
                        Department of Fire and Emergency Services, Government of Western Australia
                    </li>
                    <li> Hawkesbury Institute for the Environment, Western Sydney University</li>
                    <li>
                        {" "}
                        Hydrological Sciences Laboratory, Goddard Space Flight Center, Greenbelt,
                        Maryland, USA
                    </li>
                    <li> Hydrology and Water Resources Symposium, Sydney, Australia</li>
                    <li> Icelanic Coast Guard, Reykjavic, Iceland</li>
                    <li> Independent Hospital Pricing Authority, Australian Government</li>
                    <li> Institute for Atmospheric and Climate Science, ETH Zurich</li>
                    <li>
                        {" "}
                        Institute of Mathematics, School of Basic Sciences, Swiss Federal Institute
                        of Technology
                    </li>
                    <li>
                        {" "}
                        Bidston Observatory, Institute of Oceanographic Sciences, Birkenhead,
                        Merseyside.
                    </li>
                    <li> Instituto Espanol de Oceanografia, Spain</li>
                    <li> Intergovernmental Panel on Climate Change (IGCC)</li>
                    <li> International Center for Climate and Environment Sciences, China</li>
                    <li> Japan Meteorological Agency</li>
                    <li> Johannes Kepler University Linz, Austria</li>
                    <li> Lancaster Environment Centre, Lancaster University, UK</li>
                    <li> Manly Hydraulics Laboratory (MHL), NSW Government</li>
                    <li> Marine Environmental Data Section - Canada</li>
                    <li> McGill University, Montreal, Canada</li>
                    <li> NASA Land Use and Land Use Change Program, University of Maryland</li>
                    <li> National Academy of Sciences</li>
                    <li> National Center for Atmospheric Research, Boulder, Colorado</li>
                    <li>
                        {" "}
                        National Center for Biotechnology Information (NCBI), US National Library of
                        Medicine
                    </li>
                    <li> National Centers for Environmental Prediction (NCEP) NOAA, USA</li>
                    <li> National Centre for Atmospheric Science, University of Leeds, UK</li>
                    <li>
                        {" "}
                        National Oceanic and Atmospheric Administration, Center for Operational
                        Oceanographic Products and Services, Silver Spring, MD, USA
                    </li>
                    <li> Natural Resources Canada</li>
                    <li> NOAA Air Resources Laboratory, Silver Spring, Maryland, USA</li>
                    <li>
                        {" "}
                        Northeast Climate Adaptation Science Center, and Department of Geosciences,
                        University of Massachusetts Amherst, USA
                    </li>
                    <li>
                        {" "}
                        Northern Australian Fire Information, Charles Darwin University, Australia
                    </li>
                    <li> Norwegian Mapping Authority Hydrographic Service</li>
                    <li> Norwegian Meteorological Institute</li>
                    <li>
                        {" "}
                        Ocean and Earth Science, National Oceanography Centre, University of
                        Southampton, UK
                    </li>
                    <li> Pacific Climate Impacts Consortium University of Victoria, Canada</li>
                    <li> Pacific Northwest Research Station, U.S. Forest Service.</li>
                    <li>
                        {" "}
                        Pattiaratchi School of Environmental Systems Engineering and UWA Oceans
                        Institute, The University of Western Australia
                    </li>
                    <li>
                        {" "}
                        Proceedings of the National Academy of Sciences of the United States of
                        America (PNAS)
                    </li>
                    <li> Puertos del Estado - Spain</li>
                    <li>
                        {" "}
                        Queensland Fire and Emergency Services, Queensland Government, Australia
                    </li>
                    <li>
                        {" "}
                        Research Institute for Water and Environment, University of Siegen, Denmarkl
                    </li>
                    <li> Reseaux de reference des observations maregraphiques, France</li>
                    <li> Rijkswaterstaat - Netherlands</li>
                    <li> Risk Frontiers, Macquarie University, Australia</li>
                    <li> Royal Meteorological Society, UK</li>
                    <li> Satalia, Berlin</li>
                    <li> School of GeoSciences, The University of Edinburgh, UK</li>
                    <li> Solvay, Brussels, Belgium</li>
                    <li> Southern Research Station, U.S. Forest Service</li>
                    <li> Standards Catalogue, Standards Australia</li>
                    <li> Swedish Meteorological and Hydrological Institute</li>
                    <li> Sydney Coastal Councils, Australia</li>
                    <li>
                        {" "}
                        The Boundary Layer Wind Tunnel Laboratory, University of Western Ontario,
                        Canada
                    </li>
                    <li>
                        {" "}
                        The Ice Sheet Mass Balance Inter-comparison Exercise (IMBIE), ESA and NASA.
                    </li>
                    <li> U.S. Department of Agriculture</li>
                    <li>
                        {" "}
                        U.S. Environmental Protection Agency, Office of Research and Development,
                        Research Triangle Park, NC,USA
                    </li>
                    <li>
                        {" "}
                        Jayantha Obeysekera South Florida Water Management District, West Palm
                        Beach, USA
                    </li>
                    <li>
                        {" "}
                        U.S. Geological Survey, Woods Hole, MA, USA Chris Zervas National Oceanic
                        and Atmospheric
                    </li>
                    <li>
                        Administration, Center for Operational Oceanographic Products and Services,
                        Silver Spring, MD, USA
                    </li>
                    <li> Universities Space Research Association, Columbia, Maryland, USA</li>
                    <li>
                        {" "}
                        USDA Natural Resources Conservation Service Water and Climate Center,
                        Portland, USA
                    </li>
                    <li>
                        {" "}
                        Water Desalination and Reuse Center, Biological and Environmental Sciences
                        and Engineering Division, King Abdullah University of Science and
                        Technology, UAE
                    </li>
                    <li> Westpac Bank, Australia</li>
                    <li> World Meteorological Organization (WMO)</li>
                </ul>
            </div>
            <div
                className="footer"
                style={{ verticalAlign: "middle", textAlign: "center", marginTop: "5em" }}>
                <p>Brought to you by:</p>
                <img src="/static/images/cr_logo.png" width="20%" alt="Climate Risk Pty Ltd" />
                <img
                    src="/static/images/xdi_logo.png"
                    width="30%"
                    alt="XDI - The Cross Dependency Initiative"
                />
            </div>
        </div>
    )
}

export default DataSources
