import React from "react"
import { Card, useAccordionToggle } from "react-bootstrap"
import { MdKeyboardArrowRight } from "react-icons/md"

/**
 * Manages opening and closing accordions.
 */
function CardHeader({ children, eventKey, openCard, onClick }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => onClick(eventKey))

    return (
        <Card.Header onClick={decoratedOnClick} className={openCard != eventKey && "collapsed"}>
            <MdKeyboardArrowRight size="1.5em" />
            <div>{children}</div>
        </Card.Header>
    )
}

export { CardHeader }
