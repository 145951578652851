import React from "react";
import autoBind from "react-autobind";
import { Button, Form, Modal } from "react-bootstrap";
import { autorun } from "mobx";
import { CVLoading } from "../../CR-Components/Loading.jsx";

import appState from "../CoreFiles/state";
import Loading from "../../CR-Components/Loading.jsx";

import "./Feedback.scss";

class Feedback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account: null,
            expanded: false,
            email: appState.account ? appState.account.username : "",
            subject: "",
            comment: "",
            label: "",
            labels: "",
            success: false,
            error: null,
            valid: false,
            sending: false,
        };
        autoBind(this);
    }

    UNSAFE_componentWillMount() {
        this.observerDisposer = autorun(() => {
            if (!this.state.email && appState.account) {
                this.setState({ email: appState.account.username });
            }
        });
    }

    componentWillUnmount() {
        this.observerDisposer();
    }

    handleClose() {
        this.setState({ expanded: false, success: false });
    }

    async handleSubmit() {
        const { analysis, onSubmit, isGeneral } = this.props;
        const { comment, subject, email, label, labels } = this.state;

        const appName = () => {
            let appName = "easy";
            if (crConstants.ClimateValuation) {
                appName = "climatevaluation";
            }
            return appName;
        };

        this.setState({ error: null, sending: true });

        if (isGeneral) {
            this.setState({
                label: "general-feedback\n/label general-feedback",
                labels: "general",
            });
        } else {
            this.setState({ label: "strange-result\n/label strange-result", labels: null });
        }

        try {
            console.log("submit");
            const data = {
                email,
                subject,
                comment,
                label,
                url: window.location.href,
            };
            if (analysis) {
                data.analysis = {};
                if (analysis.inputs.asset.properties["name"]) data.analysis["title"] = analysis.inputs.asset.properties["name"];
                if (analysis.inputs.asset.properties) data.analysis["parameters"] = analysis.inputs.asset.properties;
                if (analysis["metadata"]) data.analysis["metadata"] = analysis["metadata"];
            }
            const response = await jQuery.ajax({
                method: "POST",
                url: `${crConstants.authHost}/v1/feedback`,
                data: {
                    data: JSON.stringify(data, null, 2),
                    mail: JSON.stringify({
                        app: appName(),
                        template: "feedback",
                        labels: labels,
                    }),
                },
                dataType: "json",
            });
            this.setState({ success: true, sending: false });
        } catch (e) {
            const err =
                (e.responseJSON && e.responseJSON.error && e.responseJSON.error.message) ||
                e.message ||
                e.statusText ||
                "" + e;
            console.log("e", e);
            this.setState({ error: err, sending: false });
        }
    }
    /**
     *
     * @param {string} field
     * @param {string|number|object} value
     */
    handleChange(field, value) {
        this.setState({ [field]: value });
        setTimeout(() => this.setState({ valid: this.form.checkValidity() === true }), 0);
    }

    render() {
        const { buttonText, CV, isGeneral, linkText } = this.props;
        const {
            username,
            expanded,
            comment,
            subject,
            email,
            error,
            success,
            valid,
            sending,
        } = this.state;

        return (
            <div className="Feedback">
                {/* General feedback in hamburger menu */}
                {CV && isGeneral && (
                    <a
                        className={`${
                            appState.viewportWidth >= 1070 ? "nav-link" : "feedback-link"
                        }`}
                        onClick={() => this.setState({ expanded: !expanded })}
                    >
                        {linkText}
                    </a>
                )}

                {!CV && isGeneral && (
                    <a
                        className={"feedback-link"}
                        onClick={() => this.setState({ expanded: !expanded })}
                    >
                        {linkText}
                    </a>
                )}

                {/* Strange Result button */}
                {!isGeneral && (
                    <Button
                        onClick={() => this.setState({ expanded: !expanded })}
                        variant="secondary"
                        className={`btn-secondary ${
                            appState.viewportWidth >= 1070 ? "" : "btn-sm"
                        }`}
                    >
                        {buttonText}
                    </Button>
                )}

                <Modal show={expanded} onHide={this.handleClose} className="Feedback-modal">
                    <Modal.Header className={`${CV ? "cv-modal-header" : ""}`}>
                        {" "}
                        <h4>{linkText}</h4>{" "}
                    </Modal.Header>
                    <Modal.Body>
                        {!success && (
                            <Form
                                onSubmit={(e) => e.preventDefault()}
                                ref={(form) => {
                                    this.form = form;
                                }}
                            >
                                <Form.Group controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={email}
                                        onChange={(e) => this.handleChange("email", e.target.value)}
                                        placeholder="Your email address"
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="subject">
                                    <Form.Label>Subject</Form.Label>
                                    <Form.Control
                                        type="subject"
                                        value={subject}
                                        maxLength="80"
                                        onChange={(e) =>
                                            this.handleChange("subject", e.target.value)
                                        }
                                        placeholder="Subject"
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="comment">
                                    <Form.Label>Comment</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        value={comment}
                                        rows="5"
                                        onChange={(e) =>
                                            this.handleChange("comment", e.target.value)
                                        }
                                        placeholder="Please enter your question or comment here."
                                        required
                                    />
                                </Form.Group>

                                {!!error && <div className="error">{error}</div>}
                            </Form>
                        )}

                        {!!success && (
                            <div className="success">
                                Thank you for your feedback, we'll contact you shortly.
                            </div>
                        )}

                        <div className="buttons">
                            {sending && (
                                <div
                                    className={`${
                                        CV == true ? "cv-feedback-loader" : "easy-feedback-loader"
                                    }`}
                                >
                                    {!CV && sending && <Loading />}
                                    {CV && sending && <CVLoading />}
                                </div>
                            )}
                            {!sending && !success && (
                                <Button
                                    className={`${CV ? "cv-btn-primary" : ""}`}
                                    type="submit"
                                    onClick={this.handleSubmit}
                                    disabled={!valid || !!success || !!sending}
                                >
                                    Submit
                                </Button>
                            )}

                            <Button variant="secondary" onClick={this.handleClose}>
                                {success ? "Close" : "Cancel"}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default Feedback;
