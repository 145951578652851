import React from "react"
import autoBind from "react-autobind"
import {
    Ion,
    createWorldImagery,
    IonWorldImageryStyle,
    Cartographic,
    Cartesian3,
    ScreenSpaceEventType,
    Color
} from "cesium"
import {
    CesiumContext,
    Viewer,
    Camera,
    CameraFlyTo,
    SkyAtmosphere,
    ScreenSpaceEventHandler,
    ScreenSpaceEvent,
} from "resium"
import PropTypes from "prop-types"

import { cartographicToDegrees, cartographicToRadians } from "./utils.js"

import "./Cesium.scss"

const CAMERA_POSITION_CHANGE_DEBOUNCE_DELTA = 0.001

const debug = false

// eslint-disable-next-line no-undef
Ion.defaultAccessToken = crConstants.CesiumIonAccessToken

const cesiumDefaultConfig = {
    fullscreenButton: false,
    animation: false,
    vrButton: false,
    timeline: false,
    shadows: false,
    infoBox: false,
    sceneModePicker: false,
    geocoder: false,
    homeButton: false,
    baseLayerPicker: false,
    navigationHelpButton: false,

    // Decreases memory usage, disabled for marker redraw
    requestRenderMode: false,
    maximumRenderTimeChange: Infinity,

    // Map images (steet view, satelite, etc)
    imageryProvider: createWorldImagery({
        style: IonWorldImageryStyle.AERIAL_WITH_LABELS,
    }),
}

// const contextOptions = {
//     webgl: {
//         alpha: false,
//         depth: true,
//         stencil: false,
//         antialias: true,
//         premultipliedAlpha: false,
//         preserveDrawingBuffer: true,
//         failIfMajorPerformanceCaveat: false
//     },
//     allowTextureFilterAnisotropic: true
// };

class CesiumViewer extends React.Component {
    static propTypes = {
        contextRef: PropTypes.func,
        onNewLocation: PropTypes.func,
        className: PropTypes.string,
        flyTo: PropTypes.object,
        onClick: PropTypes.func,
        children: PropTypes.node,
    }

    constructor(props) {
        super(props)
        this.cameraPosition = null
        this.state = {
            flyToLocation: null,
        }
        autoBind(this)
    }

    ctxRefHandler(ctx) {
        if (debug) console.log("ctxRefHandler", ctx)
        this.cesiumContext = ctx
        this.props.contextRef && this.props.contextRef(ctx)
    }

    handleCameraChange() {
        if (debug) console.log("handleCameraChange")

        if (this.cesiumContext && this.props.onNewLocation) {
            // Record camera position to allow debouncing camera change via flyTo.
            const positionCartogaphic = cartographicToDegrees(
                Cartographic.fromCartesian(this.cesiumContext.camera.position)
            )
            this.props.onNewLocation(positionCartogaphic)
        }
    }

    handleClick(click) {
        if (debug) console.log("handleClick", click)

        if (this.cesiumContext && this.props.onClick) {
            const cartesian = this.cesiumContext.camera.pickEllipsoid(click.position)

            if (!cartesian) {
                // Not on planet earth
                return
            }

            const cartographic = cartographicToDegrees(Cartographic.fromCartesian(cartesian))

            this.props.onClick({
                latitude: cartographic.latitude,
                longitude: cartographic.longitude,
            })
        }
    }

    componentDidMount() {
        const { flyTo } = this.props
        try {
            if (flyTo && flyTo.latitude) {
                flyTo && this.setState({
                    flyToLocation: Cartographic.toCartesian(cartographicToRadians(this.props.flyTo))
                })
            }
        } catch {
            console.log("FIXME: flyTo missing latitude!")
        }
    }

    componentDidUpdate(prevProps) {
        const { flyTo } = this.props
        if (flyTo !== prevProps.flyTo) {
            let flyTo2 = flyTo
            // If the flyTo looks like a cartographic position (which will be in degrees).
            if (flyTo && "latitude" in flyTo) {
                if (flyTo.latitude) {
                    flyTo2 = Cartographic.toCartesian(cartographicToRadians(flyTo))
                    // Debounce camera change (ignore very small changes).
                    const currentCameraPos = this.cesiumContext && this.cesiumContext.camera.position
                    if (
                        currentCameraPos &&
                        Cartesian3.distance(flyTo2, currentCameraPos) <
                            CAMERA_POSITION_CHANGE_DEBOUNCE_DELTA
                    ) {
                        flyTo2 = currentCameraPos
                    }
                    this.setState({ flyToLocation: flyTo2 })
                }
            }
        }
    }

    render() {
        // const { flyTo, onClick } = this.props
        const { flyToLocation } = this.state

        const CV = crConstants.ClimateValuation // eslint-disable-line no-undef

        return (
            <Viewer
                {...cesiumDefaultConfig}
                className={this.props.className}
                // Not used?
                // {...viewerOptions}
            >
                <CesiumCtxComp ctxRef={this.ctxRefHandler} />

                {!CV && <SkyAtmosphere />}

                <Camera onMoveEnd={this.handleCameraChange} />

                <ScreenSpaceEventHandler>
                    <ScreenSpaceEvent
                        type={ScreenSpaceEventType.LEFT_CLICK}
                        action={this.handleClick}
                    />
                    <ScreenSpaceEvent
                        type={ScreenSpaceEventType.LEFT_DOWN}
                        action={() => {
                            this.setState({ flyToLocation: false })
                        }}
                    />
                </ScreenSpaceEventHandler>

                {/* <CameraFlyHome once={true}/> */}

                {flyToLocation ? (
                    <CameraFlyTo destination={flyToLocation} cancelFlightOnUnmount={true} />
                ) : null}

                {this.props.children}
            </Viewer>
        )
    }
}
//<ScreenSpaceEvent action={action('Left Click')} type={ScreenSpaceEventType.LEFT_CLICK} />

// Component to access some native Cesium objects to change their settings.
class CesiumCtxComp extends React.Component {
    static propTypes = {
        ctxRef: PropTypes.func,
    }

    componentDidMount() {
        this.props.ctxRef(this.context)

        const { globe, scene } = this.context
        // eslint-disable-next-line no-undef
        if (crConstants.ClimateValuation) {
            globe.baseColor = Color.WHITE
            // Further Decrease memory usage
            globe.maximumScreenSpaceError = 2.0
            globe.preloadSiblings = true // loads neighboring tiles, load fewer surrounding tiles.
            // Constrain camera movement, no more tilt or rotate.
            scene.screenSpaceCameraController.enableTilt = false
            scene.skyBox.show = false
        } else {
            globe.enableLighting = true
            globe.atmosphereBrightnessShift = 0.02 //default 0.0
            globe.showGroundAtmosphere = true
            globe.nightFadeInDistance = 30000000
            globe.baseColor = Color.BLACK
            // Further Decrease memory usage
            globe.maximumScreenSpaceError = 2.0
            globe.preloadSiblings = true // loads neighboring tiles, load fewer surrounding tiles.

            // Constrain camera movement, no more tilt or rotate.
            scene.screenSpaceCameraController.enableTilt = false

            // Debug FPS
            // viewer.scene.debugShowFramesPerSecond = true;
        }
    }

    render() {
        return false
    }
}
CesiumCtxComp.contextType = CesiumContext

export default CesiumViewer
