import React from "react"
import autoBind from "react-autobind"
import { Button, Form, FormGroup, FormControl, Modal } from "react-bootstrap"
import { observer } from "mobx-react"
import ReactGA from "react-ga"
import PropTypes from 'prop-types'

import { hubSpotTracking } from "../hubSpot.js"
import { saveAnalysis } from "../CoreFiles/analyses"
import appState from "../CoreFiles/state"
import LoginForm from "../Account/LoginForm.jsx"

import "./SaveResult.scss"

const noPasswordText = (
    <React.Fragment>
        We'll send you an email with a link, so that you can view your saved analysis later. We will
        not give your email address to third parties (
        <a href="/info/privacy_policy" target="_blank">
            see our privacy policy
        </a>
        ). We may send you occasional updates when we add new features.
    </React.Fragment>
)

@observer
class SaveResult extends React.Component {
    static propTypes = {
        analysis: PropTypes.object,
        onSubmit: PropTypes.func,
        saveButtonText: PropTypes.string
    }

    constructor(props) {
        super(props)
        this.state = {
            expanded: false,
            title: "",
            error: null,
            valid: false,
        }
        autoBind(this)
    }

    handleClose() {
        this.setState({ expanded: false })
    }

    async handleSubmit() {
        const { analysis, onSubmit } = this.props

        const { title } = this.state

        if (!crConstants.ClimateValuation) {
            ReactGA.event({
                category: "User",
                action: "Save Result",
                label: "save",
                value: 2,
            })
        }
        hubSpotTracking("setPath", "/save-result", "trackPageView")
        window.document.title = "Save Result"

        try {
            const analysisUpdate = await saveAnalysis({
                ...analysis,
                title,
            })
            this.handleClose()
            onSubmit(analysisUpdate)
        } catch (e) {
            this.setState({ error: e.message || "" + e })
        }
    }

    /**
     *
     * @param {string} field
     * @param {string|object|boolean} value
     */
    handleChange(field, value) {
        this.setState({ [field]: value })
        setTimeout(() => this.setState({ valid: this.form.checkValidity() === true }), 0)
    }

    render() {
        const { analysis, saveButtonText } = this.props
        const { expanded, title, error } = this.state
        const { account } = appState

        return (
            <div className="SaveResult">
                <Button
                    variant="secondary"
                    onClick={() => this.setState({ expanded: !expanded })}
                    disabled={!!analysis._id}
                    className={`btn-secondary ${appState.viewportWidth >= 1070 ? "" : "btn-sm"}`}>
                    {!!analysis._id ? "Saved" : saveButtonText || "Save this analysis"}
                </Button>

                <Modal show={expanded} onHide={this.handleClose} className="SaveResult-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Save analysis</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {!account && (
                            <LoginForm
                                noPassword={true}
                                loginButtonText="Next"
                                noPasswordText={noPasswordText}
                            />
                        )}

                        {account && (
                            <Form
                                onSubmit={(e) => e.preventDefault()}
                                ref={(form) => {
                                    this.form = form
                                }}>
                                <FormGroup controlId="title" title="Title for this analysis">
                                    <Form.Label>Title</Form.Label>
                                    <FormControl
                                        type="text"
                                        value={title}
                                        onChange={(e) => this.handleChange("title", e.target.value)}
                                        placeholder="Enter a descriptive title for this analysis"
                                        required
                                    />
                                </FormGroup>

                                {!!error && <div className="error">{error}</div>}
                            </Form>
                        )}

                        <div className="buttons">
                            {account ? (
                                <Button type="submit" onClick={this.handleSubmit}>
                                    Save
                                </Button>
                            ) : (
                                <div> </div>
                            )}
                            <Button variant="secondary" onClick={this.handleClose}>
                                Cancel
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default SaveResult
