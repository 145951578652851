import React from "react";
import ReactDOM from "react-dom";
import autoBind from "react-autobind";
import PropTypes from 'prop-types'
import html2canvas from "html2canvas";

import appState from '../../CoreFiles/state.js'
import ReportProcessingModal from "../ReportProcessingModal/ReportProcessingModal.jsx";
import PDFDownloadLinkWrapper from "./PDFDownloadLinkWrapper.jsx";
import {
    DrawVARBreakdownCustom,
    DrawPieBreakdownCustom,
    DrawMaxFPBreakdown,
    DrawAnnualFPBreakdown,
} from "../../Charts/Breakdown-Charts.jsx";
import DrawVARChart from "../../Charts/VAR-Charts.jsx";
import DrawVulnerabilityCharts from "../../Charts/Vulnerability-Charts.jsx";
import { fetchImageFromHighChartRemote } from "./AnalysisGraphs.js";

window.html2canvas = html2canvas;
const STREET_VIEW_DELAY = 1;

class PDFReport extends React.Component {
    static propTypes = {
        isPurchased: PropTypes.func,
        analysisGroup: PropTypes.array,
        analysis: PropTypes.object.isRequired,
        fieldDefaultValues: PropTypes.object.isRequired,
        account: PropTypes.object,
        onDownload: PropTypes.func,
        filename: PropTypes.string,
        materials: PropTypes.object,
    }

    _isRendering = false;
    _isMounted = false;
    _stopNextRenderring = false;
    _debugReportsTextOnly = crConstants.debugReportsTextOnly;
    timeoutHandle = [];
    constructor(props) {
        super(props);

        this.progressBarRef = React.createRef();
        const defaultImageValue = this._debugReportsTextOnly
            ? "/static/images/report/Screen_Shot_2019-11-22_at_4.12.58_pm.png"
            : null;
        this.state = {
            locationImage: defaultImageValue,
            satelliteViewImage: `https://maps.googleapis.com/maps/api/staticmap?center=` +
                `${this.props.fieldDefaultValues.latitude},` +
                `${this.props.fieldDefaultValues.longitude}` +
                `&zoom=19&size=600x300&maptype=satellite&key=${crConstants.GoogleApiKey}`,
            VARCharts: defaultImageValue,
            pieChartsForAnnualVARStartYear: defaultImageValue,
            pieChartsForAnnualVARMortgageTerm: defaultImageValue,
            pieChartsForAnnualVAREndYear: defaultImageValue,
            maxFailureProbabilityToDate: defaultImageValue,
            annualFailureProbability: defaultImageValue,
            pieChartsForAnnualFailureProbabilityStartYear: defaultImageValue,
            pieChartsForAnnualFailureProbabilityMortgageTerm: defaultImageValue,
            pieChartsForAnnualFailureProbabilityEndYear: defaultImageValue,
            vulnerabilityStartYear: defaultImageValue,
            vulnerabilityMortgageTerm: defaultImageValue,
            vulnerabilityEndYear: defaultImageValue,
            floodRiverine: defaultImageValue,
            inundation: defaultImageValue,
            forestFire: defaultImageValue,
            soilSubsidence: defaultImageValue,
            extremeWind: defaultImageValue,
            extremeHeat: defaultImageValue,
            freezeThaw: defaultImageValue,
            floodSurfaceWater: defaultImageValue,
            cycloneWind: defaultImageValue,
        };

        autoBind(this);
    }

    componentDidMount() {
        const { fieldDefaultValues } = this.props
        this._isMounted = true;
        console.log("_debugReportsTextOnly", this._debugReportsTextOnly);
        if (this._debugReportsTextOnly) return true;
        //Show graphs here
        if (this._isRendering === false) {
            this._isRendering = true;

            this.timeoutHandle.push(
                setTimeout(
                    function () {
                        const analysis = {
                            ...this.props.analysis,
                            meta: { collection: "Point" },
                        };
                        ReactDOM.render(
                            this.renderPrintViewAssets(),
                            document.getElementById("react-root-print-assets")
                        );
                        var property = new google.maps.LatLng(
                            analysis.geometry.coordinates[1],
                            analysis.geometry.coordinates[0]
                        );
                        let sv = new google.maps.StreetViewService();
                        let heading = 0;
                        // Set the initial Street View camera to the center of the map
                        let self = this;
                        sv.getPanorama(
                            {
                                location: property,
                                radius: 50,
                                source: google.maps.StreetViewSource.OUTDOOR,
                            },
                            function (data, status) {
                                if (status === "OK") {
                                    heading = google.maps.geometry.spherical.computeHeading(
                                        data.location.latLng,
                                        property
                                    );
                                }

                                self.setState({
                                    locationImage: `https://maps.googleapis.com/maps/api/streetview?size=600x400&location=${fieldDefaultValues.latitude},${fieldDefaultValues.longitude}&fov=100&heading=${heading}&pitch=0&source=outdoor&key=${crConstants.GoogleApiKey}`,
                                });
                            }
                        );
                    }.bind(this),
                    2000
                )
            );
        }
    }

    componentWillUnmount() {
        //incase component is disnounts before readyToRenderPrintView === true
        ReactDOM.render(
            this.dismountPrintViewAssets(),
            document.getElementById("react-root-print-assets")
        );
        this._isMounted = false;
        this.timeoutHandle.map((item) => {
            clearTimeout(item);
        });
    }

    readyToRenderPrintView() {
        //
        const {
            locationImage,
            satelliteViewImage,
            VARCharts,
            pieChartsForAnnualVARStartYear,
            pieChartsForAnnualVARMortgageTerm,
            pieChartsForAnnualVAREndYear,
            maxFailureProbabilityToDate,
            annualFailureProbability,
            pieChartsForAnnualFailureProbabilityStartYear,
            pieChartsForAnnualFailureProbabilityMortgageTerm,
            pieChartsForAnnualFailureProbabilityEndYear,
            vulnerabilityStartYear,
            vulnerabilityMortgageTerm,
            vulnerabilityEndYear,
            floodRiverine,
            inundation,
            forestFire,
            soilSubsidence,
            extremeWind,
            extremeHeat,
            freezeThaw,
            floodSurfaceWater,
            cycloneWind
        } = this.state;

        if (
            locationImage !== null &&
            satelliteViewImage !== null &&
            VARCharts !== null &&
            pieChartsForAnnualVARStartYear !== null &&
            pieChartsForAnnualVARMortgageTerm !== null &&
            pieChartsForAnnualVAREndYear !== null &&
            maxFailureProbabilityToDate !== null &&
            annualFailureProbability !== null &&
            pieChartsForAnnualFailureProbabilityStartYear !== null &&
            pieChartsForAnnualFailureProbabilityMortgageTerm !== null &&
            pieChartsForAnnualFailureProbabilityEndYear !== null &&
            vulnerabilityStartYear !== null &&
            vulnerabilityMortgageTerm !== null &&
            vulnerabilityEndYear !== null &&
            floodRiverine !== null &&
            inundation !== null &&
            forestFire !== null &&
            soilSubsidence !== null &&
            extremeWind !== null &&
            extremeHeat !== null &&
            freezeThaw !== null &&
            floodSurfaceWater !== null &&
            cycloneWind !== null 
        ) {
            return true;
        } else return false;
    }

    restrictRender() {
        this._stopNextRenderring = true;
    }

    shouldComponentUpdate() {
        if (this._stopNextRenderring === true) {
            return false;
        } else {
            return true;
        }
    }

    componentDidCatch(error, errorInfo) {
        console.log("PDFReport error:", error, errorInfo);
    }

    onComplete() {
        this.progressBarRef.current.hideProgressBar();
        this._stopNextRenderring = true;
    }

    onDownload() {
        this.props.onDownload();
    }
    render() {
        const { analysis, account, filename, analysisGroup, materials } = this.props;

        const {
            locationImage,
            satelliteViewImage,
            VARCharts,
            pieChartsForAnnualVARStartYear,
            pieChartsForAnnualVARMortgageTerm,
            pieChartsForAnnualVAREndYear,
            maxFailureProbabilityToDate,
            annualFailureProbability,
            pieChartsForAnnualFailureProbabilityStartYear,
            pieChartsForAnnualFailureProbabilityMortgageTerm,
            pieChartsForAnnualFailureProbabilityEndYear,
            vulnerabilityStartYear,
            vulnerabilityMortgageTerm,
            vulnerabilityEndYear,
            floodRiverine,
            inundation,
            forestFire,
            soilSubsidence,
            extremeWind,
            extremeHeat,
            freezeThaw,
            floodSurfaceWater,
            cycloneWind
        } = this.state;

        const readyToRenderPrintView = this.readyToRenderPrintView();
        if (readyToRenderPrintView === true) {
            console.log("PDF readyToRenderPrintView", readyToRenderPrintView);
            ReactDOM.render(
                this.dismountPrintViewAssets(),
                document.getElementById("react-root-print-assets")
            );
        }

        return (
            <React.Fragment>
                <div className="processing-modal-background">
                    <div className="processing-modal report-processing-modal">
                        <ReportProcessingModal
                            ref={this.progressBarRef}
                            readyToRenderPrintView={readyToRenderPrintView}
                            VARCharts={VARCharts}
                            pieChartsForAnnualVARStartYear={pieChartsForAnnualVARStartYear}
                            pieChartsForAnnualVARMortgageTerm={pieChartsForAnnualVARMortgageTerm}
                            pieChartsForAnnualVAREndYear={pieChartsForAnnualVAREndYear}
                            maxFailureProbabilityToDate={maxFailureProbabilityToDate}
                            annualFailureProbability={annualFailureProbability}
                            pieChartsForAnnualFailureProbabilityStartYear={
                                pieChartsForAnnualFailureProbabilityStartYear
                            }
                            pieChartsForAnnualFailureProbabilityMortgageTerm={
                                pieChartsForAnnualFailureProbabilityMortgageTerm
                            }
                            pieChartsForAnnualFailureProbabilityEndYear={
                                pieChartsForAnnualFailureProbabilityEndYear
                            }
                            vulnerabilityStartYear={vulnerabilityStartYear}
                            vulnerabilityMortgageTerm={vulnerabilityMortgageTerm}
                            vulnerabilityEndYear={vulnerabilityEndYear}
                            floodRiverine={floodRiverine}
                            inundation={inundation}
                            forestFire={forestFire}
                            soilSubsidence={soilSubsidence}
                            extremeWind={extremeWind}
                            extremeHeat={extremeHeat}
                            freezeThaw={freezeThaw}
                            floodSurfaceWater={floodSurfaceWater}
                            cycloneWind={cycloneWind}
                        />


                        {locationImage !== null &&
                            satelliteViewImage !== null &&
                            VARCharts !== null &&
                            pieChartsForAnnualVARStartYear !== null &&
                            pieChartsForAnnualVARMortgageTerm !== null &&
                            pieChartsForAnnualVAREndYear !== null &&
                            maxFailureProbabilityToDate !== null &&
                            annualFailureProbability !== null &&
                            pieChartsForAnnualFailureProbabilityStartYear !== null &&
                            pieChartsForAnnualFailureProbabilityMortgageTerm !== null &&
                            pieChartsForAnnualFailureProbabilityEndYear !== null &&
                            vulnerabilityStartYear !== null &&
                            vulnerabilityMortgageTerm !== null &&
                            vulnerabilityEndYear !== null &&
                            floodRiverine !== null &&
                            inundation !== null &&
                            forestFire !== null &&
                            soilSubsidence !== null &&
                            extremeHeat !== null && 
                            freezeThaw !== null &&
                            floodSurfaceWater !== null &&
                            cycloneWind !== null && (
                            <PDFDownloadLinkWrapper
                                analysis={analysis}
                                analysisGroup={analysisGroup}
                                account={account}
                                filename={filename}
                                locationImage={locationImage}
                                satelliteViewImage={satelliteViewImage}
                                VARCharts={VARCharts}
                                pieChartsForAnnualVARStartYear={
                                    pieChartsForAnnualVARStartYear
                                }
                                pieChartsForAnnualVARMortgageTerm={pieChartsForAnnualVARMortgageTerm}
                                pieChartsForAnnualVAREndYear={pieChartsForAnnualVAREndYear}
                                maxFailureProbabilityToDate={maxFailureProbabilityToDate}
                                annualFailureProbability={annualFailureProbability}
                                pieChartsForAnnualFailureProbabilityStartYear={
                                    pieChartsForAnnualFailureProbabilityStartYear
                                }
                                pieChartsForAnnualFailureProbabilityMortgageTerm={
                                    pieChartsForAnnualFailureProbabilityMortgageTerm
                                }
                                pieChartsForAnnualFailureProbabilityEndYear={
                                    pieChartsForAnnualFailureProbabilityEndYear
                                }
                                vulnerabilityStartYear={vulnerabilityStartYear}
                                vulnerabilityMortgageTerm={vulnerabilityMortgageTerm}
                                vulnerabilityEndYear={vulnerabilityEndYear}
                                floodRiverine={floodRiverine}
                                inundation={inundation}
                                forestFire={forestFire}
                                soilSubsidence={soilSubsidence}
                                extremeWind={extremeWind}
                                extremeHeat={extremeHeat}
                                freezeThaw={freezeThaw}
                                floodSurfaceWater={floodSurfaceWater}
                                cycloneWind={cycloneWind}
                                restrictRender={this.restrictRender}
                                onComplete={this.onComplete}
                                onDownload={this.onDownload}
                                materials={materials}
                            />
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    dismountPrintViewAssets() {
        return <React.Fragment></React.Fragment>;
    }
    /**
     *
     * @param {string} source
     */
    onAssetRenderComplete(source) {
        console.log("onAssetRenderComplete >> " + source);
        this.timeoutHandle.push(
            setTimeout(
                function () {
                    const riskfractionImageDate = window.document.getElementById([source]);
                    html2canvas(riskfractionImageDate, {
                        logging: false,
                    }) //input
                        .then((canvas) => {
                            const imgData = canvas.toDataURL("image/png", 1.0);
                            console.log("onPngConversionComplete >> ", source);
                            if (this._isMounted) {
                                this.setState({
                                    [source]: imgData,
                                });
                            }
                        });
                }.bind(this),
                5000
            )
        );
    }
    /**
     * @param {object} graphData
     * @param {string} source
     * @param {number} width
     */
    getGraphImage = async (graphData, source, width = 1000) => {
        const optionsStr = JSON.stringify(graphData);

        const imageBlob = await fetchImageFromHighChartRemote(optionsStr, width);
        console.log("imageBlob for >>> " + source + "::", imageBlob);
        if (this._isMounted) {
            this.setState({
                [source]: imageBlob,
            });
        }
    };

    renderPrintViewAssets() {
        const { analysis } = this.props;

        return (
            <React.Fragment>

                {/* Pie Charts: Value at Risk */}
                {
                    <div
                        id="pieChartsForAnnualVARStartYear"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawPieBreakdownCustom
                            data={analysis.properties.riskHazards}
                            year={analysis.inputs.scenario.startYear}
                            title={analysis.inputs.scenario.startYear}
                            startYear={analysis.inputs.scenario.startYear}
                            source="pieChartsForAnnualVARStartYear"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                        />
                    </div>
                }
                {
                    <div
                        id="pieChartsForAnnualVARMortgageTerm"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawPieBreakdownCustom
                            data={analysis.properties.riskHazards}
                            startYear={analysis.inputs.scenario.startYear}
                            year={analysis.inputs.scenario.startYear + 60}
                            title={analysis.inputs.scenario.startYear + 60}
                            source="pieChartsForAnnualVARMortgageTerm"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                        />
                    </div>
                }
                {
                    <div
                        id="pieChartsForAnnualVAREndYear"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawPieBreakdownCustom
                            data={analysis.properties.riskHazards}
                            startYear={analysis.inputs.scenario.startYear}
                            year={analysis.inputs.scenario.endYear}
                            title={analysis.inputs.scenario.endYear}
                            source="pieChartsForAnnualVAREndYear"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                        />
                    </div>
                }

                {/* Pie Charts: Failure Probability */}

                {
                    <div
                        id="pieChartsForAnnualFailureProbabilityStartYear"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawPieBreakdownCustom
                            data={analysis.properties.failureHazards}
                            startYear={analysis.inputs.scenario.startYear}
                            year={analysis.inputs.scenario.endYear}
                            title={analysis.inputs.scenario.endYear}
                            source="pieChartsForAnnualFailureProbabilityStartYear"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                        />
                    </div>
                }
                {
                    <div
                        id="pieChartsForAnnualFailureProbabilityMortgageTerm"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawPieBreakdownCustom
                            data={analysis.properties.failureHazards}
                            startYear={analysis.inputs.scenario.startYear}
                            year={analysis.inputs.scenario.startYear + 60}
                            title={analysis.inputs.scenario.startYear + 60}
                            source="pieChartsForAnnualFailureProbabilityMortgageTerm"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                        />
                    </div>
                }
                {
                    <div
                        id="pieChartsForAnnualFailureProbabilityEndYear"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawPieBreakdownCustom
                            data={analysis.properties.failureHazards}
                            startYear={analysis.inputs.scenario.startYear}
                            year={analysis.inputs.scenario.endYear}
                            title={analysis.inputs.scenario.endYear}
                            source="pieChartsForAnnualFailureProbabilityEndYear"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                        />
                    </div>
                }

                {/* Area Charts: Failure Probability */}

                {
                    <div
                        id="maxFailureProbabilityToDate"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawMaxFPBreakdown
                            startYear={analysis.inputs.scenario.startYear}
                            failureHazards={analysis.properties.failureHazards}
                            source="maxFailureProbabilityToDate"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                        />
                    </div>
                }

                {
                    <div
                        id="annualFailureProbability"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawAnnualFPBreakdown
                            startYear={analysis.inputs.scenario.startYear}
                            failureHazards={analysis.properties.failureHazards}
                            source="annualFailureProbability"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                        />
                    </div>
                }

                {/* VARCharts */}

                {
                    <div
                        id="VARCharts"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawVARChart
                            analysis={analysis}
                            source="VARCharts"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                        />
                    </div>
                }

                {/* Vulnerability Charts */}

                {
                    <div
                        id="vulnerabilityStartYear"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawVulnerabilityCharts
                            analysis={analysis}
                            purchased={this.props.isPurchased}
                            year={analysis.inputs.scenario.startYear}
                            source="vulnerabilityStartYear"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                        />
                    </div>
                }

                {
                    <div
                        id="vulnerabilityMortgageTerm"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawVulnerabilityCharts
                            analysis={analysis}
                            purchased={this.props.isPurchased}
                            year={analysis.inputs.scenario.startYear + 60}
                            source="vulnerabilityMortgageTerm"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                        />
                    </div>
                }

                {
                    <div
                        id="vulnerabilityEndYear"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawVulnerabilityCharts
                            analysis={analysis}
                            purchased={this.props.isPurchased}
                            year={analysis.inputs.scenario.endYear}
                            source="vulnerabilityEndYear"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                        />
                    </div>
                }

                {/*First attribute start*/}

                {
                    <div
                        id="floodRiverine"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawVARBreakdownCustom
                            analysis={analysis}
                            purchased={this.props.isPurchased}
                            graphOnly="true"
                            maxToDateOnly="true"
                            customHazard="flood_riverine"
                            customHazardLabel="Flood (Riverine)"
                            startingHazardIndex="0"
                            hazardsLimit="1"
                            source="floodRiverine"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                            startYear={analysis.inputs.scenario.startYear}
                        />
                    </div>
                }
                {/*First attribute start*/}

                {/*Second attribute start*/}

                {
                    <div
                        id="inundation"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawVARBreakdownCustom
                            analysis={analysis}
                            purchased={this.props.isPurchased}
                            graphOnly="true"
                            maxToDateOnly="true"
                            customHazard="inundation"
                            customHazardLabel="Inundation"
                            startingHazardIndex="1"
                            hazardsLimit="2"
                            source="inundation"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                            startYear={analysis.inputs.scenario.startYear}
                        />
                    </div>
                }

                {/*Second attribute end*/}

                {/*Third attribute start*/}

                {
                    <div
                        id="forestFire"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawVARBreakdownCustom
                            analysis={analysis}
                            purchased={this.props.isPurchased}
                            graphOnly="true"
                            maxToDateOnly="true"
                            customHazard="forest_fire"
                            customHazardLabel="Forest fire"
                            startingHazardIndex="3"
                            hazardsLimit="4"
                            source="forestFire"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                            startYear={analysis.inputs.scenario.startYear}
                        />
                    </div>
                }

                {/*Third attribute end*/}

                {/*Forth attribute start*/}

                {
                    <div
                        id="soilSubsidence"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawVARBreakdownCustom
                            analysis={analysis}
                            purchased={this.props.isPurchased}
                            graphOnly="true"
                            maxToDateOnly="true"
                            customHazard="soil_movement"
                            customHazardLabel="Soil Movement"
                            startingHazardIndex="5"
                            hazardsLimit="6"
                            source="soilSubsidence"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                            startYear={analysis.inputs.scenario.startYear}
                        />
                    </div>
                }

                {/*Forth attribute end*/}

                {/*Fifth attribute start*/}

                {
                    <div
                        id="extremeWind"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawVARBreakdownCustom
                            analysis={analysis}
                            purchased={this.props.isPurchased}
                            graphOnly="true"
                            maxToDateOnly="true"
                            customHazard="wind"
                            customHazardLabel="Wind"
                            startingHazardIndex="4"
                            hazardsLimit="5"
                            source="extremeWind"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                            startYear={analysis.inputs.scenario.startYear}
                        />
                    </div>
                }

                {/*Fifth  attribute end*/}

                {/*Sixth attribute start*/}

                {
                    <div
                        id="extremeHeat"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawVARBreakdownCustom
                            analysis={analysis}
                            purchased={this.props.isPurchased}
                            graphOnly="true"
                            maxToDateOnly="true"
                            customHazard="heat"
                            customHazardLabel="Heat"
                            startingHazardIndex="2"
                            hazardsLimit="3"
                            source="extremeHeat"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                            startYear={analysis.inputs.scenario.startYear}
                        />
                    </div>
                }

                {/*Sixth  attribute end*/}

                {/*Seventh attribute start*/}

                {
                    <div
                        id="freezeThaw"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawVARBreakdownCustom
                            analysis={analysis}
                            purchased={this.props.isPurchased}
                            graphOnly="true"
                            maxToDateOnly="true"
                            customHazard="freeze-thaw"
                            customHazardLabel="Freeze Thaw"
                            startingHazardIndex="6"
                            hazardsLimit="7"
                            source="freezeThaw"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                            startYear={analysis.inputs.scenario.startYear}
                        />
                    </div>
                }

                {/*Seventh  attribute end*/}

                {/*Eighth attribute start*/}

                {
                    <div
                        id="floodSurfaceWater"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawVARBreakdownCustom
                            analysis={analysis}
                            purchased={this.props.isPurchased}
                            graphOnly="true"
                            maxToDateOnly="true"
                            customHazard="flood_surfacewater"
                            customHazardLabel="Flood Surface Water"
                            startingHazardIndex="7"
                            hazardsLimit="8"
                            source="floodSurfaceWater"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                            startYear={analysis.inputs.scenario.startYear}
                        />
                    </div>
                }

                {/*Eighth  attribute end*/}

                {/*Ninth attribute start*/}

                {
                    <div
                        id="cycloneWind"
                        style={{
                            width: "1000px",
                            height: "auto !important",
                        }}
                    >
                        <DrawVARBreakdownCustom
                            analysis={analysis}
                            purchased={this.props.isPurchased}
                            graphOnly="true"
                            maxToDateOnly="true"
                            customHazard="cyclone_wind"
                            customHazardLabel="Tropical Cyleon Wind"
                            startingHazardIndex="8"
                            hazardsLimit="9"
                            source="cycloneWind"
                            onAssetRenderComplete={this.onAssetRenderComplete}
                            startYear={analysis.inputs.scenario.startYear}
                        />
                    </div>
                }

                {/*Ninth  attribute end*/}

            </React.Fragment>
        );
    }
}
export default PDFReport;