import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classNames from "classnames";

import EITextField from "./EITextField.jsx";
import EITextArea from "./EITextArea.jsx";
//import EIColour from './EIColour.jsx';
import EISelect from "./EISelect.jsx";
import EIInteger from "./EIInteger.jsx";
//import EIWYSIWYG from './EIWYSIWYG.jsx';

import "./EditInline.scss";

class EditInline extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.state[props.field] = _.get(props.doc, props.field);
    }
    /**
     * @param {string|number} value
     */
    validate = (value) => {
        if (
            this.props.required &&
            (typeof value === "undefined" ||
                value === null ||
                (typeof value == "string" && value.trim() == "") ||
                (typeof value == "number" && isNaN(value)))
        )
            return "Required.";
        for (let func of this.props.validationFuncs) {
            validationResult = func(value);
            let isValid =
                typeof validationResult == "string"
                    ? validationResult.length == 0
                    : !!validationResult;
            if (!isValid) return validationResult;
        }
        return true;
    };
    /**
     * @param {string|number} newValue
     */
    updateDoc = (newValue) => {
        const { updateFunc, doc, field } = this.props;
        if (typeof newValue == "string") newValue = newValue.trim();

        // Only specify top-level field in update (but include entire sub-document under that field if applicable).
        const topLevelField = field.split(".")[0];
        const update = {
            _id: doc._id,
            [topLevelField]: doc[topLevelField],
        };
        _.set(update, field, newValue);

        updateFunc(update);
    };

    render = () => {
        const {
            doc,
            field,
            inputType,
            required,
            disabled,
            emptyValue,
            validationFuncs,
            editProps,
            children,
            additionalElements,
            buttonsPosition,
            className,
        } = this.props;

        const rieProps = {
            value: _.get(doc, field),
            emptyValue,
            change: this.updateDoc,
            propName: field,
            className: classNames("EditInline", className, "field-" + field),
            disabled,
            validate: this.validate,
            children,
            inputType,
            editProps,
            additionalElements,
            buttonsPosition,
        };

        if (inputType == "colour") return <EIColour {...rieProps} />;
        if (inputType == "textfield") return <EITextField {...rieProps} />;
        if (inputType == "textarea") return <EITextArea {...rieProps} />;
        if (inputType == "integer") return <EIInteger {...rieProps} />;
        if (inputType == "wysiwyg") return <EIWYSIWYG {...rieProps} />;

        rieProps.valueKey = this.props.valueKey || "value";
        rieProps.labelKey = this.props.labelKey || "label";
        rieProps.options = this.props.options;
        if (typeof rieProps.options[0] != "object") {
            rieProps.options = rieProps.options.map((o) => {
                return {
                    [rieProps.valueKey]: o,
                    [rieProps.labelKey]: o,
                };
            });
        }
        rieProps.isMulti = this.props.isMulti;
        if (inputType == "select") return <EISelect {...rieProps} />;
    };
}

EditInline.propTypes = {
    doc: PropTypes.object.isRequired,
    field: PropTypes.string.isRequired,
    updateFunc: PropTypes.func.isRequired,
    inputType: PropTypes.string.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    validationFuncs: PropTypes.arrayOf(PropTypes.func),
    editProps: PropTypes.object,
    additionalElements: PropTypes.func,
    buttonsPosition: PropTypes.string,
    // For select.
    options: PropTypes.array,
    valueKey: PropTypes.string,
    labelKey: PropTypes.string,
    className: PropTypes.string,
};

EditInline.defaultProps = {
    required: false,
    disabled: false,
    validationFuncs: [],
    editProps: {},
    className: null,
};

EditInline.types = {
    textfield: "textfield",
    textarea: "textarea",
    //colour: 'colour',
    select: "select",
    integer: "integer",
    //wysiwyg: 'wysiwyg',
};

export default EditInline;
