import React from "react";
import autoBind from "react-autobind";
import { Button, Form, FormGroup, ControlLabel, FormControl, Modal } from "react-bootstrap";

import accountManager from "../CoreFiles/account";

import "./Account.scss";

class PasswordResetModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            passwordConfirm: "",
            valid: false,
            error: null,
            successful: false,
        };
        autoBind(this);
    }

    async handleSubmit() {
        const { password, valid } = this.state;
        const { username, addCheck } = this.props;

        this.setState({ error: null });
        if (username !== undefined && addCheck) {
            if (valid) {
                try {
                    await accountManager.addPassword({ username, password });
                    this.setState({ successful: true });
                } catch (e) {
                    this.setState({ error: e.message || "" + e });
                }
            }
        } else if (valid) {
            const { token } = this.props;
            try {
                await accountManager.resetPassword({ token, password });
                this.setState({ successful: true });
            } catch (e) {
                this.setState({ error: e.message || "" + e });
            }
        }
    }

    /**
     *
     * @param {string} field
     * @param {string|number|object|set} value
     */
    handleChange(field, value) {
        this.setState({ [field]: value, validated: false });
        this.validateTimeout && clearTimeout(this.validateTimeout);
        this.validateTimeout = setTimeout(this.validate, 700);
    }

    validate() {
        const { password, passwordConfirm } = this.state;
        if (password && passwordConfirm) {
            const passwordsNotMatch = password != passwordConfirm;
            const valid = this.form.checkValidity() === true && !passwordsNotMatch;
            this.setState({
                valid,
                error: passwordsNotMatch ? "Passwords do not match" : null,
                validated: true,
            });
        } else {
            this.setState({ valid: false });
        }
    }

    render() {
        const { password, passwordConfirm, valid, error, validated, successful } = this.state;
        const { onCancel } = this.props;

        return (
            <Modal show={true} onHide={onCancel} className="PasswordResetModal">
                <Modal.Header closeButton>
                    {successful ? "" : "Please enter and confirm your new password"}
                </Modal.Header>

                <Modal.Body>
                    {successful ? (
                        <React.Fragment>
                            <p>Your password has been updated and you have been logged-in</p>
                            <Button className="btn-secondary" onClick={this.props.onDone}>
                                OK
                            </Button>
                        </React.Fragment>
                    ) : (
                        <Form
                            onSubmit={(e) => e.preventDefault()}
                            className="PasswordResetModal"
                            ref={(form) => {
                                this.form = form;
                            }}
                            noValidate
                            validated={validated}
                        >
                            <FormGroup controlId="formBasicPassword">
                                <FormControl
                                    type="password"
                                    value={password}
                                    onChange={(e) => this.handleChange("password", e.target.value)}
                                    placeholder="Password"
                                    required
                                />
                            </FormGroup>

                            <FormGroup controlId="formBasicPasswordConfirm">
                                <FormControl
                                    type="password"
                                    value={passwordConfirm}
                                    onChange={(e) =>
                                        this.handleChange("passwordConfirm", e.target.value)
                                    }
                                    placeholder="Confirm password"
                                    required
                                />
                            </FormGroup>

                            {!!error && validated && <div className="error">{error}</div>}

                            <div className="buttons">
                                <Button
                                    type="submit"
                                    className="btn-primary"
                                    onClick={this.handleSubmit}
                                    disabled={!valid}
                                >
                                    Submit
                                </Button>

                                {onCancel && (
                                    <Button className="btn-secondary" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                )}
                            </div>
                        </Form>
                    )}
                </Modal.Body>
            </Modal>
        );
    }
}

export default PasswordResetModal;
