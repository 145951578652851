import React from "react"
import { Route, Switch } from "react-router-dom"
import autoBind from "react-autobind"
import classNames from "classnames"
import { observer } from "mobx-react"
import ReactGA from "react-ga"
import PropTypes from "prop-types"

import {
    history,
    parseHashParams,
    pushLocationHashParams,
} from "../../CR-Components/CoreFiles/history"
import appState from "../../CR-Components/CoreFiles/state"
import Loading from "../../CR-Components/Loading.jsx"
import { AssetForm } from "../AssetForm/AssetForm.jsx"
import PasswordResetModal from "../../CR-Components/Account/PasswordResetModal.jsx"
import {
    finaliseAnalysisPurchase,
    updateFieldDefaults,
} from "../../CR-Components/CoreFiles/analyses"
import AboutModal from "../Modals/AboutModal.jsx"
import DataSources from "../Info/DataSources.jsx"
import DisclaimerCaveats from "../Info/DisclaimerCaveats.jsx"
import Methods from "../Info/Methods.jsx"
import PrivacyPolicy from "../Info/PrivacyPolicy.jsx"
import Terms from "../Info/Terms.jsx"
import Pricing from "../Pricing/Pricing.jsx"
import VerifyEmailModal from "../../CR-Components/VerifyEmailModal/VerifyEmailModal.jsx"
import ModalEditProfileFrom from "../../CR-Components/Account/ModalEditProfileFrom.jsx"
import AnalysisModal from "../../CR-Components/AnalysisList/AnalysisModal.jsx"

import "./App.scss"
import logoList from "../data/logos.json";
import OwlCarousel from "react-owl-carousel2"
import { AppNavbar } from "./Navbar.jsx"

//Google analytics for react
ReactGA.initialize("UA-148155584-1")

@observer
class App extends React.Component {
    static propTypes = {
        account: PropTypes.object,
        analyses: PropTypes.object,
        loading: PropTypes.bool,
        shareAnalysis: PropTypes.string,
        verifyEmail: PropTypes.bool,
    }
    constructor(props) {
        super(props)

        const getParams = new URLSearchParams(window.location.search)
        const pwResetToken = getParams.get("pwResetToken")

        this.state = {
            ...parseHashParams({
                showForm: true,
                analysisId: null,
                showEditProfileModal: false,
            }),
            loading: !!getParams.get("paymentId"),
            passwordResetToken: pwResetToken || null,
            showAbout: false,
            showAnalysisModal: false,
            showShareAnalysis: true,
            showLoginModal: false,
            showDropdownMenu: false,
        }

        autoBind(this)

        if (pwResetToken) {
            history.push({ search: "" })
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node
    }

    handleClickOutside(event) {
        if (
            this.wrapperRef &&
            !this.wrapperRef.contains(event.target) &&
            this.state.showDropdownMenu
        ) {
            this.setState({ showDropdownMenu: false })
        }
    }

    toggleDropdown() {
        this.setState((prevState) => {
            return { showDropdownMenu: !prevState.showDropdownMenu }
        })
    }

    async componentDidUpdate() {
        document.addEventListener("mousedown", this.handleClickOutside)
        if (this.state.showShareAnalysis && this.props.shareAnalysis) {
            this.setState({ showShareAnalysis: false })
            this.handleAnalysisSelect(this.props.shareAnalysis)
        }
    }

    componentDidMount() {
        this.historyUnlisten = history.listen((location) => {
            const { showForm, analysisId } = parseHashParams({}, location)
            this.setState({ showForm: !!showForm || !!analysisId, analysisId })
        })
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside)
        this.historyUnlisten()
    }

    async UNSAFE_componentWillReceiveProps(newProps) {
        if (!this.props.account && newProps.account) {
            const getParams = new URLSearchParams(window.location.search)

            if (getParams.get("paymentId")) {
                this.setState({ loading: true })
                const analysis = await finaliseAnalysisPurchase()
                this.setState({ loading: false })
                pushLocationHashParams({
                    showForm: true,
                    showModal: true,
                    thanks: true,
                    analysisId: analysis._id,
                })
            }
        }
    }

    /**
     *
     * @param {string} analysisId
     */
    handleAnalysisSelect(analysisId) {
        pushLocationHashParams({ analysisId })
        updateFieldDefaults(analysisId, this.props.analyses)
        this.closeAnalysisModal()
    }

    handleCancelPasswordReset() {
        this.setState({ passwordResetToken: null })
    }

    handleAboutModalClose() {
        this.setState({ showAbout: false })
        window.document.title = "EasyXDI"
    }

    analysisModal() {
        this.setState({
            showAnalysisModal: true,
        })
    }

    closeAnalysisModal() {
        this.setState({
            showAnalysisModal: false,
        })
        window.document.title = "EasyXDI"
    }
    /**
     * @param {number} pageWidth
     */
    sliderContent = (pageWidth) => {
        var imageCount = 8
        if (pageWidth < 899 && pageWidth > 799) {
            imageCount = 7
        } else if (pageWidth < 799 && pageWidth > 699) {
            imageCount = 6
        } else if (pageWidth < 699 && pageWidth > 599) {
            imageCount = 5
        } else if (pageWidth < 599 && pageWidth > 499) {
            imageCount = 4
        } else if (pageWidth < 499 && pageWidth > 399) {
            imageCount = 3
        } else if (pageWidth < 399 && pageWidth > 299) {
            imageCount = 3
        } else if (pageWidth < 299 && pageWidth > 199) {
            imageCount = 1
        }
        const options = {
            items: imageCount,
            nav: false,
            loop: true,
            dots: false,
            autoplay: true,
            infinite: true,
            smartSpeed: 1000,
            fluidSpeed: true,
            // autoplaySpeed: 800,
            // autoplayTimeout: 5000,
            margin: 8,
        }
        return (
            <OwlCarousel ref="car" options={options}>
                {logoList.map(({ title, src }) => (
                    <div
                        key={src}
                        className="trustedLogo"
                        style={{
                            backgroundImage: `url("/static/images/clients/${src}")`,
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            width: "6vw",
                            height: "4vw"
                        }}
                        title={title}
                    />
                ))}
            </OwlCarousel>
        )
    }

    render() {
        const {
            showForm,
            analysisId,
            passwordResetToken,
            showAbout,
            showAnalysisModal,
            showEditProfileModal,
            showLoginModal,
            showDropdownMenu,
        } = this.state

        const { account, analyses, verifyEmail } = this.props
        const { viewportWidth } = appState
        const loading = this.state.loading || this.props.loading

        return (
            <div id="App" className={classNames(showForm && "show-form")}>
                {passwordResetToken && (
                    <PasswordResetModal
                        token={passwordResetToken}
                        onCancel={this.handleCancelPasswordReset}
                        onDone={() => this.setState({ passwordResetToken: null })}
                    />
                )}

                {showAbout && (
                    <AboutModal
                        onCancel={this.handleAboutModalClose}
                        onDone={() => this.setState({ showAbout: false })}
                    />
                )}

                {showAnalysisModal && (
                    <AnalysisModal
                        analyses={analyses}
                        analysisId={analysisId}
                        onSelect={this.handleAnalysisSelect}
                        show={showAnalysisModal}
                        closeModal={this.closeAnalysisModal}
                    />
                )}

                <AppNavbar
                    account={account}
                    analyses={analyses}
                    setState={(data) => this.setState(data)}
                    setWrapperRef={this.setWrapperRef}
                    showDropdownMenu={showDropdownMenu}
                    showEditProfileModal={showEditProfileModal}
                    showLoginModal={showLoginModal}
                    toggleDropdown={this.toggleDropdown}
                />

                <Switch>
                    <Route path="/" exact={true}>
                        {!loading && (
                            <AssetForm
                                account={account}
                                analyses={analyses}
                                analysisId={
                                    analysisId && analyses.hasOwnProperty(analysisId)
                                        ? analysisId
                                        : null
                                }
                                fieldDefaultValues={appState.fieldDefaultValues}
                                // showCesiumOnly={!showForm}
                            />
                        )}
                        {/*Change in condition to make footer visible on all type of screens*/}
                        {viewportWidth > 99 && (
                            <div className="footer">
                                <div className="red-bar">
                                    <div className="arrow-down" />
                                </div>
                                <div className="logo-bar">
                                    {/*conditions for text change on mobile view*/}
                                    {viewportWidth > 799 && (
                                        <div className="title">
                                            Powered by Climate Risk Engines, technology used by
                                        </div>
                                    )}
                                    {viewportWidth < 799 && (
                                        <div className="title">Our Technology Is Trusted By</div>
                                    )}
                                    <div className="slider-logos">
                                        <div className="inner-slider">
                                            {this.sliderContent(viewportWidth)}
                                        </div>
                                    </div>
                                </div>
                                {/*condition to make gray part of footer not visible on mobile view*/}
                                {viewportWidth > 799 && (
                                    <div className="footer-grey">
                                        <a href="https://xdi.systems/" target="_blank">
                                            <img
                                                className="xdiLogo"
                                                title="XDI"
                                                src="/static/images/logo.png"
                                            />
                                            © {new Date().getFullYear()} XDI SYSTEMS
                                        </a>
                                        <a
                                            className="powered-by-cr"
                                            href="https://climateriskengines.com/"
                                            target="_blank">
                                            <img
                                                className="powered-by-cr"
                                                title="Powered by ClimateRisk Engines"
                                                src="/static/images/pwdbyCRE.png"
                                            />
                                        </a>
                                    </div>
                                )}
                            </div>
                        )}
                    </Route>
                    <Route path="/info/data_sources" exact={true}>
                        <DataSources />
                    </Route>
                    <Route path="/info/disclaimer_caveats" exact={true}>
                        <DisclaimerCaveats />
                    </Route>
                    <Route path="/info/methods" exact={true}>
                        <Methods />
                    </Route>
                    <Route path="/info/privacy_policy" exact={true}>
                        <PrivacyPolicy />
                    </Route>
                    <Route path="/info/terms_and_conditions" exact={true}>
                        <Terms />
                    </Route>
                    <Route path="/pricing" exact={true}>
                        <Pricing />
                    </Route>
                    <Route>
                        <div className="text-center mb-4">
                            <img
                                className="mb-4"
                                src="/static/images/logo.png"
                                alt=""
                                width="120"
                                height="120"
                            />
                            <h1 className="h3 mb-3 font-weight-normal">404</h1>
                            <h1 className="h3 mb-3 font-weight-normal">Page not found.</h1>
                        </div>
                    </Route>
                </Switch>
                {/*Verify email confirmation modal*/}
                {verifyEmail && <VerifyEmailModal />}
                {loading && <Loading />}

                {!!account && showEditProfileModal && (
                    <ModalEditProfileFrom
                        onCancel={() => {
                            this.setState({ showEditProfileModal: false })
                            window.document.title = "EasyXDI"
                        }}
                        showRegister={true}
                        noPassword={false}
                    />
                )}
            </div>
        )
    }
}

export default App
