import React from "react"
import autoBind from "react-autobind"
import {
    Button,
    Form,
    Accordion,
    Card,
    useAccordionToggle,
    OverlayTrigger,
    Popover,
} from "react-bootstrap"
import NumberFormat from "react-number-format"
import { MdKeyboardArrowRight } from "react-icons/md"
import classNames from "classnames"
import _ from "lodash"
import SimpleSchema from "simpl-schema"
import immutable from "immutable"
import { observer } from "mobx-react"
import PropTypes from "prop-types";
import ReactGA from "react-ga";

import { checkProp } from '../utils'
import Loading from "../Loading.jsx"
import { getArchetypeData } from "../archetype"
import SaveResult from "../SaveResult/SaveResult.jsx"

import NiceSelect from "../UI-Utils/NiceSelect.jsx"

import "./AssetCustomisation.scss"

SimpleSchema.extendOptions([
    "machineName",
    "description",
    "disableThousandsGrouping",
    "allowedValueLabels",
])
const schemaTypeMap = {
    real: Number,
    integer: SimpleSchema.Integer,
    boolean: Boolean,
    string: String,
}

// subset of fields to use from acrobase in the asset customisation form
const omFieldsInclude = [
    "buildYear",
    "heatThreshold",
    "windThreshold",
    "fireProtection",
    "foundationDesign",
    "lifetime",
    "heightAboveGround",
    "elevation",
    "interestRate",
    "marketValue",
    "mortgageTerm",
    "replacementCost",
]
// For climate val: const omFieldsInclude = ['buildYear', 'equivalentLandValue', \
// "heatThreshold", "windThreshold", "marketValue", "lifetime", "replacementCost", \
// "heightAboveGround", "elevation" ];
const windSpeedThresholds = [20, 50, 100, 250, 500, 1000, 2000]

@observer
class AssetCustomisation extends React.Component {

    static propTypes = {
        analysis: PropTypes.object,
        archetype: PropTypes.object,
        currentMaterials: PropTypes.object,
        currentValues: PropTypes.object,
        newValues: PropTypes.object,
        newMaterials: PropTypes.object,
        onSaveAnalysis: PropTypes.func,
        submitText: PropTypes.string,
        onCancel: PropTypes.func,
        revisionsLimit: PropTypes.number,
        hideFieldsWithNoDefaultValue: PropTypes.bool,
        purchased: PropTypes.bool,
        viewportWidth: PropTypes.number,
        materials: PropTypes.object,
        onSubmit: PropTypes.func,
        fieldDefaultValues: PropTypes.object
    }

    constructor(props) {
        super(props)

        this.state = {
            openCard: "0",
            valid: true,
            values: {},
            currentValues: {},
            dirtyValues: immutable.Set(),
            loading: true,
            noChanges: true,
            omSchema: {},
            currentElementsEnabled: immutable.Set(),
            elementsEnabled: immutable.Set(),
            dirtyElements: immutable.Set(),
            currentMaterials: {},
            defaultMaterials: {},
            materials: {},
            dirtyMaterials: immutable.Set(),
            formPart: 1, // used in AssetCustomisationSimple
            topLevelGroups: []
        }

        autoBind(this)

        this.materials = this.props.materials
    }

    /**
     * Corrects elementMaterial names
     *
     * @param {object} elementMaterials - elementMaterials object from analysis
     *
     * @returns {object} - edited elementMaterials object
     */
    handleElementMaterials(elementMaterials) {
        return _.reduce(elementMaterials,
            (out, val, ele) => {
                if (checkProp(val, "inundation")) {
                    val["coastal_inundation"] = val["inundation"]
                    delete val["inundation"]
                }
                if (checkProp(val, "freeze-thaw")) {
                    val["freeze_thaw"] = val["freeze-thaw"]
                    delete val["freeze-thaw"]
                }
                return {
                    ...out,
                    [ele]: val.id,
                }
            },
            {}
        )
    }

    async componentDidMount() {
        const { analysis, archetype, fieldDefaultValues } = this.props
        // eslint-disable-next-line no-undef
        if (crConstants.production) {
            ReactGA.modalview("/analysis-result/asset-customisation")
        }
        // TODO: use fieldDefaultValues
        const { fields, elements } = await getArchetypeData(
            archetype || analysis.inputs.archetype.acronym
        )
        var modifiedFields = fields
        let interestRate = {
            machine: "interestRate",
            label: "Reference 'Interest Only' interest rate",
            type: "real",
            description: "Interest rate of an 'interest only' loan for the value of the property (default value is the national average)",
            minimum: 0,
            default: 5.2,
            disableThousandsGrouping: true,
        }
        let marketValue = {
            machine: "marketValue",
            label: "Value of property",
            type: "integer",
            description: "The approximate amount the property could be sold for today (default value is the national average)",
            minimum: 0,
            default: 800000,
            disableThousandsGrouping: false,
        };
        let mortgageTerm = {
            machine: "mortgageTerm",
            label: "Mortgage term",
            type: "integer",
            description: "Total mortgage term remaining on the property (default value assumes an average mortgage of 30 years beginning today)",
            minimum: 0,
            default: 30,
            disableThousandsGrouping: true,
        }
        // TODO: rename replacementCost
        modifiedFields.replacementCost.default = 315000;
        modifiedFields = { ...modifiedFields, interestRate: interestRate };
        modifiedFields = { ...modifiedFields, marketValue: marketValue };
        modifiedFields = { ...modifiedFields, mortgageTerm: mortgageTerm };
        const fieldsFiltered = _.pick(modifiedFields, omFieldsInclude);

        fieldsFiltered.buildYear.disableThousandsGrouping = true
        fieldsFiltered.lifetime.disableThousandsGrouping = true

        fieldsFiltered.windThreshold.allowedValues = windSpeedThresholds.map((wt) => 1 / wt)
        fieldsFiltered.windThreshold.allowedValueLabels = windSpeedThresholds.map(
            (wt) => `1 in ${wt}`
        )

        const omSchema = _.mapValues(fieldsFiltered, (fieldDef, fieldName) => {
            let allowedValues =
                fieldDef.allowedValues || (fieldDef.type == "boolean" ? [false, true] : null)
            let allowedValueLabels = fieldDef.allowedValueLabels
            if (allowedValues && allowedValues.length && typeof allowedValues[0] == "object") {
                if (!allowedValueLabels) {
                    allowedValueLabels = allowedValues.map((v) => v.label)
                }
                allowedValues = allowedValues.map((v) => v.value)
            }
            return {
                machineName: fieldName,
                label: fieldDef.label,
                type: schemaTypeMap[fieldDef.type],
                description:
                    fieldDef.description.substring(0, 1).toLocaleUpperCase() +
                    fieldDef.description.substring(1) +
                    (fieldDef.description.endsWith(".") ? "" : "."),
                allowedValues,
                allowedValueLabels,
                required: true,
                min: fieldDef.hasOwnProperty("minimum") ? fieldDef.minimum : undefined,
                max: fieldDef.hasOwnProperty("maximum") ? fieldDef.maximum : undefined,
                disableThousandsGrouping: !!fieldDef.disableThousandsGrouping,
            }
        })

        this.omSchemaSS = new SimpleSchema(omSchema, {
            clean: {
                filter: true,
                autoConvert: true,
                removeEmptyStrings: true,
                trimStrings: true,
                getAutoValues: true,
                removeNullsFromArrays: true,
            },
        })

        this.validationCtx = this.omSchemaSS.newContext()

        const currentValues = {
            ..._.mapValues(fieldsFiltered, (def) => def.default),
            ...(this.props.currentValues || {}),
        }

        const currentElementsEnabled = immutable.Set(
            analysis
                ? Object.keys(analysis.inputs.asset.properties.elementMaterials)
                : elements.map((e) => e.name)
        )

        const currentMaterials = {
            ..._.reduce(elements, (cm, el, key) => {
                return { ...cm, [key]: el["default material"] }
            }, {}),
            ...(this.handleElementMaterials(this.props.currentValues.elementMaterials) || {}),
        }



        const defaultMaterials = _.reduce(fieldDefaultValues.elementMaterials,
            (out, value, key) => {
                return { ...out, [key]: value["id"] };
            }, {})

        /**
         * Returns a list of element top level group names in reverse alphabetical order
         *
         *
         * @returns {Array} - unique element top level group names
         */
        const groupList = () => {
            const mapped = Object.keys(elements).map((element) => {
                return elements[element].group.split(":")[0]
            })
            let unique = [...new Set(mapped)]

            return unique.sort().reverse()
        }

        const topLevelGroups = groupList()

        this.setState({
            omSchema,
            values: this.props.newValues || currentValues,
            currentValues,
            loading: false,
            elements,
            currentElementsEnabled: currentElementsEnabled,
            elementsEnabled: currentElementsEnabled,
            materials: this.props.newMaterials || currentMaterials,
            currentMaterials,
            defaultMaterials,
            topLevelGroups
        })
    }

    // TODO: need to remove this deprecated method
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.loading || !nextProps.analysis) return

        const currentValues = {
            ...this.state.currentValues,
            ...(nextProps.currentValues || {}),
        }
        const values = _.mapValues(this.state.values, (val, field) =>
            this.state.dirtyValues.has(field) ? val : currentValues[field]
        )

        const currentElementsEnabled = immutable.Set(
            nextProps.analysis
                ? Object.keys(nextProps.analysis.inputs.asset.properties.elementMaterials)
                : this.state.elements.map((e) => e.name)
        )
        const elementsEnabled = immutable.Set(
            Object.values(this.state.elements)
                .map((e) => e.name)
                .filter((e) =>
                    this.state.dirtyElements.has(e)
                        ? this.state.elementsEnabled.has(e)
                        : currentElementsEnabled.has(e)
                )
        )

        const currentMaterials = {
            ...this.state.currentMaterials,
            ...(this.handleElementMaterials(nextProps.currentValues.elementMaterials)
            || {}),
        }

        const materials = _.mapValues(this.state.materials, (val, el) =>
            this.state.dirtyMaterials.has(el) ? val : currentMaterials[el]
        )

        this.setState({
            currentValues,
            values,
            currentElementsEnabled,
            elementsEnabled,
            currentMaterials,
            materials,
            noChanges: !this.isChanged({
                currentValues,
                values,
                currentElementsEnabled,
                elementsEnabled,
                currentMaterials,
                materials,
            }),
        })
    }

    isChanged(state) {
        const {
            currentValues,
            values,
            currentElementsEnabled,
            elementsEnabled,
            currentMaterials,
            materials,
        } = { ...this.state, ...state }
        return (
            !_.isEqual(values, _.pick(currentValues, Object.keys(values))) ||
            !_.isEqual([...elementsEnabled].sort(), [...currentElementsEnabled].sort()) ||
            !_.isEqual(materials, currentMaterials)
        )
    }

    updateValue(field, value) {
        const values = this.omSchemaSS.clean({
            ...this.state.values,
            [field]: value,
        })
        const valid = this.validationCtx.validate(values, { keys: [field] })

        this.setState({
            values,
            dirtyValues: this.state.dirtyValues.add(field),
            noChanges: !this.isChanged({ values }),
            valid,
        })
    }

    toggleElementEnabled(element, material) {
        if (material === "Not Included" && this.state.elementsEnabled.has(element)) {
            this.setState({ elementsEnabled: this.state.elementsEnabled.delete(element) })
        } else if (!this.state.elementsEnabled.has(element) && material !== "Not Included") {
            this.setState({ elementsEnabled: this.state.elementsEnabled.add(element) })
        }
        const elementsEnabled = this.state.elementsEnabled
        this.setState({
            dirtyElements: this.state.dirtyElements.add(element),
            noChanges: !this.isChanged({ elementsEnabled }),
        })
    }

    async updateMaterial(element, material) {
        await this.toggleElementEnabled(element, material)
        const materials = { ...this.state.materials, [element]: material }

        this.setState({
            materials,
            dirtyMaterials: this.state.dirtyMaterials.add(element),
            noChanges: !this.isChanged({ materials }),
        })
    }

    async handleSubmit() {
        const { values, materials } = this.state
        await this.props.onSubmit(values, materials)
    }

    handleReset() {
        this.setState({
            values: this.state.currentValues,
            elementsEnabled: this.state.currentElementsEnabled,
            materials: this.state.currentMaterials,
            dirtyValues: immutable.Set(),
            dirtyElements: immutable.Set(),
            dirtyMaterials: immutable.Set(),
            noChanges: true,
            valid: true,
        })
    }

    toggleCard(eventKey) {
        this.setState({
            openCard: this.state.openCard === eventKey ? null : eventKey,
        })
    }

    render() {
        const { analysis, onSaveAnalysis, submitText, onCancel, revisionsLimit } = this.props
        const { valid, openCard, loading, noChanges } = this.state
        return (
            <div className="AssetCustomisation">
                {loading ? (
                    <Loading />
                ) : (
                    <React.Fragment>
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <CardHeader
                                    eventKey="0"
                                    openCard={openCard}
                                    onClick={this.toggleCard}>
                                    Design Specifications
                                </CardHeader>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>{this.renderOMFields(revisionsLimit)}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <CardHeader
                                    eventKey="1"
                                    openCard={openCard}
                                    onClick={this.toggleCard}>
                                    Element Specifications
                                </CardHeader>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>{this.renderElements(revisionsLimit)}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <div className="buttons">
                            <Button
                                onClick={this.handleReset}
                                className="reset btn-sm"
                                title={noChanges ? "No changes" : "Reset to original values"}>
                                Reset
                            </Button>

                            {!noChanges && revisionsLimit < 3 && valid && (
                                <Button
                                    onClick={this.handleSubmit}
                                    variant="success"
                                    className="submit btn-sm"
                                    title={
                                        noChanges
                                            ? "No changes"
                                            : "Perform a new analysis with these settings"
                                    }>
                                    {submitText}
                                </Button>
                            )}

                            {analysis && !analysis._id && onSaveAnalysis && (
                                <SaveResult
                                    analysis={analysis}
                                    onSubmit={onSaveAnalysis}
                                    saveButtonText="Save current analysis"
                                />
                            )}

                            {onCancel && (
                                <Button onClick={onCancel} className="cancel btn-sm">
                                    Cancel
                                </Button>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </div>
        )
    }

    makeInfoPopover(id, title, content) {
        const popover = (
            <Popover id={"popover-" + id}>
                <Popover.Title as="h3">{title}</Popover.Title>
                <Popover.Content>{content}</Popover.Content>
            </Popover>
        )

        return (
            <OverlayTrigger
                trigger="click"
                placement="auto"
                rootClose={true}
                overlay={popover}
                style={{ textAlign: "right" }}>
                <Button variant="primary" className="infopopover">
                    ?
                </Button>
            </OverlayTrigger>
        )
    }

    renderOMFields(revisionsLimit) {
        const { omSchema, currentValues, values } = this.state
        const {
            hideFieldsWithNoDefaultValue,
            purchased,
            viewportWidth,
            fieldDefaultValues
        } = this.props

        return (
            <div className="asset-fields">
                {currentValues && (
                    <div className="header">
                        <div className="placeholder" />
                        <div>Default setting</div>
                        <div>Current setting</div>
                    </div>
                )}

                {Object.values(omSchema)
                    .filter(
                        (fieldDef) =>
                            !hideFieldsWithNoDefaultValue ||
                            fieldDefaultValues[fieldDef.machineName] !== null
                    )
                    .map((fieldDef) => {
                        const def = fieldDefaultValues[fieldDef.machineName];
                        const isDefValid = def !== undefined && def !== null;

                        return <Form.Group controlId={fieldDef.machineName} key={fieldDef.machineName}>
                            <Form.Label>
                                {fieldDef.label}
                                {this.makeInfoPopover(
                                    fieldDef.machineName,
                                    fieldDef.label,
                                    fieldDef.description
                                )}
                            </Form.Label>

                            {fieldDefaultValues && (
                                <Field
                                    value={isDefValid ? def : ""}
                                    disabled={true}
                                    fieldDef={fieldDef}
                                    as={viewportWidth < 800 ? React.Fragment : "div"}
                                />
                            )}

                            <Field
                                value={values[fieldDef.machineName]}
                                simpleSchema={this.omSchemaSS}
                                validationCtx={this.validationCtx}
                                fieldDef={fieldDef}
                                onChange={(value) => this.updateValue(fieldDef.machineName, value)}
                                as={viewportWidth < 800 ? React.Fragment : "div"}
                                disabled={!purchased || revisionsLimit === 3}
                            />
                        </Form.Group>
                    })}
            </div>
        )
    }

    renderElements() {
        const {
            elements,
            currentElementsEnabled,
            elementsEnabled,
            materials,
        } = this.state
        const { purchased, viewportWidth, fieldDefaultValues } = this.props

        var materialOptions = Object.values(this.materials).map((m) => {
            return { value: m.id, label: `${m.name}` }
        })
        var notIncludedOption = { value: "Not Included", label: "Not Included" }
        materialOptions.unshift(notIncludedOption)

        return (
            <div className="elements">
                <div className="header border-sep">Elements in this archetype</div>
                {viewportWidth >= 800 && <div className="header">Default setting</div>}
                {viewportWidth >= 800 && <div className="header gridcolspan2">Current setting</div>}
                {viewportWidth < 800 && (
                    <div className="instructions">Greyed-out values are the current setting.</div>
                )}

                <div className="secondary-header border-sep" />
                <div className="secondary-header border-sep">Default Material</div>
                <div className="secondary-header">Material</div>
                {/*The following div is added for responsiveness*/}
                <div> </div>

                {Object.values(elements).map((element) => {
                    const defaultMaterial = fieldDefaultValues.elementMaterials[element.name]
                    return (
                        <React.Fragment key={element.name}>
                            <div className="border-sep element-name">
                                {element.name.substring(0, 1) +
                                    element.name
                                        .substring(1)
                                        .replace(/([A-Z])/g, " $1")
                                        .toLowerCase()}
                            </div>

                            <div className="border-sep current-material">
                                {currentElementsEnabled.has(element.name)
                                    ? defaultMaterial
                                        ? `${defaultMaterial.name}`
                                        : "???"
                                    : defaultMaterial.name}
                            </div>
                            <div>
                                <NiceSelect
                                    options={materialOptions}
                                    value={
                                        elementsEnabled && elementsEnabled.has(element.name)
                                            ? materials[element.name]
                                            : "Not Included"
                                    }
                                    onChange={(val) => this.updateMaterial(element.name, val)}
                                    placeholder="???"
                                    isDisabled={
                                        elementsEnabled && !elementsEnabled.has(element.name)
                                    }
                                    disabled={!purchased}
                                />
                            </div>
                            <div> </div>
                        </React.Fragment>
                    )
                })}
            </div>
        )
    }
}

const formatFieldError = (fieldError) => {
    if (fieldError.type == "expectedType") return `Must be a ${fieldError.dataType.toLowerCase()}.`
    if (fieldError.type == "minNumber") return `Minumum allowed value is ${fieldError.min}.`
    if (fieldError.type == "maxNumber") return `Maxumum allowed value is ${fieldError.max}.`

    return fieldError.type
        .replace(/([A-Z])/g, " $1") // split camel case error code.
        .toLowerCase()
        .replace(/^./, function (str) {
            return str.toUpperCase()
        }) // uppercase the first character.
}

class Field extends React.Component {

    static propTypes = {
        fieldDef: PropTypes.object,
        validationCtx: PropTypes.object,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number]),
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,
        className: PropTypes.string,
        name: PropTypes.string,
        as: PropTypes.string,
    }
    constructor(props) {
        super(props)

        autoBind(this)

        this.selectOptions =
            props.fieldDef.allowedValues &&
            props.fieldDef.allowedValues.map((val, idx) => {
                return {
                    value: val,
                    label: props.fieldDef.allowedValueLabels
                        ? props.fieldDef.allowedValueLabels[idx]
                        : val,
                }
            })
    }

    render() {
        const {
            fieldDef,
            validationCtx,
            value,
            onChange,
            placeholder,
            disabled,
            className,
            name,
            as,
        } = this.props

        let valid = true,
            error
        if (validationCtx) {
            error = validationCtx.validationErrors().find((err) => err.name == fieldDef.machineName)
            valid = !error
            error = !valid && formatFieldError(error)
        }

        const elProps = {
            value: value === null || typeof value == "undefined" ? "" : value,
            title: placeholder,
            placeholder,
            disabled,
            className,
            name,
        }

        if (this.selectOptions) {
            return (
                <NiceSelect
                    {...elProps}
                    multiple={false}
                    options={this.selectOptions}
                    onChange={(value) => onChange(value)}
                    value={value || ""}
                    isDisabled={elProps.disabled}
                />
            )
        }

        const isInteger = fieldDef.type == SimpleSchema.Integer
        const isNumeric = isInteger || fieldDef.type == Number

        if (isNumeric) {
            elProps.min = fieldDef.min || 0
            elProps.step = isInteger ? 1 : "any"
            if (fieldDef.hasOwnProperty("max")) elProps.max = fieldDef.max
            if (!isInteger && elProps.value !== "") {
                // Work around for DynamicNumber failing for many decimal value places.
                elProps.value = Math.round(elProps.value * 1000) / 1000
            }
        }

        return React.createElement(
            as,
            {},
            <React.Fragment>
                {isNumeric ? (
                    <NumberFormat
                        {...elProps}
                        thousandSeparator={!fieldDef.disableThousandsGrouping ? "," : undefined}
                        onValueChange={(values) => onChange && onChange(values.floatValue)}
                        className={classNames(
                            elProps.className,
                            "form-control",
                            !valid && "is-invalid"
                        )}
                    />
                ) : (
                    <Form.Control
                        type={isNumeric ? "number" : "text"}
                        {...elProps}
                        onChange={(e) => onChange(e.target.value)}
                        isInvalid={!valid}
                    />
                )}
                <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            </React.Fragment>
        )
    }
}

function CardHeader({ children, eventKey, openCard, onClick }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => onClick(eventKey))

    return (
        <Card.Header onClick={decoratedOnClick} className={openCard != eventKey && "collapsed"}>
            <MdKeyboardArrowRight size="1.5em" />
            <div>{children}</div>
        </Card.Header>
    )
}
export {
    //This is to make Field available in other components
    Field,
}

export default AssetCustomisation
