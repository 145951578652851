import { useEffect } from "react";

export const useWindowWidth = (callback) => {
    /**
     * Triggered on window resize.
     */
    function handleResize() {
        callback(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });
};