import { Button, DropdownButton, Dropdown, Navbar, Nav } from "react-bootstrap"
import { Link } from "react-router-dom";
import _ from "lodash"
import ModalLoginForm from "../../CR-Components/Account/ModalLoginForm.jsx"
import accountManager from "../../CR-Components/CoreFiles/account"
import Feedback from "../../CR-Components/Feedback/Feedback.jsx"
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useWindowWidth } from "../effects.js";
import classNames from "classnames";

export const AppNavbar = ({
    account,
    analyses,
    setState,
    setWrapperRef,
    showDropdownMenu,
    showEditProfileModal,
    showLoginModal,
    toggleDropdown,
}) => {
    const [width, setWidth] = useState(window.innerWidth);

    useWindowWidth((innerWidth) => setWidth(innerWidth));

    const contactInMenu = width < 480;
    const pricingInMenu = width < 400 && !account;

    const navbarClasses = classNames("navbar-top", {
        "navbar-top--mobile": width < 600,
        "navbar-top--ultra-mobile": width < 360,
    });

    return <Navbar className={navbarClasses} variant="dark">
        <Navbar.Brand className="align-left">
            <Link to="/">
                <img
                    alt=""
                    src="/static/images/easyXDIreverse.svg"
                    className="d-inline-block align-top logo"
                />
            </Link>
        </Navbar.Brand>
        <Nav>
            <Nav.Link href="https://xdi.systems/xdi-products/">
                XDI Products
            </Nav.Link>
            <Nav.Link onClick={() => setState({ showAbout: true })}>
                About
            </Nav.Link>
            {!pricingInMenu && <Link className="nav-link" to="/pricing">
                Pricing
            </Link>}
            {!contactInMenu && <Nav.Link href="https://xdi.systems/contact/">
                Contact
            </Nav.Link>}
            {/* Moved to profile dropdown */}
            {/* {account && <Nav.Link onClick={this.analysisModal}>My Analyses</Nav.Link>} */}

            {!account && (
                <>
                    <Button
                        // ref={this.setWrapperRef}
                        id="Account"
                        className="account-dropdown app-nav-menus"
                        onClick={() =>
                            setState((prevState) => {
                                return { showLoginModal: !prevState.showLoginModal }
                            })
                        }>
                        Login
                    </Button>

                    {showLoginModal && (
                        <ModalLoginForm
                            CV={false}
                            onCancel={() => setState({ showLoginModal: false })}
                            onSuccessfulLogin={() =>
                                setState({ showLoginModal: false })
                            }
                        />
                    )}
                </>
            )}

            {/* TODO: Break this out into module in cr-Components */}
            <DropdownButton
                ref={setWrapperRef}
                id="menu_button-dropdown"
                className="app-nav-menus"
                alignRight
                show={showDropdownMenu}
                onClick={toggleDropdown}
                title="Menu">
                {pricingInMenu && <Dropdown.Item
                    as="a"
                    onClick={() => setState({ showPricing: true })}
                >
                    Pricing
                </Dropdown.Item>}
                {contactInMenu && <a
                    className="dropdown-item"
                    href="https://xdi.systems/contact/"
                >Contact</a>}
                <Feedback
                    CV={false}
                    isGeneral={true}
                    linkText="Feedback"
                    className="feedback-dropdown"
                />
                <Link className="dropdown-item" to="/info/data_sources">
                    Data Sources
                </Link>
                <Link className="dropdown-item" to="/info/methods">
                    Scientific Methods
                </Link>
                <Link className="dropdown-item" to="/info/terms_and_conditions">
                    Terms and Conditions
                </Link>
                <Link className="dropdown-item" to="/info/privacy_policy">
                    Privacy Policy
                </Link>
                <Link className="dropdown-item" to="/info/disclaimer_caveats">
                    Disclaimer and Caveats
                </Link>
                {account && (
                    <>
                        <Dropdown.Divider />
                        <Dropdown.Item
                            as="a"
                            eventKey="1"
                            style={{ cursor: "default", color: "#646464" }}>
                            {account.username}
                        </Dropdown.Item>

                        {!_.isEmpty(analyses) && (
                            <Dropdown.Item
                                as="a"
                                className="dropdown-item"
                                onClick={() =>
                                    setState({ showAnalysisModal: true })
                                }
                                eventKey="2"
                                style={{ cursor: "pointer" }}>
                                My Analyses
                            </Dropdown.Item>
                        )}

                        <Dropdown.Item
                            eventKey="3"
                            onClick={() =>
                                setState({
                                    showEditProfileModal: !showEditProfileModal,
                                })
                            }>
                            Edit Profile
                        </Dropdown.Item>

                        <Dropdown.Item eventKey="4">
                            <Button onClick={accountManager.logout}>Logout</Button>
                        </Dropdown.Item>
                    </>
                )}
            </DropdownButton>
        </Nav>
    </Navbar>
}

AppNavbar.propTypes = {
    account: PropTypes.any,
    analyses: PropTypes.any,
    setState: PropTypes.func,
    setWrapperRef: PropTypes.func,
    showDropdownMenu: PropTypes.bool,
    showEditProfileModal: PropTypes.bool,
    showLoginModal: PropTypes.bool,
    toggleDropdown: PropTypes.func,
}