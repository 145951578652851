import React from "react";
import autoBind from "react-autobind";
import { Button, Modal } from "react-bootstrap";
// import "../AssetForm/AssetForm.scss";

class VerifyEmailModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            show: true,
        };
        autoBind(this);
    }

    closeModal() {
        this.setState({ show: false });
    }

    render() {
        const { loading } = this.state;

        return (
            <Modal
                show={this.state.show}
                onHide={() => this.closeModal()}
                className="PurchaseModal"
            >
                <Modal.Header closeButton>Email Verification</Modal.Header>
                <Modal.Body>
                    <p>Email verified successfully.</p>
                </Modal.Body>

                <Modal.Footer>
                    {!loading && (
                        <Button variant="secondary" onClick={() => this.closeModal()}>
                            Close
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default VerifyEmailModal;
