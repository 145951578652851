import React, { useEffect } from "react"
import { Button, Modal } from "react-bootstrap"
import PropTypes from "prop-types"
import { hubSpotTracking } from "../../CR-Components/hubSpot.js"
// import "../AssetForm/AssetForm.scss";

const ThankYouPurchase = (props) => {
    const { onCancel } = props

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Climate Valuation: Purchase Complete"
        hubSpotTracking("setPath", "/purchase-complete", "trackPageView")
    }, [])
    return (
        <Modal show={true} onHide={onCancel} className="PurchaseModal">
            <Modal.Header closeButton>Thanks for purchasing an EasyXDI report!</Modal.Header>

            <Modal.Body>
                <p>Your transaction has been completed.</p>
                <p>
                    <strong>Enjoy access to all EasyXDI{"'"}s features!</strong>
                </p>
                <p />

                <p>
                    <strong>Commercial Use:</strong> This report can now be used commercially
                </p>

                <p>
                    <strong>Asset Customisation:</strong> Use the {'"'}Settings{'"'} tab on your
                    analysis to change any settings used in the analysis. You can change the
                    settings and re-run the analysis twice per purchase.
                </p>

                <p>
                    <strong>View individual hazard contribution to risk rating:{" "}</strong>
                    The {'"'}Ratings{'"'} tab now shows the individual risk fraction
                    contribution of each hazard (solid lines). The failure risk is also
                    displayed (dotted lines).{" "}
                </p>

                <p>
                    <strong>Saved Reports:{" "}</strong>Your report is saved in your account, and can
                    be accessed at any time by logging in.
                </p>

                <p>
                    <strong>Printable report:{" "}</strong> The report can be downloaded and printed
                    using the download button in the top right corner of the analysis. The
                    EasyXDI report contains all the information you have seen in your analysis
                    results with additional information such as maps, explanations and
                    definitions.
                </p>
            </Modal.Body>

            <Modal.Footer style={{ display: "block" }}>
                <div>
                    <div className="float-right">
                        <div className="buttons">
                            <Button variant="secondary" onClick={onCancel}>
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

ThankYouPurchase.propTypes = {
    onCancel: PropTypes.func.isRequired, // Changes states to hide modal
}

export default ThankYouPurchase
