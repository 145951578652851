import React from "react";
import { Modal } from "react-bootstrap";
import "../Feedback/Feedback.scss";

class CopyLinkModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="Feedback">
                <Modal show={this.props.show} onHide={this.props.onHide} size="sm">
                    <Modal.Body>
                        <p style={{ marginTop: "1rem", textAlign: "center" }}>
                            Link Copied to Clipboard.
                        </p>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default CopyLinkModal;
