import { unzip, zip } from 'lodash'

const hazardLabels = h => ({
    "flood_riverine": "Riverine Flooding",
    "inundation": "Coastal Inundation",
    "heat": "Extreme Heat",
    "forest_fire": "Forest Fire",
    "wind": "Extreme Wind",
    "soil_movement": "Soil Subsidence",
    "freeze-thaw": "Freeze Thaw",
    "flood_surfacewater": "Surface Water Flooding",
    "cyclone_wind": "Tropical Cyclone Wind",
}[h])

export default (id, properties, scenario) => {
    const yearCount = scenario.endYear - scenario.startYear + 1
    const years = Array(yearCount)
        .fill()
        .map((_, i) => i + scenario.startYear)

    const hazards = scenario.hazards.map(hazardLabels)
    const cols = (field) => hazards.map((h) => `${h} ${field}`)

    const {
        name,
        address,
        archetype,
        replacementCost,
        totalFailure,
        totalRisk,
        totalRiskCost,
        failureHazards,
        riskHazards,
    } = properties

    const meta = [
        `"id","${id}"`,
        `"name","${name}"`,
        `"address","${address}"`,
        `"archetype","${archetype}"`,
        `"replacementCost",${replacementCost}`,
    ]

    const headers = [
        'Year',
        'Asset Failure Probability',
        'Asset Value at Risk',
        'Technical Insurance Premium (TIP)',
        ...cols('Failure Probability'),
        ...cols('Value at Risk'),
    ]

    const rows = [
        ...meta,
        `"${headers.join('","')}"`,
        ...zip(
            years,
            totalFailure,
            totalRisk,
            totalRiskCost,
            ...unzip(failureHazards),
            ...unzip(riskHazards)
        ),
    ]

    return rows.join('\n')
}
