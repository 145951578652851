import React from "react";
import autoBind from "react-autobind";
import { Accordion, Card } from "react-bootstrap";
import PropTypes from 'prop-types'
import ReactGA from "react-ga";

import { CardHeader } from "../../CR-Components/UI-Utils/ui-utils.jsx";
import WarningsDetail from "../../CR-Components/Results/Warnings.jsx";
import AssetDetail from "../../CR-Components/Results/AssetDetail.jsx";
import ArchetypeDetail from "../../CR-Components/Results/ArchetypeDetail.jsx";
import Scenarios from "../../CR-Components/Results/Scenarios.jsx";

import "./AnalysisResult.scss";

class Detail extends React.Component {

    static propTypes = {
        analysis: PropTypes.object,
    }
    
    constructor(props) {
        super(props);
        this.state = {
            openCard: null,
        };
        autoBind(this);
    }

    /**
     * @param {string} eventKey
     */
    toggleCard(eventKey) {
        this.setState({
            // we only have one card that we want to open and close
            openCard: this.state.openCard === eventKey ? null : eventKey,
        });
        if (crConstants.production) {
            if (eventKey === "1") {
                ReactGA.modalview("/analysis-result/detail-tab/archetype-detail")
            } else if (eventKey === "2") {
                ReactGA.modalview("/analysis-result/detail-tab/scenarios")
            }
        }
    }

    componentDidMount() {
        if (crConstants.production) ReactGA.modalview("/analysis-result/detail-tab")
    }

    render() {
        const { analysis } = this.props;
        const { openCard } = this.state;

        return (
            <React.Fragment>
                <div className="Detail">
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <CardHeader eventKey="0" openCard={openCard} onClick={this.toggleCard}>
                                Asset Details
                            </CardHeader>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <AssetDetail analysis={analysis} />

                                    {analysis.warnings && <WarningsDetail analysis={analysis} />}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <CardHeader eventKey="1" openCard={openCard} onClick={this.toggleCard}>
                                Archetype Specifications
                            </CardHeader>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <ArchetypeDetail
                                        analysis={analysis}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <CardHeader eventKey="2" openCard={openCard} onClick={this.toggleCard}>
                                Scenarios
                            </CardHeader>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    <Scenarios analysis={analysis} />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
            </React.Fragment>
        );
    }
}

export default Detail;
