import Cookies from "js-cookie"

import appState from "./state"
import { loadAllAnalyses } from "./analyses"
import { hubSpotFormLogin } from "../hubSpot.js"

const appName = function () {
    // eslint-disable-next-line no-undef
    if (crConstants.ClimateValuation) {
        return "climatevaluation"
    } else {
        return "easy"
    }
}
/**
 *
 * Function to log in user using API key Cookie
 *
 * @param {string} apiKey
 */
const loginWithApiKey = async (apiKey) => {
    appState.account = null
    Cookies.set("crapikey", apiKey, { expires: 7 })
    return await doInitialLogin()
}

const doInitialLogin = async () => {
    // Initial login if apikey available.
    const apiKey = Cookies.get("crapikey")
    if (apiKey) {
        try {
            const account = await jQuery.ajax({
                method: "GET",
                url: `${crConstants.authHost}/v1/account`,
                headers: {
                    Authorization: apiKey,
                },
                dataType: "json",
            })
            if (account && account.username) {
                appState.account = account
                await loadAllAnalyses()
                return account
            } else {
                console.log("Error loading user:", account)
            }
        } catch (e) {
            console.log("Error loading user:", e)
        }
    }
}

/**
 *
 * @param {string} username
 * @param {string} password
 */
const login = async (username, password) => {
    username = username.toLowerCase()
    const data = { username }
    if (password) {
        data.password = password
    } else {
        data.create = JSON.stringify({
            mail: {
                app: appName(),
                template: "welcome-nopass",
            },
        })
    }

    try {
        const response = await jQuery.ajax({
            method: "GET",
            url: `${crConstants.authHost}/v1/account`,
            data,
            dataType: "json",
        })

        if (response.username) {
            appState.account = response
            Cookies.set("crapikey", response.apiKey, { expires: 7 })
            await loadAllAnalyses()
            hubSpotFormLogin(username)
            return response
        } else {
            throw response.message || "An unknown error occurred."
        }
    } catch (e) {
        const err =
            (e.responseJSON && e.responseJSON.error && e.responseJSON.error.message) ||
            e.message ||
            e.statusText ||
            "" + e
        if (err.toLowerCase().includes("password required")) {
            return "password required"
        }
        console.log("Login error:", e)
        throw err == "error" ? "Auth system unavailable, please try again later." : err
    }
}
/**
 *
 * @param {string} username
 * @param {string} authenticationKey
 */
const authenticate = async (username, authenticationKey) => {
    try {
        const response = await jQuery.ajax({
            method: "POST",
            url: `${crConstants.authHost}/v1/account/authentication_code`,
            data: { username: username, authenticationKey: authenticationKey },
            dataType: "json",
        })
        if (response) {
            appState.account = response
            Cookies.set("crapikey", response.apiKey, { expires: 7 })
            await loadAllAnalyses()
            return response
        }
    } catch (e) {
        const err = e.responseJSON.error.message
        throw err
    }
}

/**
 *
 * @param {object} data
 */
const register = async (data) => {
    try {
        const response = await jQuery.ajax({
            method: "PUT",
            url: `${crConstants.authHost}/v1/account`,
            data: {
                mail: JSON.stringify({
                    app: appName(),
                    template: "welcome-register",
                }),
                ...data,
            },
            dataType: "json",
        })

        return response
    } catch (e) {
        const err =
            (e.responseJSON && e.responseJSON.error && e.responseJSON.error.message) ||
            e.message ||
            e.statusText ||
            "" + e
        console.log("e", e)
        throw err
    }
}

/**
 *
 * @param {string} username
 */
const forgotPasswordRequest = async (username) => {
    try {
        const response = await jQuery.ajax({
            method: "GET",
            url: `${crConstants.authHost}/v1/account/password_reset`,
            data: {
                username,
                mail: JSON.stringify({
                    app: appName(),
                    template: "forgot-password",
                }),
            },
            dataType: "json",
        })
    } catch (e) {
        const err =
            (e.responseJSON && e.responseJSON.error && e.responseJSON.error.message) ||
            e.message ||
            e.statusText ||
            "" + e
        console.log("e", e)
        throw err
    }
}

/**
 *
 * @param {object} data
 */
const resetPassword = async (data) => {
    try {
        const response = await jQuery.ajax({
            method: "PUT",
            url: `${crConstants.authHost}/v1/account/password_reset`,
            data,
            dataType: "json",
        })

        if (response.username) {
            appState.account = response
            Cookies.set("crapikey", response.apiKey, { expires: 7 })
            return response
        } else {
            throw response.message || "An unknown error occurred."
        }
    } catch (e) {
        const err =
            (e.responseJSON && e.responseJSON.error && e.responseJSON.error.message) ||
            e.message ||
            e.statusText ||
            "" + e
        console.log("e", e)
        throw err
    }
}
/**
 *
 * @param {object} data
 */
const addPassword = async (data) => {
    try {
        const response = await jQuery.ajax({
            method: "PUT",
            url: `${crConstants.authHost}/v1/account/password_add`,
            data,
            dataType: "json",
        })
        if (response.username) {
            appState.account = response
            Cookies.set("crapikey", response.apiKey, { expires: 7 })
            return response
        } else {
            throw response.message || "An unknown error occurred."
        }
    } catch (e) {
        const err =
            (e.responseJSON && e.responseJSON.error && e.responseJSON.error.message) ||
            e.message ||
            e.statusText ||
            "" + e
        console.log("e", e)
        throw err
    }
}

const logout = () => {
    appState.account = null
    appState.analyses = {}
    Cookies.remove("crapikey")
    // setLocationHashParams({});
}
/**
 *
 * @param {object} data
 */
const editProfile = async (data) => {
    try {
        const apiKey = Cookies.get("crapikey")
        const response = await jQuery.ajax({
            method: "POST",
            url: `${crConstants.authHost}/v1/account`,
            headers: {
                Authorization: apiKey,
            },
            data: {
                ...data,
            },
            dataType: "json",
        })
        if (response.data) {
            appState.account = response.data
            Cookies.set("crapikey", response.data.apiKey, { expires: 7 })
            return response
        } else {
            throw response.message || "An unknown error occurred."
        }
    } catch (e) {
        const err =
            (e.responseJSON && e.responseJSON.error && e.responseJSON.error.message) ||
            e.message ||
            e.statusText ||
            "" + e
        console.log("e", e)
        throw err
    }
}

//  To update newsletter subscription status
/**
 *
 * @param {object} data
 */
const doNewsLetter = async (data) => {
    try {
        const apiKey = Cookies.get("crapikey")
        const response = await jQuery.ajax({
            method: "POST",
            url: `${crConstants.authHost}/v1/account/newsletter`,
            headers: {
                Authorization: apiKey,
            },
            data: {
                ...data,
            },
            dataType: "json",
        })

        if (response) {
            appState.account = response
            Cookies.set("crapikey", response.apiKey, { expires: 7 })
            return response
        } else {
            throw response.message || "An unknown error occurred."
        }
    } catch (e) {
        const err =
            (e.responseJSON && e.responseJSON.error && e.responseJSON.error.message) ||
            e.message ||
            e.statusText ||
            "" + e
        console.log("e", e)
        throw err
    }
}

const checkWhitelist = async function (body) {
    let hasAccess = false;

    const res = await fetch("https://easyxdi-allowlist.climatica.workers.dev", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    })
        .then((r) => r.text())
        .catch(() => {
            hasAccess = false;
            console.log("whitelist check error");
        });

    hasAccess = res === "Authorised"

    return hasAccess
}

/**
 *
 * @param {object} account
 */
const isOnWhitelist = async function (account) {
    // If the account doesn't exist, or has no username.
    if (!account?.username) return false;

    return await checkWhitelist({ "allow": account.username.toLowerCase() });
}


/**
 *
 * @param {object} analysis
 * @param {object} account
 */
const isPurchased = async function (analysis, account) {
    // If the analysis exists, is paid for, and that payment has a date.
    if (analysis?.payment?.date) return true;

    // If the account doesn't exist, or has no username.
    if (!account?.username) return false;

    return await checkWhitelist({ "allow": account.username.toLowerCase() });
}


/**
 *
 * @param {object} analysis
 * @param {object} account
 */
const canExport = async function (analysis, account) {
    // If the analysis exists, is paid for, and that payment has a date.
    if (analysis?.payment?.date) return true;

    // If the account doesn't exist, or has no username.
    if (!account?.username) return false;

    return await checkWhitelist({ "export": account.username.toLowerCase() });
}


// To get user country location using IP address
// const getCountryNameFromIP = async () => {
//   try {
//     const response = await jQuery.ajax({
//       method: 'GET',
//       url: `http://ip-api.com/json`,
//       dataType: 'json',
//     });
//     return response;
//   }catch (e){
//     const err = e.responseJSON && e.responseJSON.error && e.responseJSON.error.message || e.message  || e.statusText || ""+e;
//     console.log('e', e);
//     throw err;
//   }
// }

export default {
    doNewsLetter,
    doInitialLogin,
    login,
    loginWithApiKey,
    authenticate,
    register,
    forgotPasswordRequest,
    resetPassword,
    logout,
    addPassword,
    editProfile,
    isPurchased,
    canExport,
    isOnWhitelist,
}
