import React from "react"
import autoBind from "react-autobind"
import { Accordion, Card } from "react-bootstrap"

import { CardHeader } from "../UI-Utils/ui-utils.jsx"

class WarningsDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openCard: null,
        }
        autoBind(this)
    }

    toggleCard(eventKey) {
        this.setState({
            // we only have one card that we want to open and close
            openCard: this.state.openCard === eventKey ? null : eventKey,
        })
    }

    render() {
        const { analysis } = this.props
        const { openCard } = this.state

        const debug_warnings = analysis.warnings.filter((word) => word.startsWith("DEBUG"))
        const display_warnings = analysis.warnings.filter((word) => !word.startsWith("DEBUG"))

        debug_warnings.map((x) => console.log("Ana Warning:", x))

        return (
            <div className="error-details">
                <Accordion>
                    <Card>
                        <CardHeader eventKey="0" openCard={openCard} onClick={this.toggleCard}>
                            Warnings
                        </CardHeader>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <ul className="warnings">
                                    {display_warnings.map((wrn, i) => (
                                        <li key={i}>{wrn}</li>
                                    ))}
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        )
    }
}

export default WarningsDetail
