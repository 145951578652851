import React, { useEffect } from "react"
import ReactGA from "react-ga"

import { hubSpotTracking } from "../../CR-Components/hubSpot.js"

const DisclaimerCaveats = () => {
    useEffect(() => {
        if (crConstants.production) {
            ReactGA.modalview("/disclaimer_caveats")
            window.document.title = "Disclaimer and Caveats"
            hubSpotTracking("setPath", "/disclaimer_caveats", "trackPageView")
        }
    }, [])

    return (
        <div className="info-page">
            <div className="container" style={{ paddingBottom: "10%", paddingTop: "4%" }}>
                <img
                    src="/static/images/xdi_logo.png"
                    width="30%"
                    align="right"
                    alt="XDI - The Cross Dependency Initiative"
                />

                <h1 lang="en-AU" className="western">
                    DISCLAIMER / CAVEATS
                </h1>

                <h1 lang="en-AU" style={{ marginBottom: "0.28cm", letterSpacing: "0.8pt" }}>
                    1 IMPORTANT INFORMATION
                </h1>

                <h2 lang="en-AU" className="western">
                    <a name="_Toc2782685"></a>
                    <span lang="en-AU">
                        1.1 What Products and Services on this Site do and do not do.
                    </span>
                </h2>

                <p lang="en-AU" className="western">
                    The Products and Services on this Site send information the Climate Risk Engines
                    operated by Climate Risk P/L that process this information and return results.{" "}
                </p>
                <p lang="en-AU" className="western">
                    Generally, users select or create Representative assets which are synthetic
                    representations of a real or hypothetical asset which may include real estate
                    properties, infrastructure or other physical objects. Information about this
                    representative asset is processed together with other relevant information such
                    as location, age or value.
                </p>
                <p lang="en-AU" className="western">
                    The Climate Risk Engines integrate the information sent to it with information
                    from a large number of national and international datasets from government
                    institutions, universities and private companies to provide a generalised model
                    of how climate change may affect a number of physical risks to the
                    Representative Asset, all else being equal. The physical risks covered by the
                    analysis will be presented where the results are presented (on a Site or in a
                    Report).
                </p>

                <p lang="en-AU" className="western">
                    However, the Climate Risk Engines do not provide a forecast, prediction or
                    projection based on any real or planned asset. The analysis does not purport to
                    'cover the field' of all potential risks associated with climate change nor to
                    address coincidence or correlation between such risks. For example, extremes of
                    precipitation and flooding may be coincident with extreme wind-storms which can
                    damage a building making it more vulnerable to damage.
                    <br />
                    The Climate Risk Engines do not necessarily take into account the impact of any
                    actual built infrastructure, modifications, adaptations or resilience-building
                    measures (public or private) that have been, or may be, applied that reduce (or
                    exacerbate) the relevant hazard. It makes representation is made in relation to
                    the availability or coverage of insurance to a real or planned asset.
                </p>

                <br />
                <h2
                    lang="en-AU"
                    className="western"
                    style={{ marginLeft: "1.02cm", textIndent: "-1.02cm" }}>
                    <a name="_Toc2782686"></a>
                    1.2 No forecast or prediction:&nbsp;
                </h2>
                <p lang="en-AU" className="western">
                    The Climate Risk Engines, Products and Services on this Site do not purport to
                    generate statements of fact, forecasts or predictions, nor imply any
                    representation regarding the likelihood, risk, probability, possibility or
                    expectation of any future matter. To the extent that any statements made or
                    information contained or generated on this Site might be considered
                    forward-looking in nature, they are subject to physical, political, regulatory,
                    technological and stakeholder-related variables and uncertainties that could
                    cause actual results to differ materially. You are cautioned not to place undue
                    reliance on any such forward-looking statements, which reflect assumptions and
                    information available only as of the date of modelling. No explicit or implicit
                    assumption is made in relation to the current or future alignment of any climate
                    change-related scenarios with climate-related policies of any government at
                    international, national or sub-national level. The impacts of climate change
                    analysed are only for a range of greenhouse gas emission and global warming
                    scenarios presented in the Intergovernmental Panel on Climate Change Assessment
                    Report (IPCC, 2014, IPCC 2007).
                </p>

                <h2
                    lang="en-AU"
                    className="western"
                    style={{ marginLeft: "1.02cm", textIndent: "-1.02cm" }}>
                    <a name="_Toc2782687"></a>
                    1.3 No financial advice:{" "}
                </h2>
                <p lang="en-AU" className="western">
                    The information presented does not comprise, constitute or provide, nor should
                    it be relied upon as, investment or financial advice, credit ratings, an
                    advertisement, an invitation, a confirmation, an offer or a solicitation, or
                    recommendation, to buy or sell any security or other financial, insurance,
                    credit or lending product or to engage in any investment activity, or an offer
                    of any financial service. This information does not purport to quantify risk to
                    the subject land, infrastructure, buildings or other physical assets or any part
                    thereof, nor make any representation in regards to the saleability,
                    mortgageability, insurability, or defects, of any subject property, nor its
                    suitability for purchase, holding or sale. The Modelling Outputs presented are
                    provided with the understanding and expectation that each user will, with due
                    care, conduct their own investigation and evaluation of any real or planned
                    asset at a specific location.
                </p>

                <h2
                    lang="en-AU"
                    className="western"
                    style={{ marginLeft: "1.02cm", textIndent: "-1.02cm" }}>
                    <a name="_Toc2782688"></a>
                    1.4 EXCLUSION OF LIABILITY:&nbsp;
                </h2>
                <p lang="en-AU" className="western">
                    TO THE EXTENT PERMITTED BY LAW WE AND OUR DATA AND ANALYTIC SUPPLIERS WILL NOT
                    BE LIABLE FOR ANY LOSS OR DAMAGE, WHETHER IN CONTRACT, TORT (INCLUDING
                    NEGLIGENCE), BREACH OF STATUTORY DUTY OR OTHERWISE, EVEN IF FORESEEABLE, ARISING
                    UNDER OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY INFORMATION, DATA OR
                    CONTENT OBTAINED VIA OUR SERVICES, INCLUDING (WITHOUT LIMITATION) THE MODELLING
                    OUTPUTS PRESENTED.
                </p>
                <br />

                <h1 lang="en-AU" style={{ marginBottom: "0.28cm", letterSpacing: "0.8pt" }}>
                    2 Scientific Limitations
                </h1>
                <p lang="en-AU" className="western">
                    The information presented has been generated using an expert selection of the
                    scientific methods and computational modelling techniques available at the time
                    of creation. However, at any time there are known limitations of which you
                    should make yourself aware. These are constantly refined and updated and are
                    clearly specified on the Site.
                </p>

                <h2
                    lang="en-AU"
                    className="western"
                    style={{ marginLeft: "1.02cm", textIndent: "-1.02cm" }}>
                    <a name="_Toc2782689"></a>
                    2.1 Scope of Modelling and Scenarios
                </h2>
                <p lang="en-AU" className="western">
                    Science is not able to definitively predict the exact range or rate of future
                    global warming; or the scale and rate of change of atmospheric and oceanic
                    processes that may be hazardous, including temperatures, precipitation, wind and
                    the rise in sea levels that result from this warming. Many variables will
                    determine society's continuing rate of emission of 'greenhouse gases' (including
                    political, regulatory, technological and behavioural factors), and how the
                    Earth's natural systems respond. However, we can estimate a range of potential
                    impacts across what mainstream science considers to be a plausible set of
                    scenarios for future ocean and atmospheric behaviour. The scenarios used are
                    specified in the relevant Scenarios sections of websites and reports where the
                    Climate Risk Engines are used.
                </p>
                <h2 lang="en-AU" className="western">
                    2.2 Scope of Financial Modelling
                </h2>
                <p lang="en-AU" className="western">
                    The Climate Risk Engines compute a number of financial indicators based on the
                    concept of Annual Average Loss (AAL) which is the computed financial loss in a
                    future year due to the damage to component elements of an asset and the cost of
                    their replacement and/or consequential costs such as compensation payments of
                    lost income.
                </p>
                <p lang="en-AU" className="western">
                    The model also uses an associated interest rate to calculate the extent to
                    which the diversion of funds to cover Annual Average Loss, would represent a
                    reduced ability to make repayments on the financing (e.g. mortgage) property
                    with a commensurate reduction in principal that can be borrowed. This is a
                    useful indicator of a potential difference in value caused by climate change
                </p>

                <br />
            </div>
            <div
                className="footer"
                style={{ verticalAlign: "middle", textAlign: "center", marginTop: "5em" }}>
                <p>Brought to you by:</p>
                <img src="/static/images/cr_logo.png" width="20%" alt="Climate Risk Pty Ltd" />
                <img
                    src="/static/images/xdi_logo.png"
                    width="30%"
                    alt="XDI - The Cross Dependency Initiative"
                />
            </div>
        </div>
    )
}

export default DisclaimerCaveats
