import React from "react";
import autoBind from "react-autobind";
import { Button, Form, FormGroup, FormControl } from "react-bootstrap";
import ReactGA from "react-ga";
import PropTypes from "prop-types";

import { hubSpotFormEditDetails } from "../../CR-Components/hubSpot.js";
import accountManager from "../CoreFiles/account";
import appState from "../CoreFiles/state";
import { EasyEditProfileLoading } from "../../CR-Components/Loading.jsx";
import { CVEditProfileLoading } from "../../CR-Components/Loading.jsx";
import "./Account.scss";

class EditProfileFrom extends React.Component {
    static propTypes = {
        editProfileButtonText: PropTypes.string,
        onCancel: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            username: appState.account.username,
            password: "",
            passwordConfirm: "",
            valid: true,
            validated: false,
            error: null,
            message: null,
            loading: false,
            resetPasswordSelected: false,
            newsletterSelected: false,
            newsLetterValue: 0,
            editProfileButtonText: "Submit",
            currentUserNewsLetterValue: appState.account.newsletterSubscription,
        };
        //this.handlePasswordReset = this.handlePasswordReset.bind(this);
        autoBind(this);
    }

    componentDidMount() {
        if (this.state.currentUserNewsLetterValue === true) {
            this.setState({ newsLetterValue: 1 });
        } else {
            this.setState({ newsLetterValue: 0 });
        }
    }

    // TODO: this function is needlessly complex. Just update the values in the db
    // to whatever is in the form. No need to be selective.
    async handleSubmit() {
        const {
            username,
            password,
            resetPasswordSelected,
            newsletterSelected,
            newsLetterValue,
            valid,
        } = this.state;

        this.setState({ error: null, loading: true });

        if (valid) {
            try {
                let data = {
                    username: username,
                };

                if (newsletterSelected) {
                    data["newsletter"] = 1;
                    await accountManager.doNewsLetter(data);
                } else {
                    if (this.state.newsLetterValue === 0) {
                        data["newsletter"] = 0;
                    } else {
                        if (this.state.currentUserNewsLetterValue === true) {
                            data["newsletter"] = 1;
                        }
                    }
                    await accountManager.doNewsLetter(data);
                }

                if (resetPasswordSelected) data.password = password;
                await accountManager.editProfile(data);

                // Tracking code
                // eslint-disable-next-line no-undef
                if (!crConstants.ClimateValuation) {
                    ReactGA.event({
                        category: "User",
                        action: "Edit Profile",
                        label: "profile",
                        value: 2,
                    });
                    // End Tracking
                    hubSpotFormEditDetails(username, !!newsLetterValue);
                }

                this.reset();
                this.setState({
                    loading: false,
                    message: "Details updated successfully",
                });
            } catch (e) {
                this.setState({ error: e.message || "" + e });
                this.setState({ loading: false });
            }
        }
    }

    reset() {
        this.setState({
            username: appState.account.username,
            password: "",
            passwordConfirm: "",
            valid: true,
            validated: false,
            error: null,
            message: null,
            loading: false,
            resetPasswordSelected: false,
            newsletterSelected: false,
            newsLetterValue: 0,
        });
    }

    isComplete() {
        const { username, password, passwordConfirm, resetPasswordSelected } = this.state;
        return !!(
            username &&
            ((!!resetPasswordSelected && password && passwordConfirm) || !resetPasswordSelected)
        );
    }

    /**
     *
     * @param {string} field
     * @param {string|number|boolean|object} value
     */
    handleChange(field, value) {
        this.setState({ [field]: value, validated: false });
        this.validateTimeout && clearTimeout(this.validateTimeout);
        this.validateTimeout = setTimeout(this.validate, 700);
        if (field === "newsletterSelected") {
            if (value === true) {
                this.setState({ newsLetterValue: 1 });
            } else {
                this.setState({ newsLetterValue: 0 });
            }
        }
    }

    validate() {
        if (this.isComplete()) {
            if (this.state.resetPasswordSelected) {
                const passwordsMatch = this.state.password == this.state.passwordConfirm;
                const valid = !!this.form && this.form.checkValidity() === true && !!passwordsMatch;
                this.setState({
                    valid,
                    error: passwordsMatch ? null : "Passwords do not match",
                    validated: true,
                });
            } else {
                const valid = !!this.form && this.form.checkValidity() === true;
                this.setState({
                    valid,
                    error: null,
                    validated: true,
                });
            }
        } else {
            this.setState({ valid: false });
        }
    }

    render() {
        const {
            username,
            password,
            passwordConfirm,
            valid,
            error,
            message,
            resetPasswordSelected,
            validated,
            loading,
        } = this.state;
        const { editProfileButtonText, onCancel } = this.props;
        // eslint-disable-next-line no-undef
        const crConstantFlag = `${crConstants.ClimateValuation}`;

        return (
            <Form
                onSubmit={(e) => e.preventDefault()}
                className="LoginForm"
                ref={(form) => {
                    this.form = form;
                }}
                noValidate
                validated={validated}
            >
                {!message && (
                    <React.Fragment>
                        <FormGroup controlId="formBasicEmail">
                            <FormControl
                                type="email"
                                value={username}
                                onChange={(e) => this.handleChange("username", e.target.value)}
                                placeholder="Email address"
                                required
                                disabled={loading}
                            />
                        </FormGroup>

                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check
                                type="checkbox"
                                label="Keep me up to date"
                                defaultChecked={this.state.currentUserNewsLetterValue}
                                onChange={(e) => {
                                    this.handleChange("newsletterSelected", e.target.checked);
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check
                                type="checkbox"
                                label="Reset Password"
                                onChange={(e) => {
                                    this.handleChange("resetPasswordSelected", e.target.checked);
                                }}
                            />
                        </Form.Group>
                    </React.Fragment>
                )}

                {!!resetPasswordSelected && (
                    <React.Fragment>
                        <FormGroup controlId="formBasicPassword">
                            <FormControl
                                type="password"
                                value={password}
                                onChange={(e) => this.handleChange("password", e.target.value)}
                                placeholder="Password"
                                required
                                disabled={loading}
                            />
                        </FormGroup>

                        {!!resetPasswordSelected && (
                            <FormGroup controlId="formBasicPasswordConfirm">
                                <FormControl
                                    type="password"
                                    value={passwordConfirm}
                                    onChange={(e) =>
                                        this.handleChange("passwordConfirm", e.target.value)
                                    }
                                    placeholder="Confirm password"
                                    required
                                    disabled={loading}
                                />
                            </FormGroup>
                        )}
                    </React.Fragment>
                )}

                {!!message && <div className="message">{message}</div>}

                {!!error && validated && <div className="error">{error}</div>}

                <div className="buttons">
                    {!loading && !message && (
                        <Button
                            type="submit"
                            className={`btn ${
                                crConstantFlag == "true" ? "btn-orange" : ""
                            } text-white`}
                            onClick={this.handleSubmit}
                        >
                            {(!loading && editProfileButtonText) || "Submit"}
                        </Button>
                    )}

                    {!message && loading && (
                        <Button
                            type="submit"
                            className={`btn ${
                                crConstantFlag == "true" ? "btn-orange-loader" : "btn-primary"
                            } text-white`}
                            onClick={this.handleSubmit}
                        >
                            {crConstantFlag == "true" ? (
                                <CVEditProfileLoading />
                            ) : (
                                <EasyEditProfileLoading />
                            )}
                        </Button>
                    )}

                    {onCancel && !message && (
                        <Button className="btn-secondary" onClick={onCancel} disabled={loading}>
                            Cancel
                        </Button>
                    )}

                    {onCancel && message && (
                        <Button
                            className="btn-secondary"
                            onClick={() => {
                                this.reset();
                                onCancel();
                            }}
                            disabled={loading}
                        >
                            OK
                        </Button>
                    )}
                </div>
            </Form>
        );
    }
}

export default EditProfileFrom;
