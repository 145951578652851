import React from "react";
import autoBind from "react-autobind";
import { Button, Form, FormGroup, ControlLabel, FormControl, Modal } from "react-bootstrap";
import ReactGA from "react-ga";

import { hubSpotTracking } from "../../CR-Components/hubSpot.js";
import LoginForm from "./LoginForm.jsx";

import "./Account.scss";

class ModalLoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalFlag: true,
        };
        autoBind(this);
    }
    modalVisibility(bool) {
        this.setState({
            modalFlag: bool,
        });
    }

    render() {
        const { modalFlag } = this.state;
        const { CV } = this.props;
        return (
            <Modal
                show={true}
                onEntered={() => {
                    if (!crConstants.ClimateValuation) {
                        if (crConstants.production) ReactGA.modalview("/login");
                    }
                    window.document.title = "Login / Register";
                    hubSpotTracking("setPath", "/login", "trackPageView");
                }}
                onHide={this.props.onCancel}
                className="LoginForm-modal"
            >
                <Modal.Header closeButton className={`${CV ? "cv-modal-header" : ""}`}>
                    <h3>Please Log in or Register</h3>
                </Modal.Header>

                <Modal.Body>
                    <LoginForm
                        modalVisibility={this.modalVisibility}
                        modalFlag={"modal"}
                        {...this.props}
                    />
                </Modal.Body>
            </Modal>
        );
    }
}

export default ModalLoginForm;
