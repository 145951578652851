import React from "react";
import ReactDOM from "react-dom";
import Select from "react-select";

import EIBase from "./EIBase.jsx";

export default class EISelect extends EIBase {
    constructor(props) {
        super(props);
        this.state.value = this.props.value;
    }

    renderEditingComponent = () => {
        return (
            <Select
                disabled={this.state.loading || this.props.disabled}
                value={this.getSelectedOptions()}
                onChange={this.handleChange}
                multi={!!this.props.isMulti}
                options={this.props.options}
                valueKey={this.props.valueKey}
                labelKey={this.props.labelKey}
                className="react-select"
                ref={(select) => {
                    this.ref = select;
                    if (!this.props.isMulti && select) select.setState({ isOpen: true });
                }}
                {...this.props.editProps}
            />
        );
        // ref line is used to force open when single select (menuIsOpen prop doesn't seem to work).
    };

    getSelectedOptions = () => {
        const newValue = this.state.newValue;
        if (!this.props.isMulti) {
            return this.props.options.find((o) => newValue == o[this.props.valueKey]);
        }

        if (!Array.isArray(newValue)) return [];
        return newValue.map((v) => this.props.options.find((o) => o[this.props.valueKey] == v));
    };

    handleChange = (value) => {
        if (!this.props.isMulti) {
            this.valueChanged(value ? value[this.props.valueKey] : null);
            // Save new selection immediately when single select.
            // Can't call finishEditing immediately because it pulls the new value from this.state,
            // which isn't updated until React has updated the component as a result of the call
            // to setState in valueChanged.
            Meteor.defer(this.finishEditing);
        } else {
            this.valueChanged(value.map((v) => v[this.props.valueKey]));
        }
    };

    renderNormalComponent = () => {
        let values = this.getSelectedOptions();
        if (!Array.isArray(values)) {
            values = values === null || typeof values == "undefined" ? [] : [values];
        }
        values = values
            .map((v) => v[this.props.labelKey])
            .join(", ")
            .trim();
        values = values || this.props.emptyValue || "Set me.";
        return <span {...this.props.defaultProps}>{values}</span>;
    };
}
