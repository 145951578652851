import { createElement, loadAirwallex } from "airwallex-payment-elements";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Loading from "../Loading.jsx";

import "./DropIn.scss";
import { getAirwallexPaymentIntent } from "../CoreFiles/analyses.js";

export const DropIn = ({ id, ...props }) => {
    const [error, setError] = useState(null);
    const [loadingFailed, setLoadingFailed] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [isReady, setIsReady] = useState(false);

    const onReady = () => {
        setIsReady(true);
    }

    const onSuccess = () => {
        if (isPaid) {
            return; // Don't trigger more than once
        }

        setIsPaid(true);
        props.onSuccess();
    }

    const onError = (event) => {
        console.log(event.detail.error);
        setError(event.detail.error);
    }

    const load = (force = false) => {
        if (hasLoaded && !force) {
            return;
        }

        setLoadingFailed(false);
        setHasLoaded(true);

        window.addEventListener("onReady", onReady);
        window.addEventListener("onSuccess", onSuccess);
        window.addEventListener("onError", onError);

        loadAirwallex({
            env: "prod",
            origin: window.location.origin,
        }).then(async () => {
            try {
                const intent = await getAirwallexPaymentIntent();

                props.onIntent(intent);

                const element = createElement("dropIn", {
                    ...intent,
                    components: ["card"],
                });
                element.mount(id);
            } catch (err) {
                console.log(err);
                setLoadingFailed(true);
            }
        });
    }

    useEffect(() => {
        load();
        return () => {
            window.removeEventListener("onReady", onReady);
            window.removeEventListener("onSuccess", onSuccess);
            window.removeEventListener("onError", onError);
        };
    }, []);

    let content;

    if (loadingFailed) {
        const text = error
            ? `Payment error: ${error}`
            : "Payment form loading failed.";
        content = <div className="airwallex__loading">
            <div>{text}</div>
            <div>
                <button onClick={() => load(true)}>Reload</button>
            </div>
        </div>;
    } else if (isPaid) {
        content = <div className="airwallex__loading">
            <div>Payment successful!</div>
            <div>Redirecting...</div>
        </div>;
    } else {
        content = <>
            <div
                id={id}
                style={{ minWidth: "400px", minHeight: "400px" }}
            />
            {!isReady && <div className="airwallex__loading">
                <Loading />
            </div>}
        </>;
    }

    return <div className="airwallex">
        {content}
    </div>;
}

DropIn.propTypes = {
    id: PropTypes.string,
    onIntent: PropTypes.func,
    onSuccess: PropTypes.func,
};