import React, { useEffect } from "react"
import ReactGA from "react-ga"

import { hubSpotTracking } from "../../CR-Components/hubSpot.js"

const PrivacyPolicy = () => {
    useEffect(() => {
        if (crConstants.production) {
            ReactGA.modalview("/privacy_policy")
            window.document.title = "Privacy Policy"
            hubSpotTracking("setPath", "/privacy_policy", "trackPageView")
        }
    }, [])

    return (
        <div className="info-page">
            <div className="container" style={{ paddingBottom: "10%", paddingTop: "4%" }}>
                <img
                    src="/static/images/xdi_logo.png"
                    width="30%"
                    align="right"
                    alt="XDI - The Cross Dependency Initiative"
                />

                <h1 className="western"> 1 Privacy Policy </h1>

                <p>
                    At XDI, we understand the concerns of users visiting https://easy.xdi.systems
                    (“Site”). XDI respects your privacy and treats the information you provide us,
                    including your Personal Information, with care. This Privacy Statement applies
                    to our Site. It explains how we collect, use, and disclose information collected
                    from you and the rights and choices available to you. View our Site’s Terms and
                    Conditions.
                </p>
                <br />
                <h2 className="western">1.1 Personal and Other Information</h2>
                <p>
                    Through your interaction with and use of our Site, XDI Systems may collect
                    “Personal Information”, which is information that identifies or relates to an
                    individual. As examples, Personal Information may include your name, physical
                    address, telephone number, e-mail address, company affiliation, and associated
                    interests.
                </p>
                <p>
                    XDI Systems may also collect “Other Information” through your interaction with
                    and use of the Site, which is not directly related to an individual. As
                    examples, Other Information may include browser and device information, data
                    collected through automated electronic interactions, application usage data,
                    demographic information, geographic or geo-location information, and statistical
                    or aggregated data.
                </p>
                <p>
                    Statistical or aggregated data does not directly identify a specify person, but
                    it may be derived from Personal Information. For example, we may aggregate
                    Personal Information to calculate the percentage of users in a particular zip
                    code.
                </p>
                <p>
                    In some instances, we may combine Other Information with Personal Information.
                    In such cases, we will treat the combined information as Personal Information.
                </p>

                <br />
                <h2 className="western">1.2 Information You Provide to Us</h2>
                <p>
                    If you request or provide Personal Information through our Site using our online
                    contact forms, we store the Personal Information you provide to assist us in
                    responding to your requests.
                </p>
                <br />

                <h2 className="western">1.3 Information We Collect by Automated Tools</h2>
                <p>
                    When you access our Site, we may collect Other Information by automated means,
                    including the use of cookies, web server logs, and third party analytics.
                    Cookies are small pieces of data or data files that are sent by a website to
                    your web browser and stored on your computer. They are used to understand
                    general traffic patterns on our Site. The Other Information stored in the cookie
                    is used for statistical tracking and other purposes, but not for individual
                    identification. Cookies are also used to prefill forms so that you do not need
                    to re-enter the data, such as when you apply for a job online. Accepting a
                    cookie does not give us access to your computer or any Personal Information
                    about you. We may use the aggregated data to provide feedback to our web
                    designers, content providers, or business managers to assist them in improving
                    our Site and services offered. You may refuse to accept browser cookies by
                    activating the appropriate setting on your browser.
                </p>
                <p>
                    If you select this setting, however, you may be unable to access certain parts
                    of our Site. Unless you have adjusted your browser setting so that it will
                    refuse cookies, our system will issue cookies when you direct your browser to
                    our Site. Additionally, our web servers may log Other Information such as your
                    device type, operating system type, browser type, domain, and other system
                    settings, as well as the language your system uses and the country and time zone
                    where your device is located. We may also record Other Information such as the
                    address of the webpage that referred you to our Site and the IP address of the
                    device you use to connect to our Site. Additionally, we may log Other
                    Information about your interaction with our Site, such as pages you visit.
                    Because we do not track our Site’s users over time and across third-party
                    websites, we do not respond to browser “do not track” signals at this time. We
                    may also use third party web analytics to help us analyze how users engage with
                    our Site. The Other Information collected will be disclosed to, or collected by,
                    these third party service providers.
                </p>
                <br />

                <h2 className="western">1.4 How We Use Your Personal Information</h2>
                <p>
                    In accordance with applicable laws, XDI may use your Personal and Other
                    Information for the purposes listed below:
                </p>
                <ul>
                    <li>
                        <p
                            className="western"
                            style={{
                                marginTop: "0.49cm",
                                marginBottom: "0cm",
                                lineHeight: "100%",
                            }}>
                            to send you information you requested or “opted-in” to receive
                        </p>
                    </li>
                    <li>
                        <p className="western" style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                            to identify, prevent, and protect against cyber security and other
                            security events, espionage, fraud, unlawful activity, claims, and other
                            liabilities
                        </p>
                    </li>
                    <li>
                        <p
                            className="western"
                            style={{ marginBottom: "0.49cm", lineHeight: "100%" }}>
                            to comply with and enforce applicable legal requirements and standards
                            consistent with industry practices and our policies.
                        </p>
                    </li>
                </ul>
                <br />

                <h2 className="western">1.5 Information We Share</h2>
                <p>
                    Although XDI does not sell, rent, or loan your Personal or Other Information to
                    any third party, we may disclose such information as listed below:
                </p>
                <ul>
                    <li>
                        <p
                            className="western"
                            style={{
                                marginTop: "0.49cm",
                                marginBottom: "0cm",
                                lineHeight: "100%",
                            }}>
                            if required by law or through a legal process
                        </p>
                    </li>
                    <li>
                        <p className="western" style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                            when formally requested by law enforcement authorities or other
                            government entities
                        </p>
                    </li>
                    <li>
                        <p className="western" style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                            when we believe disclosure is necessary or appropriate to prevent harm
                            or financial loss
                        </p>
                    </li>
                    <li>
                        <p className="western" style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                            in connection with an investigation of suspected or actual espionage,
                            cyber security or security events, or other fraudulent or illegal
                            activity
                        </p>
                    </li>
                    <li>
                        <p
                            className="western"
                            style={{ marginBottom: "0.49cm", lineHeight: "100%" }}>
                            any other legal purpose
                        </p>
                    </li>
                </ul>
                <p>
                    We also reserve the right to transfer Personal Information we have about you in
                    the event we sell or transfer all or a portion of our business and assets.
                </p>
                <br />

                <h2 className="western">
                    <a name="_GoBack"></a>1.6 Your Rights and Choices
                </h2>
                <p>
                    At our Site, you can sign up to receive XDI Newsletter. We offer “opt-in” lists
                    so you can sign up and sign off at any time. To unsubscribe, simply click
                    unsubscribe on the bottom of every email we send you.
                </p>
            </div>
            <div
                className="footer"
                style={{ verticalAlign: "middle", textAlign: "center", marginTop: "5em" }}>
                <p>Brought to you by:</p>
                <img src="/static/images/cr_logo.png" width="20%" alt="Climate Risk Pty Ltd" />
                <img
                    src="/static/images/xdi_logo.png"
                    width="30%"
                    alt="XDI - The Cross Dependency Initiative"
                />
            </div>
        </div>
    )
}

export default PrivacyPolicy
