import React, { useEffect } from "react"
import ReactGA from "react-ga"

import { hubSpotTracking } from "../../CR-Components/hubSpot.js"

const Methods = () => {
    useEffect(() => {
        if (crConstants.production) {
            ReactGA.modalview("/methods")
            window.document.title = "Methods"
            hubSpotTracking("setPath", "/methods", "trackPageView")
        }
    }, [])

    return (
        <div className="info-page">
            <div className="container" style={{ paddingBottom: "10%", paddingTop: "4%" }}>
                <img
                    src="/static/images/xdi_logo.png"
                    width="30%"
                    align="right"
                    alt="XDI - The Cross Dependency Initiative"
                />

                <h1>Scientific Methods - Outline</h1>

                <h2>Introduction</h2>
                <p>
                    The analysis provided uses proprietary technology running on high-speed servers
                    referred to as the Climate Risk Engines.
                </p>

                <h3>The Climate Risk Engines</h3>

                <p>
                    The Climate Risk Engines are purpose built to compute hypothetical future risks
                    to a modelled representative asset (synthesized with engineering data) designed
                    to represent property and infrastructure. The system enables each such asset to
                    be stress-tested against a wide range of extreme weather and extreme sea events
                    typical of its location. A range of future-looking scenarios can be applied that
                    are consistent with different greenhouse gas emission scenarios, atmospheric
                    sensitivity and response, adaptation pathways, building standards and planning
                    regimes.
                </p>
                <p>
                    The Climate Risk Engines combine engineering analysis with statistical analysis
                    of historical weather and climate projections and actuarial methods for
                    financial analysis of risk and value. The results generally include (a) the
                    annual probability of an asset failing to function, (b) the annual average
                    losses due to damage or consequential costs (Risk Cost), and (c) projected
                    changes in relative value due to the increased costs of insurance or
                    self-insurance.
                </p>
                <div className="warning">
                    It is important to note that these results apply to a synthetic{" "}
                    <strong>Representative Asset</strong> under a range of future scenarios – the
                    results cannot therefore be taken as representations of the actual future risks
                    to or value of a real or planned property or infrastructure asset.
                </div>

                <h2>Methods</h2>

                <h3>Representative Assets</h3>
                <p>
                    Initially the system creates a synthetic representation of an asset that is
                    based on nominal industry archetypes, but may include some customisation by the
                    user. This <strong>Representative Asset</strong> could be selected and tailored
                    to mimic a real asset at the same location – such as a house, road or phone
                    tower – or be created as an entirely hypothetical asset being placed in that
                    location.
                </p>
                <p>
                    Each asset is made up of a number of <strong>elements</strong>, for example a
                    house includes foundations, walls, roof, and many other elements. Each of these
                    elements can depend on other elements, for example, the roof of a house depends
                    on the walls, which depend on the foundations - if the foundations fail, then
                    the walls and roof will also necessarily fail. Each element has different
                    vulnerability to different hazards, depending on the materials it is constructed
                    from. These vulnerabilities are drawn from the engineering literature.
                </p>

                <h3>Included Hazards</h3>
                <p>
                    Weather and climate change are not hazards in themselves. Rain is not a hazard,
                    but flooding is. Drought is not a hazard to physical assets, but cracking soils
                    and moving foundations can be. The Climate Risk Engines analyse a range of
                    hazards, currently including:
                </p>
                <ul>
                    <li>Riverine flooding</li>
                    <li>Surface Water flooding</li>
                    <li>Coastal inundation</li>
                    <li>Forest fires</li>
                    <li>Extreme wind</li>
                    <li>Soil movement in drought</li>
                    <li>Extreme Heat</li>
                </ul>
                <p>
                    Some hazards may not be available in all applications of the Climate Risk
                    Engines. The list of hazards available for analysis may also expand over time.
                </p>
                <div className="warning">
                    <h4>What Climate Risk Engines do not do</h4>
                    <p>
                        The Climate Risk Engines do not currently include coastal erosion, or land
                        slip. As such, the risk of sea-level related damage may be considerably
                        higher than presented for properties located on soft coastlines, especially
                        in the latter part of the century. The risk of coastal inundation in EasyXDI
                        does not currently include the impact of waves, which can increase the risk
                        to low-lying properties substantially.
                    </p>
                    <p>
                        Other hazards that may be impacted by global warming, and which are not yet
                        assessed in the Climate Risk Engines system include:
                    </p>
                    <ul>
                        <li>Rainfall-related land-slip</li>
                        <li>Hail</li>
                        <li>Lightning</li>
                        <li>Storm winds</li>
                        <li>Grass fires</li>
                        <li>Salt-water ingress</li>
                    </ul>
                </div>

                <h3>Analysis</h3>
                <p>
                    The extreme weather and climate risks to an asset will depend on its
                    <strong>exposure</strong> and <strong>vulnerability</strong> to{" "}
                    <strong>each hazard</strong>, as well as the current and future severity and
                    frequency of the hazard that may alter with <strong>climate change</strong>. How
                    each of these are handled by the Climate Risk Engines is discussed below.
                </p>

                <h3>Hazard Exposure</h3>
                <p>
                    To understand if the Representative Asset is exposed to a hazard or not,
                    contextual information about each location is gathered by the Climate Risk
                    Engines. This may include information about the soils, tree cover, topology,
                    elevation, flood plains, local tides or waves, or other sources, depending on
                    the hazards to be analysed. Contextual information may also include
                    asset-related information, such current or historical national design standards
                    for buildings or infrastructure.
                </p>
                <p>
                    Data is gathered on these contextual features from national and international
                    sources selected on the basis on scientific methods used, accuracy, spatial
                    resolution, completeness and the standing of the institution that has generated
                    the information. The organisations from which data have been used are set out in
                    the
                    <a href="/info/data_sources">sources section</a> of this site.
                </p>

                <h3>Vulnerability Analysis; damage and failure thresholds</h3>
                <p>
                    Each asset is tested each year for its ability to withstand the hazards to which
                    it is likely to be exposed. This is executed one element at a time, for each
                    hazard. The system tests both failure thresholds and damage thresholds.
                </p>
                <p>
                    A damage threshold is breached when an element of the asset is affected by a
                    hazard such that it is broken or excessively weakened. The system assumes that a
                    damaged element will need to be replaced. Examples might be flood waters
                    damaging an electrical control system, or a wind storm blowing the roof off a
                    house.
                </p>
                <p>
                    A failure threshold is breached when an element of the asset prevents the asset
                    from performing is function. For example, when the roof is blown off in a storm
                    it is both damaged and it fails to protect its occupants from the weather.
                    However, it is possible to have failure without damage, for example an
                    electrical control system that exceeds its operating temperature in a heat wave
                    may stop the asset working, but there will be no damage (when the temperature
                    drops it will start working again).
                </p>

                <h3>Weather Data</h3>
                <p>
                    To establish the precise probability that a hazard will exceed the coping
                    threshold of an asset or element, information about the driving weather
                    indicators may be needed, for example the likelihood of flooding is linked to
                    the likelihood of extreme precipitation.
                </p>
                <p>
                    The Climate Risk Engines have access to observational datasets from over 100,000
                    national weather stations around the world. Internal algorithms are used to
                    select which stations to use when testing an asset based on proximity, data
                    quality, duration and completeness. In some cases, the Climate Risk Engines may
                    use a combination of data from more than one station or gridded data sets made
                    by national meteorological centres. More recently satellite data has become an
                    important source of weather data and this too may be accessed for computations.
                </p>

                <h3>Climate Change Modelling</h3>
                <p>
                    Changes in the composition of the atmosphere due to greenhouse gas emissions
                    will change how the atmosphere and oceans behave. Therefore, the historical
                    weather station statistics need to be adjusted to allow for climate change.
                </p>
                <p>
                    The Climate Risk Engines have access to a large number of climate projection
                    data sets from various sources, including the Coupled Model Inter-comparison
                    Project (CMIP), WCRP Coordinated Regional Climate Downscaling Experiment
                    (CORDEX), and various regionally specific projects, such as the NSW and ACT
                    Regional Climate Modelling (NARCliM) Project. These projects usually model the
                    atmosphere under various Representative Concentration Pathways (RCP), which
                    represent different possible future CO2 emission scenarios. At a whole of
                    atmosphere scale the General Circulation Models (GCMs) have a spatial resolution
                    down to about 100km. With downscaling, Regional Climate Models (RCMs) include
                    finer representations of local topology and land surface information to provide
                    weather parameters at higher spatial resolutions - between 5km and 50km.
                </p>
                <p>
                    In some locations users can select the GCM/RCM they wish to apply to the
                    analysis of the Representative Asset, otherwise the Climate Risk Engines will
                    select by default the most appropriate climate modelling to use based on a
                    number of factors, including which models are available in the region; the
                    ‘skill’ of the model in capturing typical weather behaviour in a certain region;
                    the range of parameters included or reported; the spatial resolution; and where
                    the results of the model fit within the ensemble of other models for the region.
                </p>

                <h3>Actuarial Risk Calculations</h3>
                <p>
                    The risk of an asset failing is computed from the probability of a hazard
                    exceeding the coping threshold of one or more elements of an asset or the whole
                    asset. The cost of damage is the product of the probability of threshold being
                    exceeded and the associated replacement costs of an element – this can be
                    extended to include consequential costs of failure in more advanced systems
                    using the Climate Risk Engines.
                </p>
                <p>
                    A relative deterioration in asset value can be calculated based on a fully
                    financed asset where a hypothetical buyer is intending to make interest only
                    payments each year. Any abnormally high insurance/self-insurance costs reduce
                    the ability of the buyer to service the loan, and therefore require the size of
                    the principal (value of the asset) to be adjusted down to the point that the
                    outgoings are the same.
                </p>

                <h2>For more information</h2>
                <p>
                    To find out more about the data used in this analysis a list of sources is
                    provided on each web site that uses the Climate Risk Engines. See the{" "}
                    <a href="/info/data_sources">sources section</a> of this site.
                </p>
            </div>
            <div
                className="footer"
                style={{ verticalAlign: "middle", textAlign: "center", marginTop: "5em" }}>
                <p>Brought to you by:</p>
                <img src="/static/images/cr_logo.png" width="20%" alt="Climate Risk Pty Ltd" />
                <img
                    src="/static/images/xdi_logo.png"
                    width="30%"
                    alt="XDI - The Cross Dependency Initiative"
                />
            </div>
        </div>
    )
}

export default Methods
