import React from "react";
import autoBind from "react-autobind";
import Select from "react-select";
import { BingMapsGeocoderService, Rectangle, Cartographic } from "cesium";
import PropTypes from 'prop-types'
import { fetchAddressesByCoordinates, parseCoordinates } from "../functions/geocoding.js";

import { cartographicToDegrees } from "./utils.js";

import "./Cesium.scss";

const debug = false;

class CesiumGeocoder extends React.Component {

    static propTypes = {
        initialQuery: PropTypes.any,
        cesiumContext: PropTypes.any,
        onSelect: PropTypes.func,
        disabled: PropTypes.bool,
        placeholder: PropTypes.string,
    }
    constructor(props) {
        super(props);

        this.state = {
            query: this.props.initialQuery,
            results: [],
            showOptions: false,
            selected: null,
            timerCount: 0,
            timeOut: 0,
        };

        autoBind(this);
    }

    ensureGeocoder() {
        if (debug) console.log("ensureGeocoder")
        if (!this.geocoder) {
            this.geocoder = new BingMapsGeocoderService({
                key: crConstants.BingMapsKey,
            });
            // `https://dev.virtualearth.net/REST/v1/Locations/${latitude},${longitude}?o=json&key=${crConstants.BingMapsKey}`
        }
    }

    async UNSAFE_componentWillMount() {
        if (this.props.cesiumContext) {
            if (debug) console.log("cesiumContext")

            this.ensureGeocoder();

            try {
                // Load results but don't show them for initial query.
                this.setState({
                    results: await this.geocoder.geocode(this.state.query),
                });
            } catch (ex) {}
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (debug) console.log("UNSAFE_componentWillReceiveProps", props.initialQuery)

        if (this.props.initialQuery != props.initialQuery) {
            this.setState({
                query: props.initialQuery,
                showOptions: false,
            });
        }
    }

    /**
     *
     * @param {string} query
     */
    async updateSearch(query) {
        if (debug) console.log("updateSearch", query)

        this.setState({
            query,
            showOptions: false,
        });
        if (this.state.timerCount === 0) {
            await this.setState({ timerCount: 1 });
        } else {
            window.clearTimeout(this.state.timeOut);
        }

        if (query.trim().length > 0) {
            //added 1-sec delay on every key press
            if (this.state.timerCount === 1) {
                var count = window.setTimeout(this.doSearch, 500);
                this.setState({ timeOut: count });
            }
        }
    }

    async doSearch() {
        if (debug) console.log("doSearch")
        try {
            this.ensureGeocoder();

            const coords = parseCoordinates(this.state.query);
            if (coords) {
                this.setState({
                    results: await fetchAddressesByCoordinates(coords),
                    showOptions: true,
                    timerCount: 0,
                });
            } else {
                this.setState({
                    results: await this.geocoder.geocode(this.state.query),
                    showOptions: true,
                    timerCount: 0,
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    /**
     *
     * @param {object} selected
     */
    handleSelection(selected) {
        if (debug) console.log("handleSelection", selected)
        this.setState({
            query: selected.label,
            selected,
            showOptions: false,
        });

        if (selected.value.hasOwnProperty("west")) {
            this.props.onSelect({
                address: selected.label,
                cartographic: cartographicToDegrees(Rectangle.center(selected.value)),
            });
        } else {
            this.props.onSelect({
                address: selected.label,
                cartographic: cartographicToDegrees(
                    Cartographic.fromCartesian(selected.value)
                ),
            });
        }
    }

    render() {
        const { query, results, showOptions, selected } = this.state;

        const options = results
            .filter((r) => {
                const d = r.destination;
                if (d.hasOwnProperty("west")) {
                    return d.west && d.south && d.east && d.north;
                } else {
                    return d.x && d.y && d.z;
                }
            })
            .map((r) => {
                return {
                    label: r.displayName,
                    value: r.destination,
                };
            });

        return (
            <div className="CesiumGeocoder">
                <input
                    className="cesiumInput"
                    value={query || ""}
                    onChange={(e) => this.updateSearch(e.target.value)}
                    onFocus={() => this.setState({ showOptions: true })}
                    onBlur={() => this.setState({ showOptions: false })}
                    placeholder={this.props.placeholder || "Enter an address and select from dropdown"}
                    disabled={this.props.disabled}
                />

                {showOptions && query.trim().length > 3 && (
                    <Select
                        noOptionsMessage={() => "No results"}
                        classNamePrefix="react-select"
                        isMulti={false}
                        name="geocoder-address-options"
                        options={options}
                        value={selected}
                        onChange={this.handleSelection}
                        menuIsOpen={true}
                    />
                )}
            </div>
        );
    }
}

export default CesiumGeocoder;
