import React from "react"
import PropTypes from 'prop-types'

import "./ArchetypeDetail.scss"

const ArchetypeDetail = (props) => {
    const { properties, inputs, metadata } = props.analysis
    const { elementMaterials } = props.analysis.inputs.asset.properties

    return (
        <div className="ArchetypeDetail">
            <div className="design-specs">
                <h4>Design Specifications: {properties.archetype}</h4>
                <p className="archetypeBlurb">
                    EasyXDI has analysed the Representative Asset you have selected, based on
                    the Design Specifications and Elements listed. It is possible these
                    parameters may not reflect the actual features of an actual asset.
                </p>
                <dl>
                    <dt>Wind speed design threshold</dt>
                    <dd>1 in {Math.round(1 / inputs.asset.properties.windThreshold)} year</dd>
                    <dt>Heat threshold</dt>
                    <dd>{metadata.heat.threshold_temperature ? metadata.heat.threshold_temperature : inputs.asset.properties.heatThreshold}°C</dd>
                    <dt>Ground height</dt>
                    <dd>{inputs.asset.properties.heightAboveGround} metres</dd>
                </dl>
            </div>

            <div className="elements">
                <h4>Elements</h4>
                <table className="table2">
                    <thead>
                        <tr>
                            <th>Element</th>
                            <th className="center-col">Primary material</th>
                            <th>Material name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(elementMaterials).map((el) => (
                            <tr key={el}>
                                <td>
                                    {el.substring(0, 1) +
                                        el
                                            .substring(1)
                                            .replace(/([A-Z])/g, " $1")
                                            .toLowerCase()}
                                </td>
                                <td className="center-col">{elementMaterials[el].id}</td>
                                <td>{elementMaterials[el].name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
ArchetypeDetail.propTypes = {
    analysis: PropTypes.object.isRequired,
}

export default ArchetypeDetail
