import JSURL from "jsurl";
import _ from "lodash";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const mergeNewParams = (location, params, returnJSURL) => {
    if (typeof params != "object") throw "Hash params must be an object";
    const parsed = JSURL.parse(location.hash.substr(1));
    let newParams = {
        ...parsed,
        ...params,
    };
    // Filter out null/undefined/empty array values
    newParams = _.pickBy(newParams, (value, key) => value !== null && value !== undefined);

    if (returnJSURL) return JSURL.stringify(newParams);

    if (_.isEqual(parsed, newParams)) return false;
    return newParams;
};

const pushLocationHashParams = (params, _history, _location) => {
    _history = _history || history;
    _location = _location || _history.location;
    const newParams = mergeNewParams(_location, params);
    if (newParams) {
        _history.push({ hash: JSURL.stringify(newParams) });
    }
};

const replaceLocationHashParams = (params, _history, _location) => {
    _history = _history || history;
    _location = _location || _history.location;
    const newParams = mergeNewParams(_location, params);
    if (newParams) {
        _history.replace({ hash: JSURL.stringify(newParams) });
    }
};

const setLocationHashParams = (params, _history) => {
    _history = _history || history;
    // Filter out null/undefined values
    params = _.pickBy(params, (value, key) => value !== null && value !== undefined);
    _history.push({ hash: JSURL.stringify(params) });
};

const parseHashParams = (defaults, _location) => {
    _location = _location || history.location;
    // Do not parse hashs on CV science page as HashLinks are used for in page navigation
    // TODO: There is likely a better fix to this.
    if (_location.pathname === "/science") return { ...(defaults || {}) };
    // Parse location query params, including defaults if given.
    let parsed = {
        ...(defaults || {}),
        ...JSURL.parse(_location.hash.substr(1)),
    };
    // For values that look numeric return a number.
    //parsed = _.mapValues(parsed, value => ""+parseFloat(value) == value ? parseFloat(value) : value);
    return parsed;
};

const openInNewWindow = (address, focus) => {
    const loc = window.location;
    const url = `${loc.protocol}//${loc.hostname}${loc.port ? ":" + loc.port : ""}/${address}`;
    const win = window.open(url, "_blank");
    focus && win.focus();
};

const goHome = () => {
    history.push("/");
};

const goToAnalysisId = (analysisId) => {
    setTimeout(() => pushLocationHashParams({ analysisId }), 0);
};

export {
    history,
    parseHashParams,
    setLocationHashParams,
    pushLocationHashParams,
    replaceLocationHashParams,
    openInNewWindow,
    goHome,
    mergeNewParams,
    goToAnalysisId,
};
