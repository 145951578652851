import React from "react"
import autoBind from "react-autobind"
import { Modal, Button, Accordion, Card, useAccordionToggle } from "react-bootstrap"
import ReactGA from "react-ga"

import { CardHeader } from "../../CR-Components/UI-Utils/ui-utils.jsx"

import "./ErrorModal.scss"

class ErrorModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openCard: "1",
        }
        autoBind(this)
    }

    handleClose() {
        this.props.onClose() // Handle error resetting in parent
        this.setState({ show: false })
    }
    /**
     *
     * @param {string} eventKey
     */
    toggleCard(eventKey) {
        this.setState({
            openCard: this.state.openCard === eventKey ? null : eventKey,
        })
    }

    render() {
        const { openCard } = this.state
        const { message, details } = this.props.error

        return (
            <Modal
                show={true}
                onEntered={() => {
                    if (!crConstants.ClimateValuation) {
                        if (crConstants.production) ReactGA.modalview("/error")
                    }
                }}
                onHide={this.handleClose}
                className="error-modal">
                <Modal.Header>
                    <Modal.Title>Unfortunately an error occurred during processing:</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <pre className="error-message">{message}</pre>

                    <div className="error-details">
                        <Accordion>
                            <Card>
                                <CardHeader
                                    eventKey="0"
                                    openCard={openCard}
                                    onClick={this.toggleCard}>
                                    Details
                                </CardHeader>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <pre>{details}</pre>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>

                    <p className="error-disclaimer">
                        We are notified of errors as they occur, and will try to fix this as soon as
                        possible. Please try again in a few minutes.
                    </p>
                    <p className="error-disclaimer">
                        If the problem persists, please{" "}
                        {this.props.app === "CV" ? (
                            <a href={`${crConstants.domain}/contact`}>contact us</a>
                        ) : (
                            <a href="https://xdi.systems/contact/">contact us</a>
                        )}
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ErrorModal
