import React from 'react'
import autoBind from "react-autobind"
import { Accordion, Card, Table } from "react-bootstrap"
import { CardHeader } from "./ui-utils.jsx";
import PropTypes from "prop-types"

class ElementsAccordion extends React.Component {
    static propTypes = {
        elementMaterials: PropTypes.object,
    }

    constructor(props) {
        super(props);
        this.state = {
            openCard: null,
        };
        autoBind(this);
    }
    /**
     *
     * @param {string} eventKey
     */
    toggleCard(eventKey) {
        this.setState({
            // we only have one card that we want to open and close
            openCard: this.state.openCard === eventKey ? null : eventKey,
        });
    }

    render() {
        const { elementMaterials } = this.props;
        const { openCard } = this.state;

        return (
            <div className="elements-details">
                <Accordion>
                    <Card>
                        <CardHeader eventKey="0" openCard={openCard} onClick={this.toggleCard}>
                            Specific Design Elements
                        </CardHeader>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Table striped={true} hover={true} size="sm" className="table2">
                                    <thead>
                                        <tr>
                                            <th>Element</th>
                                            <th className="center-head-tab">Primary material</th>
                                            <th>Material name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(elementMaterials).map((el) => (
                                            <tr key={el}>
                                                <td>
                                                    {el.substring(0, 1) +
                                                        el
                                                            .substring(1)
                                                            .replace(/([A-Z])/g, " $1")
                                                            .toLowerCase()}
                                                </td>
                                                <td className="center-col-tab">
                                                    {elementMaterials[el].id}
                                                </td>
                                                <td>
                                                    {elementMaterials[el].name}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        );
    }
}

export default ElementsAccordion