import _ from "lodash"
import { toJS } from "mobx"

import appState from "./state"
import adaptState from "../Adaptation/adaptState"
import { getAnalysisFromAna, getElevation } from "../analyses"
import { getArchetypeData, getMaterialsData } from "../archetype"
import { checkProp, checkNested } from "../utils.js"

// TODO: Add freeze thaw once archetypes have been updated to include it.
// TODO: retrieve these from scenario
const hazards = ["flood_riverine", "inundation", "heat", "forest_fire", "wind", "soil_movement",
    "freeze-thaw", "flood_surfacewater", "cyclone_wind"]

// TODO: maybe merge with
// CR-components:Scenario.js:checkProp into a utils function?
/**
 * Returns the value of a prop or the default??? - needs clarification
 *
 * @param {object} obj - object to check
 * @param {string} prop - property to check in object
 * @param {any} defaultVal - default value
 *
 * @returns {any} chosen value or default
 */
const getValue = (obj, prop, defaultVal) =>
    !obj.hasOwnProperty(prop) || obj[prop] === null || Number.isNaN(obj[prop])
        ? defaultVal
        : obj[prop]

/**
 * This changes the proportion of any element to 0 if its not
 * included in elementsEnabled
 *
 * @param {object} archetype - archetype object (raw)
 * @param {Array} elementsEnabled - array of enabled element names
 *
 * @returns {object} - archetype.elements with proportions adjusted
 */
const setElementProportions = (archetype, elementsEnabled) => {
    return _.reduce(
        archetype.elements,
        (out, value, key) => {
            return {
                ...out,
                [key]: {
                    ...value,
                    proportion: elementsEnabled.has(key) ? Number(value.proportion) : 0,
                },
            }
        },
        {}
    )
}

/**
 * Formats assetParameters and sets the fieldDefaultValues object. This object is meant
 * to be used as an immutable set of
 * values returned from a basic search
 *
 * @param {object} parameters - Original user submitted parameters
 * @param {object} params - formatted params
 * @param {object} archetype - formatted archetype object
 * @param {object} elementMaterials - elements and selected (or default) materials
 * @param {object} omFieldDefaults - default fields ????
 *
 * @returns {object} - formatted assetParameters object
 *
 */
const getAssetParameters = (parameters, params, archetype, elementMaterials, omFieldDefaults) => {
    const assetParameters = {
        // Collect everything from parameters, includes meta fields that are
        // used in AssetForm.jsx.
        ...parameters,
        // ???
        ..._.mapValues(omFieldDefaults, (value, field) => getValue(params, field, value)),
        totalHeight: params.totalHeight,
        materialsDict: archetype.materials,
        elementMaterials: elementMaterials,
        // TODO: This is in place to avoid a KPI calculation warning,
        // but it should be added to asset customisation.
        numPeople: archetype.model == "Dwelling" ? 1 : 0,
        elevationDataSet: params.elevationDataSet,
        // add CV specific defaults
        mortgageTerm: params.mortgageTerm || 30,
        marketValue: params.marketValue || 800000,
        interestRate: params.interestRate || 5.2,
        replacementCost: params.replacementCost || 315000,
    }

    // Sets defaults
    if (Object.keys(toJS(appState.fieldDefaultValues)).length < 2) {
        appState.fieldDefaultValues = assetParameters
    }

    return assetParameters
}

/** Run an asset analysis using Google elevation API and Ana
 *
 * @param {object} parameters - analysis options, including for example:
 *     parameters = {
 *      "whenType":"select",
 *      "locationType":"select",
 *      "latitude":31.997210934393824,
 *      "longitude":-107.58625400863691,
 *      "address":"88030, NM",
 *      "archetypeService":"Building",
 *      "archetype":"HRS",
 *      "assetLifeEnd":2100}
 *     elements: elements to modify (can be empty)
 */
const CR_doAnalysis = async ({ parameters, elements }) => {
    // Copy parameters. TODO: Is this necessary? Side-effects?
    const params = { ...parameters }

    // get archetype data from acrobase, takes acronym

    let archetype = await getArchetypeData(params.archetype)

    // gets initial archetype defaults
    const omFieldDefaults = _.mapValues(archetype.fields, (def) => def.default)

    // Get elevation if none is specified
    if (!("elevation" in params) || params.elevation === null || Number.isNaN(params.elevation)) {
        const el_res = await getElevation(params.latitude, params.longitude)
        params.elevation = el_res.elevation
        params.elevationDataSet = el_res.dataset
    }

    // calculate total height
    params.totalHeight =
        params.elevation +
        getValue(params, "heightAboveGround", omFieldDefaults["heightAboveGround"])

    // sets default buildYear, and age
    // TODO: is age used?
    const currentYear = new Date().getFullYear()
    params.buildYear = params.buildYear || currentYear - 30
    params.age = currentYear - params.buildYear

    // if elements are provided create working objects
    const modifyElements = !!elements
    const elementsEnabled = modifyElements && new Set(Object.keys(elements))
    // this must be null if not used.
    let elementMaterials = null

    // if elements are passed (e.g. from asset customisation), modify the
    // archetype elements to suit.
    // TODO: Move this into archetype getter?
    if (modifyElements) {
        // This changes the proportion of any element not in elementsEnabled
        archetype.elements = setElementProportions(archetype, elementsEnabled)
        // retrieves cached materials data
        const materialsDataDefaults = toJS(await getMaterialsData())

        elementMaterials = _.reduce(
            elements,
            (out, val, key) => {
                console.log(val, key)
                return {
                    ...out,
                    [key]: materialsDataDefaults[val],
                }
            },
            {}
        )

        // changes the structure of materials
        archetype.materials = _.mapValues(archetype.materials, (vals, element) => {
            // This is an object like {material_name: [list of hazard failure coeffs], ...}
            if (elements.hasOwnProperty(element)) {
                let included = elements[element] !== "Not Included"

                let haz = hazards.reduce((out, h) => {
                    out[h] = included ? elementMaterials[element].failure_coefficients[h] : 0
                    return out
                }, {})
                // TODO: fix this hack answer that turns off soil movement,
                // alters Fire results. This will happen in ana
                if (params.foundationDesign != "normal") {
                    haz["soil_movement"] = 0
                }
                if (params.fireProtection == "heatEmber") {
                    haz["forest_fire"] /= 2
                }
                if (params.fireProtection == "flame") {
                    haz["forest_fire"] /= 4
                }

                if (
                    included && checkProp(elementMaterials[element].failure_coefficients, "coastal_inundation")
                ) {
                    // TODO: Get rid of this horrible hack. Rename to be consistent everywhere.
                    haz.inundation = included
                        ? elementMaterials[element].failure_coefficients.coastal_inundation
                        : 0
                } else {
                    haz.inundation = included
                        ? elementMaterials[element].failure_coefficients.inundation
                        : 0
                }

                if (included) {
                    // sets same coefficients in elementMaterials
                    elementMaterials[element].failure_coefficients = { ...haz }
                }

                return haz
                // TODO: fix this ^^^^ this should happen in ana and all this
                // code should be removed. uncomment line below
                // return hazards.map(h => material[h]);
            }
            return vals
        })
    } else {
        // if default elements make elementMaterials with default values
        const materialsDataDefaults = toJS(await getMaterialsData())

        elementMaterials = _.reduce(
            archetype.elements,
            (out, val) => {
                return {
                    ...out,
                    [val.name]: materialsDataDefaults[val["default material"]],
                }
            },
            {}
        )
    }

    for (var key in elementMaterials) {
        if (elementMaterials[key] === "Not Included") {
            delete elementMaterials[key]
        }
    }

    // TODO This will be provided by acrobase one day.
    archetype.heatStressArchConst = 0.3

    // Get rid of archetype data that Ana does not use
    // TODO: Need to think about the structure of archetypes in Acrobase/Easy.
    // This stuff should be able to be passed to Ana directly.
    const archetype_to_ana = { ...archetype }
    delete archetype_to_ana.fields
    delete archetype_to_ana.materials

    const inputs = {
        archetype: archetype_to_ana,
        asset: {
            _id: "EasyXDI_CV", // TODO need to be unique?
            archetype: params.archetype, // Archetype acronym.
            geometry: {
                type: "Point",
                coordinates: [params.longitude, params.latitude],
            },
            properties: getAssetParameters(
                parameters,
                params,
                archetype,
                elementMaterials,
                omFieldDefaults
            ),
        },

        scenario: {
            startYear: 1990,
            endYear: 2100,
            hazards,
            organisation: "jba", // Flood organisation
            profileSoilMovement: "AUTO",
            profileForestFire: "AUTO",
            // We don't have historical HDW data. Not sure how it would work anyway.
            historicalForestFire: "none",
            profileHeatStress: "AUTO",
            profileFlood: "AUTO",
            profileWind: "AUTO", // We don't have or need historical wind data.
            historicalWind: "none",
            profileInundation: "Haigh-et-al-2014",
            profileHeat: "AUTO",
            profileFreezeThaw: "AUTO",
            heatStressLinearConst: 0.001,
            heatStressHourProb: 0.1,
            heatStressHourConst: 5.5,
            waves: "off",
            waveSetup: 15.0,
            assetDegradation: 0.0,
            yearOffset: 0,
        },
        settings: {
            method: "timeseries",
            sections: { "inputs": "all" },
        },
    }

    const analysis = await getAnalysisFromAna(inputs)

    return analysis
}

/**
 * Does a new analysis request to populate the fieldDefaults appState
 * This is used when loading a saved analysis
 *
 * @param {string} analysisId
 * @param {Array} analyses - Array of saved analyses
 */
const updateFieldDefaults = async (analysisId, analyses) => {
    // clears defaults from previous analysis
    const { inputs } = analyses[analysisId]
    appState.fieldDefaultValues = {}
    const parameters = {
        archetype: inputs.asset.archetype,
        latitude: inputs.asset.properties.latitude,
        longitude: inputs.asset.properties.longitude,
        assetLifeEnd: inputs.asset.properties.assetLifeEnd,
    }
    await CR_doAnalysis(
        {
            parameters,
        },
        true
    )
}

/**
 * EASY Analysis saving/loading/deleting functionality
 */

/**
 * Deletes adaptation from the database
 *
 * @param {string} pathwayNumber - pathway number string
 * @param {string} optionNumber - pathway number string -optional
 * @param {string} currentID - Id of the analysis
 */
const handleDeleteAdaptation = async (pathwayNumber, optionNumber, currentID) => {
    if (!currentID) {
        console.log("Analysis not saved to database. Deleting Locally...")
        return
    }

    try {
        const response = await jQuery.ajax({
            method: "POST",
            headers: {
                Authorization: appState.account.apiKey,
            },
            url: `${crConstants.authHost}/v1/analysis/delete-adaptation`,
            data: { pathwayNumber, optionNumber, currentID },
            dataType: "json",
        })
    } catch (e) {
        console.log("Delete from database failed!")
    }
}

// TODO: why tho
// TODO: re-write this
const handleSaveAdaptation = async (adaptState, pathwayNumber, optionNumber) => {
    var currentAnalysisId = adaptState.currentAnalysisId
    var analysis = adaptState.adaptation[currentAnalysisId][pathwayNumber][optionNumber]
    let data, level1, level2
    if (adaptState.adaptationValues[currentAnalysisId] !== undefined) {
        if (adaptState.adaptationValues[currentAnalysisId][pathwayNumber] !== undefined) {
            if (
                adaptState.adaptationValues[currentAnalysisId][pathwayNumber][optionNumber] !==
                undefined
            ) {
                if (
                    adaptState.adaptationValues[currentAnalysisId][pathwayNumber][optionNumber][
                        "adaptationYear"
                    ] === undefined
                ) {
                    adaptState.adaptationValues[currentAnalysisId][pathwayNumber][optionNumber][
                        "adaptationYear"
                    ] = 2030
                } else if (
                    adaptState.adaptationValues[currentAnalysisId][pathwayNumber][optionNumber][
                        "capex"
                    ] === undefined
                ) {
                    adaptState.adaptationValues[currentAnalysisId][pathwayNumber][optionNumber][
                        "capex"
                    ] = 0
                }
            } else {
                data = { adaptationYear: 2030, capex: 0 }
                adaptState.adaptationValues[currentAnalysisId][pathwayNumber] = {
                    ...adaptState.adaptationValues[currentAnalysisId][pathwayNumber],
                    [optionNumber]: data,
                }
            }
        } else {
            data = { adaptationYear: 2030, capex: 0 }
            level1 = { [optionNumber]: data }
            adaptState.adaptationValues[currentAnalysisId] = {
                ...adaptState.adaptationValues[currentAnalysisId],
                [pathwayNumber]: level1,
            }
        }
    } else {
        data = { adaptationYear: 2030, capex: 0 }
        level1 = { [optionNumber]: data }
        level2 = { [pathwayNumber]: level1 }
        adaptState.adaptationValues = {
            ...adaptState.adaptationValues,
            [currentAnalysisId]: level2,
        }
    }

    var adaptationValues =
        adaptState.adaptationValues[currentAnalysisId][pathwayNumber][optionNumber]

    // adding further values
    if (adaptState.assetValues[currentAnalysisId] !== undefined) {
        if (adaptState.assetValues[currentAnalysisId]["assetCost"] === undefined) {
            adaptState.assetValues[currentAnalysisId] = {
                ...adaptState.assetValues[currentAnalysisId],
                ["assetCost"]: 1000000,
            }
        } else if (adaptState.assetValues[currentAnalysisId]["npv"] === undefined) {
            adaptState.assetValues[currentAnalysisId] = {
                ...adaptState.assetValues[currentAnalysisId],
                ["npv"]: 30,
            }
        } else if (adaptState.assetValues[currentAnalysisId]["discountRate"] === undefined) {
            adaptState.assetValues[currentAnalysisId] = {
                ...adaptState.assetValues[currentAnalysisId],
                ["discountRate"]: 0.05,
            }
        }
    } else {
        data = { assetCost: 1000000, npv: 30, discountRate: 0.05 }
        adaptState.assetValues = { ...adaptState.assetValues, [currentAnalysisId]: data }
    }
    var assetValues = adaptState.assetValues[currentAnalysisId]

    var adaptationData = {
        pathwayNumber: pathwayNumber,
        optionNumber: optionNumber,
        adaptationYear: adaptationValues["adaptationYear"],
        capex: adaptationValues["capex"],
        assetCost: assetValues["assetCost"],
        npv: assetValues["npv"],
        discountRate: assetValues["discountRate"],
    }
    if (pathwayNumber == "pathway1") {
        analysis = { ...analysis, ["UpdatedLine"]: adaptState.path["1"]["newData"] }
    }
    if (pathwayNumber == "pathway2") {
        analysis = { ...analysis, ["UpdatedLine"]: adaptState.path["2"]["newData"] }
    }
    if (pathwayNumber == "pathway3") {
        analysis = { ...analysis, ["UpdatedLine"]: adaptState.path["3"]["newData"] }
    }

    analysis = { ...analysis, ["parentAnalysisId"]: currentAnalysisId }
    analysis = { ...analysis, ["adaptationOptions"]: adaptationData }
    delete analysis["groupId"]

    try {
        const analysisUpdate = await saveAnalysis(analysis, true)
    } catch (e) {
        console.log("error", e)
    }
}

/**
 * converts any analysis returned from ana to the current format
 *
 * @param {Array} analysesRaw - array of analysis objects
 * @param {object} materialsDataDefaults - materials data from acrobase
 *
 * @returns {Array} - array of formatted analyses
 */
const munge_easy_saved_analyses = (analysesRaw, materialsDataDefaults) => {
    const analyses = analysesRaw.map((analysisRaw) => {
        // Check if v1.1
        if (checkNested(analysisRaw, "inputs", "asset", "properties")) return analysisRaw

        const elementMaterials = _.reduce(
            analysisRaw.asset.elements,
            (out, value, key) => {
                return {
                    ...out,
                    [key]: {
                        id: value,
                        name: materialsDataDefaults[value].name,
                        failure_coefficients: {
                            "flood_riverine":
                                analysisRaw.parameters.materialsDict[key][0] ||
                                analysisRaw.parameters.materialsDict[key].flood_riverine,
                            "inundation":
                                analysisRaw.parameters.materialsDict[key][1] ||
                                analysisRaw.parameters.materialsDict[key].inundation,
                            "heat":
                                analysisRaw.parameters.materialsDict[key][2] ||
                                analysisRaw.parameters.materialsDict[key].heat,
                            "forest_fire":
                                analysisRaw.parameters.materialsDict[key][3] ||
                                analysisRaw.parameters.materialsDict[key].forest_fire,
                            "wind":
                                analysisRaw.parameters.materialsDict[key][4] ||
                                analysisRaw.parameters.materialsDict[key].wind,
                            "soil_movement":
                                analysisRaw.parameters.materialsDict[key][5] ||
                                analysisRaw.parameters.materialsDict[key].soil_movement,
                        },
                    },
                }
            },
            {}
        )

        let analysis = {
            ...analysisRaw,
            inputs: {
                asset: {
                    _id: "EasyXDI_CV",

                    properties: {
                        ...analysisRaw.parameters,
                        archetype: analysisRaw.asset.archetype.acronym,
                        elementMaterials: elementMaterials,
                        name: analysisRaw.parameters.address,
                        marketValue: analysisRaw.parameters.valueOfProperty,
                    },
                    geometry: analysisRaw.geometry,
                },
                archetype: {
                    ...analysisRaw.asset.archetype,
                    name: analysisRaw.asset.archetype.archetype,
                },
                scenario: {
                    ...analysisRaw.scenario,
                    hazards: [
                        "flood_riverine",
                        "inundation",
                        "heat",
                        "forest_fire",
                        "wind",
                        "soil_movement",
                    ],
                },
            },
            properties: {
                ...analysisRaw.results,
            },
        }
        delete analysis.parameters
        delete analysis.asset
        delete analysis.scenario
        delete analysis.results
        return analysis
    })

    return analyses
}

/* Loads all existing analyses for the user from the Auth database
 *
 * TODO: Replace with save to/load from Repo
 */
const loadAllAnalyses = async () => {
    if (!appState.account) return

    try {
        const response = await jQuery.ajax({
            method: "GET",
            url: `${crConstants.authHost}/v1/analysis`,
            headers: {
                Authorization: appState.account.apiKey,
            },
            dataType: "json",
        })

        // const analyses = response
        const materialsDataDefaults = toJS(await getMaterialsData())
        const analyses = munge_easy_saved_analyses(response, materialsDataDefaults)

        if (analyses && !analyses.error) {
            const sortedAnalyses = _.sortBy(analyses, "createdAt.$date")
            appState.analyses = sortedAnalyses.reduce(
                (o, analysis) => ({ ...o, [analysis._id]: analysis }),
                {}
            )
            adaptState.analyses = sortedAnalyses.reduce(
                (o, analysis) => ({ ...o, [analysis._id]: analysis }),
                {}
            )
        } else {
            // TODO: Where does this come from? Global variable? Broken?
            console.log("Error loading analyses:", account)
        }
    } catch (e) {
        console.log("Error loading analyses:", e)
    }
}

/* Save Analysis to Auth DB
 *
 * TODO: Replace with save to Repo, rename
 */
/**
 *
 * @param {object} analysis
 */
const saveAnalysis = async (analysis, forAdaptation = false) => {
    try {
        // console.log("Inside Save Analysis", analysis)
        const response = await jQuery.ajax({
            method: "POST",
            headers: {
                Authorization: appState.account.apiKey,
            },
            url: `${crConstants.authHost}/v1/analysis`,
            data: { analysis: JSON.stringify(analysis), isAdaptation: forAdaptation },
            dataType: "json",
        })

        if (response._id) {
            appState.analyses = { ...appState.analyses, [response._id]: response }
            const sortedAnalyses = _.sortBy(Object.values(appState.analyses), "createdAt.$date")
            appState.analyses = sortedAnalyses.reduce(
                (o, analysis, index) => ({ ...o, [analysis._id]: analysis }),
                {}
            )

            if (forAdaptation == false) {
                //Updating current analysis ID
                adaptState.currentAnalysisId = response._id
            }

            return response
        } else {
            throw response.message || "An unknown error occurred."
        }
    } catch (e) {
        const err =
            (e.responseJSON && e.responseJSON.error && e.responseJSON.error.message) ||
            e.message ||
            e.statusText ||
            "" + e
        console.log("e", e)
        throw err
    }
}

// TODO: Not sure if this should stay in Auth or move the Repo?
/**
 * Save search parameters to the Auth DB
 * @param {object} params
 */
const saveSearch = async (params) => {
    try {
        const response = await jQuery.ajax({
            method: "POST",
            url: `${crConstants.authHost}/v1/search`,
            data: { params: JSON.stringify(params) },
            dataType: "json",
        })

        if (!response.error) {
            return response
        } else {
            throw response.message || "An unknown error occurred."
        }
    } catch (e) {
        const err =
            (e.responseJSON && e.responseJSON.error && e.responseJSON.error.message) ||
            e.message ||
            e.statusText ||
            "" + e
        console.log("e", e)
        throw err
    }
}

/**
 *
 * @param {object} analysis
 */
const initiateAnalysisPurchase = async (analysis) => {
    try {
        const data = {
            analysisId: analysis._id,
        }

        const response = await jQuery.ajax({
            method: "POST",
            headers: {
                Authorization: appState.account.apiKey,
            },
            url: `${crConstants.authHost}/v1/get_token`, //payment_start
            data,
            dataType: "json",
        })
        if (response.client_token) {
            return response.client_token
        } else {
            throw response.message || "An unknown error occurred."
        }
    } catch (e) {
        const err =
            (e.responseJSON && e.responseJSON.error && e.responseJSON.error.message) ||
            e.message ||
            e.statusText ||
            "" + e
        console.log("e", e)
        throw err
    }
}

const getAirwallexPaymentIntent = async () => {
    return jQuery.ajax({
        method: "GET",
        // eslint-disable-next-line no-undef
        url: `${crConstants.authHost}/v1/payment/get_intent`,
        data: {},
        headers: {
            // "Access-Control-Allow-Headers": 'Content-Type',
            // "Access-Control-Allow-Origin": '*',
            Authorization: appState.account.apiKey,
        },
        dataType: "json",
    });
}

/**
 *
 * @param {object} analysis
 * @param {string} intentId
 * @param {boolean} saveAnalysis
 */
const confirmAirwallexAnalysisPurchase = async (analysis, intentId, saveAnalysis = false) => {
    try {
        const data = {
            analysisId: analysis._id,
            intentId: intentId,
            saveAnalysis: saveAnalysis,
            analysis: saveAnalysis ? JSON.stringify(analysis) : null,
        }
        const response = await jQuery.ajax({
            method: "POST",
            headers: {
                Authorization: appState.account.apiKey,
            },
            url: `${crConstants.authHost}/v1/payment/confirm`,
            data: data,
            dataType: "json",
        })
        if (response._id) {
            appState.analyses = { ...appState.analyses, [response._id]: response }
            const sortedAnalyses = _.sortBy(Object.values(appState.analyses), "createdAt.$date")
            appState.analyses = sortedAnalyses.reduce(
                (o, analysis, index) => ({ ...o, [analysis._id]: analysis }),
                {}
            )
        }
        return response
    } catch (e) {
        const err =
            (e.responseJSON && e.responseJSON.error && e.responseJSON.error.message) ||
            e.message ||
            e.statusText ||
            "" + e
        console.log("e", e)
        throw err
    }
}

/**
 *
 * @param {object} analysis
 * @param {string} paymentNonce
 * @param {boolean} saveAnalysis
 */
const confirmAnalysisPurchase = async (analysis, paymentNonce, saveAnalysis = false) => {
    try {
        const data = {
            analysisId: analysis._id,
            paymentNonce: paymentNonce,
            saveAnalysis: saveAnalysis,
            analysis: saveAnalysis ? JSON.stringify(analysis) : null,
        }
        const response = await jQuery.ajax({
            method: "POST",
            headers: {
                Authorization: appState.account.apiKey,
            },
            url: `${crConstants.authHost}/v1/confirm_payment`, //payment_start
            data: data,
            dataType: "json",
        })
        if (response._id) {
            appState.analyses = { ...appState.analyses, [response._id]: response }
            const sortedAnalyses = _.sortBy(Object.values(appState.analyses), "createdAt.$date")
            appState.analyses = sortedAnalyses.reduce(
                (o, analysis, index) => ({ ...o, [analysis._id]: analysis }),
                {}
            )
        }
        return response
    } catch (e) {
        const err =
            (e.responseJSON && e.responseJSON.error && e.responseJSON.error.message) ||
            e.message ||
            e.statusText ||
            "" + e
        console.log("e", e)
        throw err
    }
}

const finaliseAnalysisPurchase = async () => {
    try {
        const getParams = new URLSearchParams(window.location.search)

        const data = {
            analysisId: getParams.get("analysisId"),
            paymentId: getParams.get("paymentId"),
            PayerID: getParams.get("PayerID"),
        }

        const response = await jQuery.ajax({
            method: "POST",
            headers: {
                Authorization: appState.account.apiKey,
            },
            url: `${crConstants.authHost}/v1/payment_finalise`,
            data,
            dataType: "json",
        })

        if (response.payment && response.payment.date) {
            appState.analyses = { ...appState.analyses, [response._id]: response }
            const sortedAnalyses = _.sortBy(Object.values(appState.analyses), "createdAt.$date")
            appState.analyses = sortedAnalyses.reduce(
                (o, analysis, index) => ({ ...o, [analysis._id]: analysis }),
                {}
            )

            return response
        } else {
            throw response.message || "An unknown error occurred."
        }
    } catch (e) {
        const err =
            (e.responseJSON && e.responseJSON.error && e.responseJSON.error.message) ||
            e.message ||
            e.statusText ||
            "" + e
        console.log("e", e)
        throw err
    }
}

/**
 *
 * @param {object} analysis
 */
const getAnalysisGroup = (analysis) => {
    if (!analysis.groupId || !appState.analyses) return []
    return Object.values(appState.analyses).filter((a) => a.groupId == analysis.groupId)
}

/**
 *
 * @param {string} groupId
 */
const deleteAnalysis = async (groupId) => {
    try {
        // Sending group id for now and receiving analyses ids deleted from server
        const response = await jQuery.ajax({
            method: "DELETE",
            headers: {
                Authorization: appState.account.apiKey,
            },
            url: `${crConstants.authHost}/v1/analysis` + "?" + $.param({ id: groupId }),
            dataType: "json",
        })

        if (response.response) {
            appState.analyses = { ...appState.analyses }
            const allAnalyses = Object.values(appState.analyses)
            const remainingAnalyses = _.remove(Object.values(appState.analyses), function (obj) {
                return obj.groupId !== groupId
            })
            const sortedAnalyses = _.sortBy(remainingAnalyses, "createdAt.$date")
            appState.analyses = sortedAnalyses.reduce(
                (o, analysis, index) => ({ ...o, [analysis._id]: analysis }),
                {}
            )
            //It is important to store results comming from the database in "adaptState.analyses".
            adaptState.analyses = sortedAnalyses.reduce(
                (o, analysis, index) => ({ ...o, [analysis._id]: analysis }),
                {}
            )
            return response
        } else {
            throw response.message || "An unknown error occurred."
        }
    } catch (e) {
        const err =
            (e.responseJSON && e.responseJSON.error && e.responseJSON.error.message) ||
            e.message ||
            e.statusText ||
            "" + e
        console.log("e", e)
        throw err
    }
}

const getAnalyses = () => {
    return appState.analyses
}
//to get analysis from auth-provider
/**
 *
 * @param {string} analysisId
 */
const shareAnalysis = async (analysisId) => {
    try {
        const response = await jQuery.ajax({
            method: "GET",
            url: `${crConstants.authHost}/v1/share_analysis/${analysisId}`,
            dataType: "json",
        })

        if (response) {
            if (appState.account && appState.account._id["$oid"] !== response.userId["$oid"]) {
                appState.analyses[response["_id"]] = response
                const sortedAnalyses = _.sortBy(appState.analyses, "createdAt.$date")
                appState.analyses = sortedAnalyses.reduce(
                    (o, analysis) => ({ ...o, [analysis._id]: analysis }),
                    {}
                )
            } else if (!appState.account) {
                var analysis = [response]
                const sortedAnalyses = _.sortBy(analysis, "createdAt.$date")
                appState.analyses = sortedAnalyses.reduce(
                    (o, analysis) => ({ ...o, [analysis._id]: analysis }),
                    {}
                )
            }
            return response
        } else {
            throw response.message || "An unknown error occurred."
        }
    } catch (e) {
        const err =
            (e.responseJSON && e.responseJSON.error && e.responseJSON.error.message) ||
            e.message ||
            e.statusText ||
            "" + e
        console.log("share analysis error:", e)
        throw err
    }
}

const sendSiteCheckMail = async (ratingData, email = null) => {
    try {
        const response = await jQuery.ajax({
            method: "POST",
            headers: {
                Authorization: appState.account && appState.account.apiKey,
            },
            url: `${crConstants.authHost}/v1/basic_site_check/ratings`,
            data: { ratingData: JSON.stringify(ratingData), email: JSON.stringify(email) },
            dataType: "json",
        })

        if (response) {
            console.log(response)
        } else {
            throw response.message || "An unknown error occurred."
        }
    } catch (e) {
        const err =
            (e.responseJSON && e.responseJSON.error && e.responseJSON.error.message) ||
            e.message ||
            e.statusText ||
            "" + e
        console.log("e", e)
        throw err
    }
}

export {
    sendSiteCheckMail,
    shareAnalysis,
    CR_doAnalysis,
    loadAllAnalyses,
    saveAnalysis,
    saveSearch,
    initiateAnalysisPurchase,
    getAirwallexPaymentIntent,
    confirmAirwallexAnalysisPurchase,
    confirmAnalysisPurchase,
    finaliseAnalysisPurchase,
    getAnalysisGroup,
    deleteAnalysis,
    getAnalyses,
    handleDeleteAdaptation,
    handleSaveAdaptation,
    updateFieldDefaults,
}
