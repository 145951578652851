import React from "react";
import autoBind from "react-autobind";
import { Button, Popover, OverlayTrigger } from "react-bootstrap";
import ReactGA from "react-ga";

import { hubSpotTracking } from "../../CR-Components/hubSpot.js";

import "./Pricing.scss";
//Google analytics for react
ReactGA.initialize("UA-148155584-1");

class Pricing extends React.Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        if (crConstants.production) ReactGA.modalview("/pricing");
        window.document.title = "Pricing";
        hubSpotTracking("setPath", "/pricing", "trackPageView");
    }

    /**
     *
     * @param {string} id
     * @param {string} title
     * @param {string} content
     * @param {boolean} tAndC
     */
    makeInfoPopover(id, title, content, tAndC = false) {
        const popover = (
            <Popover id={"popover-" + id}>
                <Popover.Title as="h3">{title}</Popover.Title>
                <Popover.Content>
                    {content} <br />
                    {tAndC && (
                        <React.Fragment>
                            <br />
                            <a href=" /info/terms_and_conditions" target="_blank">
                                Terms and Conditions
                            </a>
                        </React.Fragment>
                    )}
                </Popover.Content>
            </Popover>
        );

        return (
            <OverlayTrigger trigger="click" placement="auto" rootClose={true} overlay={popover}>
                <Button variant="primary" className="infopopover">
                    ?
                </Button>
            </OverlayTrigger>
        );
    }

    render() {
        return <div className="pricing-page">
            <div className="pricing-container">
                <div className="free-box">
                    <img
                        src="/static/images/check.svg"
                        width="100%"
                        align="center"
                        alt="EasyXDI - Free Search"
                    />
                    <div className="box-header">
                        <h4>
                            <strong>EasyXDI</strong>
                        </h4>
                        <h4>Non-Commercial</h4>
                        <div className="redbox" />
                        {this.makeInfoPopover(
                            "free",
                            "Free for non-commercial use",
                            "EasyXDI analysis is free to students, Not for Profits and anyone wishing to understand climate risk to a non commercial asset",
                            true
                        )}
                    </div>
                    <ul>
                        <li>
                            <strong>Single Asset</strong>
                        </li>
                        <li>100 standard asset types</li>
                        <li>No asset customisation</li>
                        <li>Risk rating for current year and year 2100</li>
                        <li>Impacts for all hazards aggregated in one view</li>
                    </ul>
                    <div className="button-box">
                        <h4>
                            <strong>Free</strong>
                        </h4>
                    </div>
                </div>
                <div className="commercial-box">
                    <img
                        src="/static/images/buildings.svg"
                        width="100%"
                        align="center"
                        alt="EasyXDI - Commercial Use"
                    />
                    <div className="box-header">
                        <h4>
                            <strong>EasyXDI</strong>
                        </h4>
                        <h4>Commercial</h4>
                        <div className="redbox" />
                        {this.makeInfoPopover(
                            "commercial",
                            "Commercial Use",
                            "If you are using EasyXDI in application to commercial assets, in business, or for management, you will need to pay for your analysis.",
                            true
                        )}
                    </div>
                    <ul>
                        <li>
                            <strong>Single Asset</strong>
                        </li>
                        <li>100 standard asset types</li>
                        <li>Asset customisation</li>
                        <li>
                            Risk rating for current year and end of asset life span (or 2100
                            as default)
                        </li>
                        <li>Impacts charted per hazard for contribution to total impact</li>
                        <ul className="ind-ul">
                            <li>Extreme heat</li>
                            <li>Forest fire</li>
                            <li>Coastal inundation and sea level rise</li>
                            <li>Riverine and overland flooding</li>
                            <li>Wind</li>
                            <li>Soil movement</li>
                        </ul>
                        <li>Printable report</li>
                    </ul>
                    <div className="button-box">
                        <h4>
                            <strong>$1035</strong>USD
                        </h4>
                    </div>
                </div>
                <div className="otherProducts-box">
                    <img
                        src="/static/images/bridge.svg"
                        width="100%"
                        align="center"
                        alt="Other XDI Products"
                    />
                    <div className="box-header">
                        <h4>
                            <strong>Other XDI Products</strong>
                        </h4>
                        <div className="redbox" />
                    </div>
                    <ul>
                        <li>
                            <strong>Multiple Assets</strong>
                        </li>
                        <p className="list-header">If you:</p>
                        <ul className="ind-ul">
                            <li>
                                Require analysis of a portfolio of multiple assets in
                                multiple locations{" "}
                            </li>
                            <li>
                                Need deeper optics on risk including cost benefit analysis
                                and adaptation pathways
                            </li>
                            <li>
                                Seek to understand cross dependent impacts and risk transfer
                                between infrastructure types
                            </li>
                            <li>
                                Are interested in an online, iterative approach to climate
                                risk management in your organisation
                            </li>
                        </ul>
                        <p />
                        <p>
                            Go to{" "}
                            <a href="https://xdi.systems/" target="_blank">
                                xdi.systems
                            </a>{" "}
                            for more information on the suite of products in the XDI
                            Platform or contact us to request a demonstration.
                        </p>
                    </ul>
                    <div className="button-box">
                        <Button
                            className="btn-primary btn-contact-us"
                            href="https://xdi.systems/contact/"
                            target="_blank"
                        >
                            CONTACT US
                        </Button>
                    </div>
                </div>
            </div>

            <br />
            <div className="compliance-box">
                <h4>Standard Compliance Statement</h4>
                <br />
                <p>
                    <span>
                        The analysis from Easy XDI is based on world leading and award
                        winning analytics. It meets the standard compliance requirements
                        for:
                    </span>
                </p>
                <ul>
                    <li>
                        <span>Task Force for Climate Related Financial Disclosures (</span>
                        <a href="https://www.fsb-tcfd.org/">
                            <span>TCFD</span>
                        </a>
                        <span>) physical risk reporting</span>
                    </li>
                    <li>
                        <span>United Nations Environment Program Finance Initiative (</span>
                        <a href="https://www.unepfi.org/">
                            <span>UNEP FI</span>
                        </a>
                        <span>) requirements</span>
                    </li>
                    <li>
                        <span>European Bank for Reconstruction (</span>
                        <a href="https://www.ebrd.com/home">
                            <span>EBRD</span>
                        </a>
                        <span>) requirements</span>
                    </li>
                    <li>
                        <span>Canadian </span>
                        <a href="https://pievc.ca/">
                            <span>PIEVC</span>
                        </a>
                        <span> Standard</span>
                    </li>
                    <li>
                        <a href="https://www.iso.org/standard/68507.html">
                            <span>ISO 14090</span>
                        </a>
                        <span>
                            {" "}
                            &ndash; Adaptation to Climate Change: Principles, requirements
                            and guidelines
                        </span>
                    </li>
                    <li>
                        <span>Draft EU </span>
                        <a href="https://ec.europa.eu/info/publications/sustainable-finance-teg-green-bond-standard_en">
                            <span>Green Bonds Standard</span>
                        </a>
                    </li>
                    <li>
                        <span>Climate Bonds Initiative (</span>
                        <a href="https://www.climatebonds.net/">
                            <span>CBI</span>
                        </a>
                        <span>) Adaptation and Resilience Expert Group (</span>
                        <a href="https://www.climatebonds.net/adaptation-and-resilience">
                            <span>AREG</span>
                        </a>
                        <span>) draft Adaptation and Resilience Principles</span>
                    </li>
                </ul>
            </div>
        </div>;
    }
}

export default Pricing;
