import { observable } from "mobx";

class AppState {
    @observable analysis = {};
    @observable analyses = {};
    @observable account = null;
    @observable fieldDefaultValues = {};
    @observable materials = {};
    @observable loading = true;
    @observable viewportWidth = 0;
    @observable viewportHeight = 0;
    @observable verifyEmail = false;
    @observable shareAnalysis = null;

    // Globe states:
    // TODO: remove where possible
    @observable showResultModal = false
    @observable current_analysis = null
    @observable current_analysis_source = null
    @observable current_dependencies = {}
    @observable viewType = {} // Object mapping app "view types" to boolean values. eg { nsw: true, ... }.
    @observable showStackSelectModal = false
    @observable analysesList = []
    @observable archetypePaths = {}
    current_project = 'public'

    // CV states, not observable
    expressPrice = 59
    expressDiscount = 30
    compPrice = 199
    compDiscount = 0
    currency = 'AU'
}

const appState = new AppState();

const updateViewportDimensions = () => {
    appState.viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    appState.viewportHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
    );
};
updateViewportDimensions();
window.addEventListener("resize", updateViewportDimensions);

export default appState;
