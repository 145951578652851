import React from "react"
import Highcharts from "highcharts"
import ReactHighcharts from "react-highcharts"
import autoBind from "react-autobind"
import PropTypes from "prop-types"

/**
 * Draws chart HighChart object
 *
 * @param {string} title - title of chart
 * @param {string} yAxisTitle - title on Y axis
 * @param {Array} seriesArray - data series
 * @param {number} startYear - start year of the analysis
 *
 * @returns {object} - HighCharts data object
 */
const drawChart = function (title, yAxisTitle, seriesArray, startYear, colorScheme) {
    const config = {
        chart: {
            zoomType: "xy",
            height: "500px",
            type: colorScheme === "CV" ? "line" : "area",
        },
        credits: {
            enabled: false
        },
        title: {
            text: title,
        },
        // subtitle: {
        //   text: 'Annual average cost of damage / Asset replacement value'
        // },
        xAxis: {
            title: {
                text: "Year",
                style: {
                    fontWeight: 'bold',
                    fontSize: 18
                }
            },
            labels: {
                style: {
                    fontWeight: 'bold',
                    fontSize: 15
                }
            },
            lineWidth: 2,
            lineColor: "#000000",
        },
        yAxis: [
            {
                min: 0,
                title: {
                    text: yAxisTitle,
                    style: {
                        color: Highcharts.getOptions().colors[1],
                        fontWeight: 'bold',
                        fontSize: 18
                    },
                },
                gridLineWidth: 0,
                lineWidth: 2,
                lineColor: "#000000",
                softMax: 0.25,
                labels: {
                    formatter: function () {
                        return Highcharts.numberFormat(this.value, 2, ".") + "%"
                    },
                    style: {
                        color: Highcharts.getOptions().colors[1],
                        fontWeight: 'bold',
                        fontSize: 12
                    },
                },
                minTickInterval: 0.01,
                plotLines: [
                    {
                        value: 0.2,
                        width: 2,
                        dashStyle: colorScheme === "CV" ? "LongDash" : "Dot",
                        color: colorScheme === "CV" ? "#000000" : "rgba(255, 150, 0, .75)",
                        label: {
                            align: "left",
                            style: {
                                fontStyle: "italic",
                            },
                            text: "Moderate Risk (B Rating)",
                            x: 10,
                        },
                    },
                    {
                        value: 1,
                        width: 2,
                        color: colorScheme === "CV" ? "#000000" : "rgba(255, 150, 0, .75)",
                        dashStyle: colorScheme === "CV" ? "LongDash" : "Dot",
                        label: {
                            align: "left",
                            style: {
                                fontStyle: "italic",
                            },
                            text: "High Risk (C Rating)",
                            x: 250,
                        },
                    },
                    {
                        value: 10,
                        width: 2,
                        color: colorScheme === "CV" ? "#000000" : "rgba(255, 150, 0, .75)",
                        dashStyle: colorScheme === "CV" ? "LongDash" : "Dot",
                        label: {
                            align: "left",
                            style: {
                                fontStyle: "italic",
                            },
                            text: "Likely uninsurable",
                            x: 20,
                        },
                    },
                ],
                // plotBands: [{
                //     from: 0.2,
                //     to: 1,
                //     color: 'rgba(255, 150, 0, .2)'
                // }, {
                //     from: 1,
                //     to: 100,
                //     color: 'rgba(255, 0, 0, .2)'
                // }]
            },
        ],
        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false,
                },
                pointStart: startYear,
            },
            // line: {
            //     softThreshold: false
            // }
        },
        tooltip: {
            shared: true,
        },
        navigation: {
            buttonOptions: {
                enabled: colorScheme === "CV" ? false : true,
            },
        },
        series: seriesArray,
    }
    return config
}

class DrawVARChart extends React.Component {
    static propTypes = {
        analysis: PropTypes.object.isRequired,
        source: PropTypes.string,
        onAssetRenderComplete: PropTypes.func,
        colorScheme: PropTypes.string,
    }

    _VARGraphReady = false
    constructor(props) {
        super(props)
        autoBind(this)
        this.VARGraphReady = this.VARGraphReady.bind(this)
    }

    VARGraphReady() {
        this._VARGraphReady = true
        if (this.props.source && this._VARGraphReady)
            this.props.onAssetRenderComplete(this.props.source)
    }
    render() {
        const { analysis } = this.props

        const riskToDate = function () {
            let riskFraction = analysis.properties.totalRisk.map(
                (v) => (100 * v))
            let riskToDate = [riskFraction[0]]
            let i = 1
            for (i = 1; i < riskFraction.length; i++) {
                if (riskFraction[i] < riskToDate[i - 1]) {
                    riskToDate.push(riskToDate[i - 1])
                } else {
                    riskToDate.push(riskFraction[i])
                }
            }
            return riskToDate
        }

        const riskToDateMarkers = function () {
            let riskFraction = analysis.properties.totalRisk.map(
                (v) => (100 * v)
            )
            let riskToDate = [riskFraction[0]]
            let i = 1

            const addMarker = (value) => {
                return {
                    y: value,
                    marker: {
                        enabled: true,
                        radius: 10,
                        fillColor: "#00000",
                    }
                }
            }

            for (i = 1; i < riskFraction.length; i++) {
                if (riskFraction[i] < riskToDate[i - 1]) {
                    riskToDate.push(riskToDate[i - 1])
                } else {
                    riskToDate.push(riskFraction[i])
                }
            }

            // add markers at start, end of mortgage, and end year
            riskToDate[0] = addMarker(riskToDate[0])
            riskToDate[analysis.inputs.asset.properties.mortgageTerm] = addMarker(riskToDate[analysis.inputs.asset.properties.mortgageTerm])
            riskToDate[riskFraction.length - 1] = addMarker(riskToDate[riskFraction.length - 1])

            return riskToDate
        }

        const VARMarkers = function () {
            let riskFraction = analysis.properties.totalRisk.map(
                (v) => (100 * v)
            )

            const addMarker = (value) => {
                return {
                    y: value,
                    marker: {
                        enabled: true,
                        radius: 10,
                        fillColor: "#00000",
                    }
                }
            }

            // add markers at start, end of mortgage, and end year
            riskFraction[0] = addMarker(riskFraction[0])
            riskFraction[analysis.inputs.asset.properties.mortgageTerm] = addMarker(riskFraction[analysis.inputs.asset.properties.mortgageTerm])
            riskFraction[riskFraction.length - 1] = addMarker(riskFraction[riskFraction.length - 1])

            return riskFraction
        }

        let VAR
        if (this.props.colorScheme !== "CV") {
            VAR = [
                {
                    name: "Maximum Value At Risk (%) To Date",
                    data: riskToDate(),
                    marker: {
                        enabled: false,
                    },
                    fillOpacity: 0.1,
                    color: "#b51218",
                    tooltip: {
                        pointFormatter: function () {
                            return (
                                "<b>" +
                                this.series.name +
                                "</b>: " +
                                Highcharts.numberFormat(this.y, 4, ".") +
                                "%<br/>"
                            )
                        },
                    },
                },
                {
                    name: "Value At Risk (Annual %)",
                    // TODO: Replace this with Value At Risk as calculated by Ana (add in Ana). ned.
                    data: analysis.properties.totalRisk.map(
                        (v) => (100 * v)),
                    marker: {
                        enabled: false,
                    },
                    fillOpacity: 0.5,
                    tooltip: {
                        pointFormatter: function () {
                            return (
                                "<b>" +
                                this.series.name +
                                "</b>: " +
                                Highcharts.numberFormat(this.y, 4, ".") +
                                "%<br/>"
                            )
                        },
                    },
                },
            ]
        } else {
            VAR = [
                {
                    fillOpacity: 1,
                    showInLegend: true,
                    name: "Maximum Value At Risk to Date (MVAR%)",
                    data: riskToDateMarkers(),
                    marker: {
                        enabled: false,
                    },
                    color: "#262626",
                    lineWidth: 4,
                    tooltip: {
                        pointFormatter: function () {
                            return (
                                "<b>" +
                                this.series.name +
                                "</b>: " +
                                Highcharts.numberFormat(this.y, 4, ".") +
                                "%<br/>"
                            )
                        },
                    },
                },
                {
                    fillOpacity: 1,
                    showInLegend: true,
                    name: "Value At Risk (VAR%)",
                    data: VARMarkers(),
                    marker: {
                        enabled: false,
                    },
                    color: "#f26522",
                    lineWidth: 4,
                    tooltip: {
                        pointFormatter: function () {
                            return (
                                "<b>" +
                                this.series.name +
                                "</b>: " +
                                Highcharts.numberFormat(this.y, 4, ".") +
                                "%<br/>"
                            )
                        },
                    },
                }
            ]
        }

        const yAxisText = this.props.colorScheme === "CV" ? "" : "Value At Risk (%)";
        const xAxisText = "Value at Risk (VAR%)"

        return (
            <ReactHighcharts
                domProps={{ id: "chartId" }}
                callback={this.VARGraphReady}
                config={drawChart(
                    yAxisText,
                    xAxisText,
                    VAR,
                    this.props.analysis.inputs.scenario.startYear,
                    this.props.colorScheme
                )}
                className="RiskFraction"
            />
        )
    }
}

export default DrawVARChart

/**
 * Chart config object function
 *
 * @param {object} analysis -analysis object
 */
const VARChartConfig = async (analysis) => {
    const riskToDate = function () {
        let riskFraction = analysis.properties.totalRisk.map(
            (v) => (100 * v)
        )
        let riskToDate = [riskFraction[0]]
        let i = 1
        for (i = 1; i < riskFraction.length; i++) {
            if (riskFraction[i] < riskToDate[i - 1]) {
                riskToDate.push(riskToDate[i - 1])
            } else {
                riskToDate.push(riskFraction[i])
            }
        }
        return riskToDate
    }

    const VAR = [
        {
            name: "Maximum Value At Risk (%) To Date",
            data: riskToDate(),
            marker: {
                enabled: false,
            },
            fillOpacity: 0.1,
            color: "#b51218",
            tooltip: {
                pointFormatter: function () {
                    return (
                        "<b>" +
                        this.series.name +
                        "</b>: " +
                        Highcharts.numberFormat(this.y, 4, ".") +
                        "%<br/>"
                    )
                },
            },
        },
        {
            name: "Value At Risk (Annual %)",
            // TODO: Replace this with Value At Risk as calculated by Ana (add in Ana). ned.
            data: analysis.properties.totalRiskCost.map(
                (v) => (100 * v) / analysis.properties.replacementCost
            ),
            marker: {
                enabled: false,
            },
            fillOpacity: 0.5,
            tooltip: {
                pointFormatter: function () {
                    return (
                        "<b>" +
                        this.series.name +
                        "</b>: " +
                        Highcharts.numberFormat(this.y, 4, ".") +
                        "%<br/>"
                    )
                },
            },
        },
    ]

    return drawChart("Value At Risk (%)", "VAR (%)", VAR, analysis.inputs.scenario.startYear)
}
export { VARChartConfig }
