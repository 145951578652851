import Cookies from "js-cookie"

/**
 *
 * Function to track user activity through HubSpot
 *
 * @param {string} trackingType - 'setPath'
 * @param {string} page - '/about-us'
 * @param {string} trackingName - 'trackPageView'
 */
function hubSpotTracking(trackingType, page, trackingName) {
    // if (!crConstants.production && crConstants.ClimateValuation) return
    var _hsq = (window._hsq = window._hsq || [])
    _hsq.push([trackingType, page])
    _hsq.push([trackingName])
}

/**
 *
 * Single function handles sending form data to hubSpot
 *
 * @param {string} portalId - Portal ID (eg. CV or EasyXDI) CV:7735589
 * @param {string} guId - HubSpot form ID
 * @param {object} data -JSON data to be sent
 */
function sendHubSpotData(portalId, guId, data) {
    // eslint-disable-next-line no-undef
    if (!crConstants.production && crConstants.ClimateValuation) return
    // TODO: make this handle both apps
    let xhr = new XMLHttpRequest()
    // `https://api.hsforms.com/submissions/v3/integration/submit/7735589/26f9ebea-ae21-45d5-aaef-82926c45c9e9`
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${guId}`

    xhr.open("POST", url)
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader("Content-Type", "application/json")

    // Sends the request
    xhr.send(data)
}

/**
 *
 * Function sends new contact info to CRM
 *
 * @param {string} email - email address from form
 * @param {boolean} newsletter - newsletter box checked?
 */
function hubSpotFormCreateAccount(email, newsletter) {
    const hutk = Cookies.get("hubspotutk")

    let pageUri = "easyxdi.com"
    let pageName = "EasyXDI"
    // eslint-disable-next-line no-undef
    if (crConstants.ClimateValuation) {
        pageUri = "climatevaluation.com"
        pageName = "Climate Valuation"
    }

    var data = {
        submittedAt: new Date().getTime(),
        fields: [
            {
                name: "email",
                value: email,
            },
            {
                name: "newsletter_subscription",
                value: newsletter,
            },
            {
                name: "email_verified",
                value: "false",
            },
        ],
        context: {
            hutk: hutk, // hubspotutk cookie value enables tracking on submission
            pageUri: pageUri,
            pageName: pageName,
        },
    }

    const final_data = JSON.stringify(data)
    // eslint-disable-next-line no-undef
    if (crConstants.ClimateValuation) {
        sendHubSpotData("7471644", "d4e71e97-8509-46f4-a509-bb19e12f74c7", final_data)
    } else {
        sendHubSpotData("7735589", "26f9ebea-ae21-45d5-aaef-82926c45c9e9", final_data)
    }
}

/**
 *
 * Function sends login info to CRM
 *
 * @param {string} email - email address from form
 */
function hubSpotFormLogin(email) {
    const hutk = Cookies.get("hubspotutk")
    // console.log(hutk)
    let pageUri = "easyxdi.com"
    let pageName = "EasyXDI"
    // eslint-disable-next-line no-undef
    if (crConstants.ClimateValuation) {
        pageUri = "climatevaluation.com"
        pageName = "Climate Valuation"
    }

    var data = {
        submittedAt: new Date().getTime(),
        fields: [
            {
                name: "email",
                value: email,
            },
            {
                name: "email_verified",
                value: "true",
            },
        ],
        context: {
            hutk: hutk, // hubspotutk cookie value enables tracking on submission
            pageUri: pageUri,
            pageName: pageName,
        },
    }

    const final_data = JSON.stringify(data)

    // console.log(final_data)
    // eslint-disable-next-line no-undef
    if (crConstants.ClimateValuation) {
        sendHubSpotData("7471644", "f9775399-2d71-448b-97a1-1e33a24eb285", final_data)
    } else {
        sendHubSpotData("7735589", "91767065-53aa-4979-abab-3b0ee7b97635", final_data)
    }
}

/**
 *
 * Function sends edit account info to CRM
 *
 * @param {string} email - email address from form
 * @param {boolean} newsletter - newsletter box checked?
 */
function hubSpotFormEditDetails(email, newsletter) {
    const hutk = Cookies.get("hubspotutk")

    let pageUri = "easyxdi.com"
    let pageName = "EasyXDI"
    // eslint-disable-next-line no-undef
    if (crConstants.ClimateValuation) {
        pageUri = "climatevaluation.com"
        pageName = "Climate Valuation"
    }

    var data = {
        submittedAt: new Date().getTime(),
        fields: [
            {
                name: "email",
                value: email,
            },
            {
                name: "newsletter_subscription",
                value: newsletter,
            },
        ],
        context: {
            hutk: hutk, // hubspotutk cookie value enables tracking on submission
            pageUri: pageUri,
            pageName: pageName,
        },
    }

    const final_data = JSON.stringify(data)
    // eslint-disable-next-line no-undef
    if (crConstants.ClimateValuation) {
        sendHubSpotData("7471644", "69b8daeb-f73c-4fc9-85de-939a55656cf1", final_data)
    } else {
        sendHubSpotData("7735589", "dc3d9705-2df3-46e0-b2ca-496b7e318a36", final_data)
    }
}

/**
 *
 * Function sends contact form info to CRM
 *
 * @param {string} name - name of user
 * @param {string} email - email address from form
 * @param {Array} purpose - purpose for using CV
 * @param {string} phone - phone number
 * @param {string} description - request content
 */
function hubSpotContactFormDetails(name, email, phone, purpose, description) {
    const hutk = Cookies.get("hubspotutk")

    var data = {
        submittedAt: new Date().getTime(),
        fields: [
            {
                name: "firstname",
                value: name,
            },
            {
                name: "email",
                value: email,
            },
            {
                name: "phone",
                value: phone,
            },
            {
                name: "purpose",
                value: purpose,
            },
            {
                name: "message",
                value: description,
            },
        ],
        context: {
            hutk: hutk, // hubspotutk cookie value enables tracking on submission
            pageUri: "climatevaluation.com",
            pageName: "Climate Valuation: Contact",
        },
    }

    const final_data = JSON.stringify(data)

    sendHubSpotData("7471644", "b8279a08-43b9-4a4d-825a-22f6d29a3d1d", final_data)
}

/**
 *
 * Function sends contact form info to CRM
 *
 * @param {string} name - name of user
 * @param {string} email - email address from form
 * @param {string} company - company name
 * @param {string} jobTitle - job title
 * @param {string} phone - phone number
 * @param {string} description - request content
 */
function hubSpotCommercialFormDetails(name, email, phone, company, jobTitle, description) {
    const hutk = Cookies.get("hubspotutk")

    var data = {
        submittedAt: new Date().getTime(),
        fields: [
            {
                name: "firstname",
                value: name,
            },
            {
                name: "email",
                value: email,
            },
            {
                name: "phone",
                value: phone,
            },
            {
                name: "company",
                value: company,
            },
            {
                name: "jobtitle",
                value: jobTitle,
            },
            {
                name: "message",
                value: description,
            },
        ],
        context: {
            hutk: hutk, // hubspotutk cookie value enables tracking on submission
            pageUri: "climatevaluation.com",
            pageName: "Climate Valuation: Commercial Contact",
        },
    }

    const final_data = JSON.stringify(data)

    sendHubSpotData("7471644", "cb76b442-9b72-4965-9e31-53c3f2a0ef85", final_data)
}

export {
    hubSpotTracking,
    hubSpotFormCreateAccount,
    hubSpotFormLogin,
    hubSpotFormEditDetails,
    hubSpotContactFormDetails,
    hubSpotCommercialFormDetails,
}
