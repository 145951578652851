import React from "react";
import ReactDOM from "react-dom";

import EIBase from "./EIBase.jsx";

export default class EITextArea extends EIBase {
    renderEditingComponent = () => {
        return (
            <textarea
                rows={this.props.rows}
                cols={this.props.cols}
                disabled={this.state.loading || this.props.disabled}
                defaultValue={this.props.value}
                onInput={(e) => this.valueChanged(e.target.value)}
                ref={(ref) => (this.ref = ref)}
                onKeyDown={this.keyDown}
                {...this.props.editProps}
            />
        );
    };

    renderNormalComponent = () => {
        const value = this.state.newValue || this.props.value || this.props.emptyValue || "Set me.";
        return (
            <span {...this.props.defaultProps}>
                <pre>{value}</pre>
            </span>
        );
    };

    keyDown = (event) => {
        if (event.keyCode === 27) {
            this.cancelEditing();
        } // Escape
    };
}
