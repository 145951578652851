import React from "react";
import autoBind from "react-autobind";
import { Button, Form, FormGroup, ControlLabel, FormControl, Modal } from "react-bootstrap";
import ReactGA from "react-ga";

import { hubSpotTracking } from "../../CR-Components/hubSpot.js";
import EditProfileFrom from "./EditProfileFrom.jsx";

import "./Account.scss";

class ModalEditProfileFrom extends React.Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const crConstantFlag = `${crConstants.ClimateValuation}`;
        return (
            <Modal
                show={true}
                onEntered={() => {
                    if (!crConstants.ClimateValuation) {
                        if (crConstants.production) ReactGA.modalview("/edit-profile");
                    }
                    window.document.title = "Edit Profile";
                    hubSpotTracking("setPath", "/edit-profile-modal", "trackPageView");
                }}
                onHide={this.props.onCancel}
                className="LoginForm-modal"
            >
                <Modal.Header
                    className={crConstantFlag == "true" ? "cv-modal-header" : "modal-header"}
                    closeButton
                >
                    <h3>Edit Profile</h3>
                </Modal.Header>

                <Modal.Body>
                    <EditProfileFrom {...this.props} />
                </Modal.Body>
            </Modal>
        );
    }
}

export default ModalEditProfileFrom;
