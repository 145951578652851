import React from "react"
import autoBind from "react-autobind"
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Overlay, Popover } from "react-bootstrap"
import Cookies from "js-cookie"

import "./Instructions.scss"

class Instructions extends React.Component {

    static propTypes = {
        instructions: PropTypes.array,
        completed: PropTypes.object,
        hide: PropTypes.func,
    }
    constructor(props) {
        super(props)

        this.state = {
            show: true, // Cookies.get('instructions-hide') != '1',
        }

        autoBind(this)
    }

    // used to disable with cookie control (currently button is used to activate)
    // hide() {
    //   Cookies.set('instructions-hide', '1', { expires: 1 });
    //   this.setState({show: false});
    // }

    render() {
        if (!this.state.show) return null
        const { instructions, completed, hide } = this.props
        const completedSteps = (completed && Object.keys(_.pickBy(completed, (val) => !!val))) || []
        const stepIndex =
            _.findLastIndex(instructions, (ins) => completedSteps.includes(ins.key)) + 1
        if (stepIndex >= instructions.length) return null
        const instruction = instructions[stepIndex]
        if (!instruction.ref()) return null

        return (
            <Overlay show={true} target={instruction.ref()}>
                <Popover className="Instructions" id={"instruction-popover-" + instruction.key}>
                    <Popover.Title as="h3">
                        <span>{instruction.title}</span>
                        <span className="instructions-hide" onClick={hide}>
                            Hide instructions
                        </span>
                    </Popover.Title>
                    <Popover.Content>{instruction.content}</Popover.Content>
                </Popover>
            </Overlay>
        )
    }
}

export default Instructions
