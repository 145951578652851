import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import ReactGA from "react-ga";

import DrawVARChart from "../../CR-Components/Charts/VAR-Charts.jsx"
import appState from "../CoreFiles/state.js"

const ValueAtRisk = (props) => {
    const { analysis } = props

    useEffect(() => {
        if (crConstants.production) ReactGA.modalview("/analysis-result/var-tab")
    }, [])

    return (
        <React.Fragment>
            <div className="tab-content">
                <header>
                    <h2>Total Value at Risk (VAR%)</h2>
                </header>
                <p>
                    This chart shows the contribution of all hazards to the Annual Value At Risk
                    % and the Maximum Value At Risk To Date.
                </p>
                <p>Annual average cost of damage / Asset replacement value</p>
            </div>

            <div className="chartContainer">
                <DrawVARChart analysis={analysis} fieldDefaultValues={appState.fieldDefaultValues} />
            </div>

            <div className="tab-content">
                <p>
                    Starting in 2020, the Max VAR% shows the highest VAR% reached up to a given
                    date. This can be thought of as the record reached by an athlete, which
                    stays unchanged until the record is broken and pushed higher. Max VAR% is an
                    important reference for stress testing an asset, because climate models may
                    not be accurate at assessing the precise time at which climate indicators
                    increase or decrease. This chart alerts the user to the maximum VAR% that
                    has been seen up until that date. This number may be the most appropriate to
                    use for adaptation planning.
                </p>
            </div>

            <div className="tab-content">
                <p>
                    Note that downward fluctuations sometimes appear due to climate model
                    outputs, which VAR% follows. In some cases, model outputs are aggregated
                    into 20 year blocks, in which case curve-fitting is used to interpolate and
                    extrapolate the annual behaviour.
                </p>
            </div>
        </React.Fragment>
    )
}
ValueAtRisk.propTypes = {
    analysis: PropTypes.object
}

export default ValueAtRisk
