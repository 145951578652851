/**
 * Helper functions for cesium-related components
 */
import { Math } from "cesium";

const cartographicToDegrees = (carto) => {
    return {
        latitude: Math.toDegrees(carto.latitude),
        longitude: Math.toDegrees(carto.longitude),
        height: carto.height,
    };
};

const cartographicToRadians = (carto) => {
    return {
        latitude: Math.toRadians(carto.latitude),
        longitude: Math.toRadians(carto.longitude),
        height: carto.height,
    };
};

export { cartographicToDegrees, cartographicToRadians };
