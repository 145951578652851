/**
 * Google elevation call handling.
 *
 * This function requires that the google maps API javascript is imported by
 * the host app.
 *
 */
const googleElevation = (latitude, longitude) => {
    return new Promise((resolve, reject) => {
        const elevation = new google.maps.ElevationService()
        const latlng = new google.maps.LatLng(latitude, longitude)

        const timeoutHandle = setTimeout(() => reject("Elevation request timed out."), 20000)

        elevation.getElevationForLocations({ locations: [latlng] }, function (results, status) {
            clearTimeout(timeoutHandle)

            if (status === "OK") {
                try {
                    resolve({ elevation: results[0].elevation, dataset: "GoogleElevation" })
                } catch (ex) {
                    reject(ex)
                }
            } else {
                reject(status)
            }
        })
    })
}

export default googleElevation
