import React from "react"
import { ProgressBar } from "react-bootstrap"
import PropTypes from "prop-types"
import autoBind from "react-autobind"
import ReactGA from "react-ga"

import { hubSpotTracking } from "../../CR-Components/hubSpot.js"
import ModalLoginForm from "../../CR-Components/Account/ModalLoginForm.jsx"

import "./ProcessingModal.scss"

const labels = [
    "Accessing LiDAR data...",
    "Accessing tidal data...",
    "Accessing wave data...",
    "Accessing flood data...",
    "Accessing wind data...",
    "Accessing fire data...",
    "Accessing soil maps...",
    "Collecting archetype data...",
    "Collecting specific asset details...",
    "Uploading weather station data...",
    "Computing failure thresholds...",
    "Interrogating climate change GCM/RCM...",
    "Computing elements risk...",
    "Aggregating element risk...",
    "Computing AAL...",
    "Calculating the risk for the property...",
    "Calculating the risk for the property...",
    "Preparing report...",
    "Preparing report...",
]

class ProcessingModal extends React.Component {
    static propTypes = {
        analysis: PropTypes.object,
        account: PropTypes.object, // user account object
        purchased: PropTypes.bool, // analysis is purchased or user whitelisted
        openFull: PropTypes.bool, // display full report when finished
        showPurchaseModal: PropTypes.func, // display the purchase modal
        openThanks: PropTypes.func, // display the thank you modal
        onDone: PropTypes.func, // close the modal
        freeSiteCheck: PropTypes.bool, // is this a free site check?
        onClose: PropTypes.func, // closes processing modal
        analysisAvailable: PropTypes.bool,
    }

    constructor(props) {
        super(props)
        this.state = {
            progress: 0,
            showLoginModal: false,
            loggedInSuccessfully: this.props.account ? true : false,
            termsAccepted: false,
        }
        autoBind(this)
    }

    incrementProgress() {
        self.timeoutHandle = setTimeout(
            () => {
                this.setState({ progress: this.state.progress + 5 })
                if (this.state.progress < 100) this.incrementProgress()
            },
            250 + Math.random() * 250 // speed of bar
        )
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.incrementProgress()
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutHandle)
    }

    handleOpen() {
        ReactGA.event({
            category: "Analysis",
            action: "Successful Analysis",
            label: "open",
            value: 1,
        })
        window.document.title = "Analysis Result"
        hubSpotTracking("setPath", "/analysis", "trackPageView")
        this.props.onDone()
    }

    getPrintURL() {
        const getParams = new URLSearchParams(window.location.search)
        getParams.append("printView", 1)
        const loc = window.location
        const url = `${loc.protocol}//${loc.hostname}${
            loc.port ? ":" + loc.port : ""
        }/?${getParams.toString()}${loc.hash}`
        console.log(url)
        return url
    }

    async openEasyReport() {
        // Uncomment to require login
        // if (!this.props.account && !this.state.loggedInSuccessfully) {
        //     this.setState({ showLoginModal: true })
        // } else {
        this.handleOpen()
        // }
    }

    onSuccessfulLogin() {
        this.openEasyReport()
        this.setState({ showLoginModal: false, loggedInSuccessfully: true })
    }

    /**
     * function to update multiple states
     *
     * @param {string} field - state to update
     * @param {string|number|boolean|object} value - value to update to
     */
    handleChange(field, value) {
        this.setState({ [field]: value })
    }

    render() {
        const { freeSiteCheck } = this.props
        const { progress, showLoginModal } = this.state

        const step = Math.floor((progress / 100) * (labels.length - 1))

        return (
            <div className="processing-modal-background" style={{ zIndex: 99998 }}>
                {!!showLoginModal && (
                    <ModalLoginForm
                        CV={false}
                        onCancel={() => this.setState({ showLoginModal: false })}
                        onSuccessfulLogin={this.onSuccessfulLogin}
                    />
                )}
                {/* TODO: change to react modal */}
                {!showLoginModal && (
                    <div className="processing-modal terms">
                        <button type="button" className="close" onClick={this.props.onClose}>
                            <span aria-hidden="true">×</span>
                            <span className="sr-only">Close</span>
                        </button>

                        {/* Progress bar disabled for free site check */}
                        {!freeSiteCheck && progress < 100 && (
                            <div>
                                <h5>Running asset specific data and computing risk profile:</h5>
                                <p className="progress-text">{labels[step]}</p>
                                <ProgressBar variant="success" now={progress} animated="true" />
                            </div>
                        )}

                        {freeSiteCheck && progress < 100 && (
                            <div>
                                <h5>Running asset specific data and computing risk profile</h5>
                                {/* <p className="progress-text">{labels[step]}</p> */}
                                <ProgressBar variant="success" now={progress} animated="true" />
                            </div>
                        )}

                        {/* EasyXDI Results Complete */}
                        {progress == 100 && (
                            <div>
                                <p className="welcome-text">Your results are ready!</p>

                                {freeSiteCheck
                                    ? <p> View your results for free. If this analysis is for commercial application, you will need to purchase the report.</p> 
                                    : <></> }

                                <p className="note">
                                    {freeSiteCheck &&
                                        <i> <strong> Note:</strong> The free analysis does not include a hazards breakdown. </i> }
                                    <i>By clicking the button below you agree to
                                    our <a className="link" href="/info/terms_and_conditions" target="_blank"> Terms and Conditions</a></i>
                                </p>

                                <button className="btn-primary btn-agree" onClick={this.openEasyReport}>
                                    {freeSiteCheck ? "View Free Report" : "View Report"}
                                </button>
                            </div>
                        )}

                    </div>
                )}
            </div>
        )
    }
}

export default ProcessingModal
