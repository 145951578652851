import React from "react"
import Highcharts from "highcharts"
import ReactHighcharts from "react-highcharts"
import autoBind from "react-autobind"
import PropTypes from "prop-types"

import { showHazard } from '../../CR-Components/utils.js'
import { hazard_colours } from './Breakdown-Charts.jsx'

class DrawVulnerabilityCharts extends React.Component {

    static propTypes = {
        analysis: PropTypes.object.isRequired,
        purchased: PropTypes.func,
        year: PropTypes.number.isRequired,
        source: PropTypes.string,
        onAssetRenderComplete: PropTypes.func,
        colorScheme: PropTypes.string,
    }

    static defaultProps = {
        colorScheme: "EasyXDI",
    }

    _updateToParent = false
    constructor(props) {
        super(props)
        this.state = {
            elements: [],
        }
        autoBind(this)
    }

    async componentDidMount() {
        const { analysis } = this.props

        let elementList = []
        let elementsObject = analysis.inputs.asset.properties.elementMaterials

        Object.keys(elementsObject).forEach(function (e) {
            let addSpaces =
                e.substring(0, 1) +
                e
                    .substring(1)
                    .replace(/([A-Z])/g, " $1")
                    .toLowerCase()
            elementList.push(addSpaces)
        })

        this.setState({ elements: elementList })
    }

    componentDidUpdate() {
        const { elements } = this.state
        if (elements.length > 0 && this._updateToParent === false) {
            // Call onAssetRenderComplete if all good
            if (this.props.source) this.props.onAssetRenderComplete(this.props.source)

            this._updateToParent = true
        }
    }

    render() {
        const { elements } = this.state
        const { analysis, source, colorScheme } = this.props
        var hazardLabels = [];
        if(colorScheme === "CV"){
            // TODO: Verify Order!!!
            hazardLabels = [
                "Riverine Flooding",
                "Coastal Inundation",
                "Heat",
                "Forest Fire",
                "Extreme Wind",
                "Soil Subsidence",
            ]
        } else {
            hazardLabels = [
                "Flood (Riverine)",
                "Inundation",
                "Heat",
                "Forest fire",
                "Wind",
                "Soil Movement",
            ]

            // TODO: Replace by fixing analysis structure, get this from props
            if (showHazard(analysis, "freeze-thaw")){
                hazardLabels.push("Freeze Thaw")
            }
            if (showHazard(analysis, "flood_surfacewater")){
                hazardLabels.push("Flood (Surface Water)")
            }
            if (showHazard(analysis, "cyclone_wind")){
                hazardLabels.push("Tropical Cyclone Wind")
            }
        }
        
        const getSeries = function (year) {
            if (!analysis.properties.riskHazardsElements) {
                return [];
            }

            let series = []
            let yearIndex = year - analysis.inputs.scenario.startYear

            for (
                var hazardIndex = 0;
                hazardIndex < analysis.properties.riskHazardsElements[yearIndex].length;
                hazardIndex++
            ) {
                if(colorScheme === "CV"){
                    let areaColor;
                    if (hazardLabels[hazardIndex] === "Riverine Flooding") {
                        areaColor = "#3A657E"
                    }
                    if (hazardLabels[hazardIndex] === "Coastal Inundation") {
                        areaColor = "#3C8B53"
                    }
                    if (hazardLabels[hazardIndex] === "Soil Subsidence") {
                        areaColor = "#88203B"
                    }
                    if (hazardLabels[hazardIndex] === "Forest Fire") {
                        areaColor = "#D57A31"
                    }
                    if (hazardLabels[hazardIndex] === "Extreme Wind") {
                        areaColor = "#E1CC7F"
                    }
                    series.push({
                        name: hazardLabels[hazardIndex],
                        data: analysis.properties.riskHazardsElements[yearIndex][hazardIndex],
                        marker: {
                            enabled: false,
                        },
                        color: areaColor
                    })
                } else {
                    series.push({
                        name: hazardLabels[hazardIndex],
                        data: analysis.properties.riskHazardsElements[yearIndex][hazardIndex],
                        marker: {
                            enabled: false,
                        },
                    })
                }
            }

            return series
        }
        /**
         *
         * @param {object} values
         */
        const drawHighCharts = function (values) {
            let xAxisText;
            let legendColor;
            colorScheme === "CV" ? legendColor = "#E9EAEB" : legendColor = "transparent";
            if(colorScheme === "CV"){
                xAxisText = "Asset Elements",
                values.title = '';
            } else {
                if (source == "vulnerabilityEndYear") {
                    xAxisText = "Elements";
                } else {
                    xAxisText = "Property Elements"
                }
            }
            
            let highChartsConfig = {
                chart: {
                    height:  colorScheme === "CV" ? 1050 : 800,
                    zoomType: "xy",
                    type: "bar",
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: values.title,
                },
                legend: {
                    backgroundColor: legendColor,
                    padding: 10,
                },
                xAxis: {
                    title: {
                        text: xAxisText,
                        style: { fontWeight: 'bold', fontSize: 18 }
                    },
                    labels: {
                        style: {
                            fontWeight: 'bold',
                            fontSize: 13
                        }
                    },
                    categories: elements,
                    minTickInterval: 0.0001,
                    lineWidth: 2,
                    lineColor: "#000000",
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: "Value At Risk (%)",
                        style: { fontWeight: 'bold', fontSize: 18 }
                    },
                    labels: {
                        formatter: function () {
                            return Highcharts.numberFormat( this.value * 100, 2, ".") + "%"
                        },
                        style: {
                            fontWeight: 'bold',
                            fontSize: 13
                        }
                    },
                    gridLineWidth: 0,
                    lineWidth: 2,
                    lineColor: "#000000",
                },
                tooltip: {
                    pointFormatter: function () {
                        return (
                            "<b> " +
                            this.series.name +
                            "</b>: " +
                            Highcharts.numberFormat(this.y * 100, 4, ".") +
                            "%</b>"
                        )
                    },
                },
                marker: {
                    enabled: false,
                },
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false,
                        },
                        stacking: "normal",
                    },
                },
                navigation: {
                    buttonOptions: {
                        enabled: colorScheme === "CV" ? false : true,
                    },
                },
                series: values.series,
                colors: hazard_colours
            }
            return highChartsConfig
        }

        return (
            <ReactHighcharts
                config={drawHighCharts({
                    title: `Vulnerability per Hazard by Element: ${this.props.year}`,
                    series: getSeries(this.props.year),
                })}
                className="VulnerabilityChart"
            />
        )
    }
}

export default DrawVulnerabilityCharts
