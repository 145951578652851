/**
 * General utility functions
 */

/**
 * Correctly and safely check whether an object has the given property
 *
 * @param {object} obj - object to check
 * @param {string} prop - property to check the existence of
 *
 * @returns {boolean} whether the object contains the property
 */
const checkProp = (obj, prop) => {
    return Object.prototype.hasOwnProperty.call(obj, prop)
}

/**
 * Checks for the existence of a deep key, also works with arrays.
 *
 * @param {object} obj - object to check
 * @param {string} level - name of first level
 * @param {string} rest - comma separated all names of nested properties
 *
 * @returns {boolean} - returns true if nested property exists
 */
function checkNested(obj, level, ...rest) {
    if (obj === undefined || obj === null) {
        return false
    }
    if (Array.isArray(obj)) {
        if (obj.length <= level) {
            return false
        }
        if (rest.length == 0) {
            return true
        } else {
            return checkNested(obj[level], ...rest)
        }
    }
    if (rest.length == 0 && checkProp(obj, level)) {
        return true
    }
    return checkNested(obj[level], ...rest)
}

/**
 * Checks if the user is on mobile
 *
 * @returns {boolean} - true if mobile
 */
const checkMobile = () => {
    let check = false
    ;(function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true
    })(navigator.userAgent || navigator.vendor || window.opera)
    return check
}

/**
 * Returns index of hazard or false if not included in results
 *
 * @param {object} analysis - analysis object
 * @param {string} hazardName - name of the hazard in the analysis
 *
 * @returns {number|boolean} - Index of hazard or false
 */
const showHazard = (analysis, hazardName) => {
    if (checkNested(analysis, "inputs", "scenario", "hazards")) {
        return analysis.inputs.scenario.hazards.findIndex((hazard) => hazard.includes(hazardName))
    } else {
        return false
    }
}

/**
 * Returns index of freeze thaw hazard or false if not included in results
 * 
 * @param {object} analysis - analysis object
 * 
 * @returns {number|boolean} - Index of Freeze-thaw or false
 */
const showFreezeThaw = (analysis) => {
    if (checkNested(analysis, "inputs", "scenario", "hazards")) {
        return analysis.inputs.scenario.hazards.findIndex((hazard) => hazard.includes("freeze-thaw"))
    }
}

/**
 * Returns index of surface water flood hazard or false if not included in results
 * 
 * @param {object} analysis - analysis object
 * 
 * @returns {number|boolean} - Index of surface water flood or false
 */
const showSurfaceWater = (analysis) => {
    if (checkNested(analysis, "inputs", "scenario", "hazards")) {
        return analysis.inputs.scenario.hazards.findIndex((hazard) => hazard.includes("flood_surfacewater"))
    } else {
        return false
    }
}
/**
 * Returns index of cyclone wind hazard or false if not included in results
 * 
 * @param {object} analysis - analysis object
 * 
 * @returns {number|boolean} - Index of surface water flood or false
 */
const showCycloneWind = (analysis) => {
    if (checkNested(analysis, "inputs", "scenario", "hazards")) {
        return analysis.inputs.scenario.hazards.findIndex((hazard) => hazard.includes("cyclone_wind"))
    } else {
        return false
    }
}

/**
 * Recursively searches an object for a matching string, returns the path to the value in an array
 *
 * @param {object} object - Object to search
 * @param {string} searchTerm - value of bottom level property
 *
 * @returns {Array} array of key names of the path (strings)
 */
const getNestedObjectKeys = (object, searchTerm) => {
    let tempResult = []
    let result = []

    const searchItem = (item) => {
        let stringFound = false
        if (typeof item === "object" && !Array.isArray(item)) {
            Object.keys(item).forEach((key) => {
                if (typeof item[key] === "object") {
                    tempResult.push(key)
                    searchItem(item[key])
                }
                if (typeof item[key] === "string") {
                    stringFound = true
                    let searchAsRegEx = new RegExp(searchTerm, "gi")
                    if (item[key].match(searchAsRegEx)) {
                        result = [...tempResult]
                        result.push(item[key])
                        return
                    }
                }
            })
            if (result.length <= 0 && stringFound === true) {
                tempResult.pop()
            }
        } else if (Array.isArray(item)) {
            item.forEach((child) => {
                if (typeof child === "object") {
                    searchItem(child)
                }
                if (typeof child === "string") {
                    stringFound = true
                    if (child === searchTerm) {
                        result = [...tempResult]
                        result.push(child)
                        return
                    }
                }
            })
            if (result.length <= 0 && stringFound === true) {
                tempResult.pop()
            }
        }
    }

    Object.keys(object).forEach((item) => {
        tempResult = [item]
        searchItem(object[item])
    })

    return result
}

/**
 * returns object with the 'keyName' value from the bottom level of an object moved
 * to the top level, the path to that name contained within an array
 *
 * @param {object} object - archetype hierarchy object
 * @param {string} keyName - name of bottom level key
 *
 * @returns {object} - object of arrays keyed by name, path in array
 */
const getObjectKeyPath = (object, keyName) => {
    let tempResult = []
    let result = {}

    const searchItem = (item) => {
        if (typeof item === "object" && !Array.isArray(item)) {
            Object.keys(item).forEach((key) => {
                if (typeof item[key] === "object") {
                    tempResult.push(key)
                    searchItem(item[key])
                }
                if (keyName in item) {
                    result[item.name] = [...tempResult]
                    return
                }
            })
        } else if (Array.isArray(item)) {
            item.forEach((child) => {
                if (typeof child === "object") {
                    if (keyName in child) {
                        result[child.name] = [...tempResult]
                        return
                    }
                    searchItem(child)
                }
            })
        }
        tempResult.pop()
    }

    Object.keys(object).forEach((item) => {
        // TODO: add this back in if the top level is needed
        // tempResult = [item] 
        searchItem(object[item])
    })

    return result
}

const convertCamelCase = (camelString) => {
    return camelString.substring(0, 1) +
        camelString
            .substring(1)
            .replace(/([A-Z])/g, " $1")
            .toLowerCase()
}

export { 
    checkProp,
    checkNested,
    showHazard,
    checkMobile,
    getNestedObjectKeys,
    getObjectKeyPath,
    showFreezeThaw, 
    showSurfaceWater,
    showCycloneWind,
    convertCamelCase
}