import React from "react";
import autoBind from "react-autobind";
import { Modal } from "react-bootstrap";
import ReactGA from "react-ga";
import PropTypes from "prop-types";

import { hubSpotTracking } from "../../CR-Components/hubSpot.js";

import "./AboutModal.scss";
//Google analytics for react
ReactGA.initialize("UA-148155584-1");

class AboutModal extends React.Component {

    static propTypes = {
        onCancel: PropTypes.func
    }
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const { onCancel } = this.props;

        return (
            <Modal
                show={true}
                onHide={onCancel}
                onEntered={() => {
                    if (crConstants.production) ReactGA.modalview("/about");
                    window.document.title = "About Us";
                    hubSpotTracking("setPath", "/about", "trackPageView");
                }}
                id="aboutModal"
                className="aboutModal"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <a
                        className="powered-by-cr"
                        href="https://xdi.systems/"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <img
                            src="/static/images/xdi_logo.png"
                            width="40%"
                            align="right"
                            alt="XDI - The Cross Dependency Initiative"
                        />
                    </a>
                    <h1> About Us </h1>
                    <p>
                        <b>
                        Since 2006, organisations have turned to our team to help them understand the physical climate
                        risk to their assets, investments and business operations.
                        </b>
                    </p>

                    <p className="intro-text">
                        Easy XDI provides on-demand physical climate risk analysis for a single infrastructure or property
                        asset anywhere in the world. With engineering level detail on pre-programmed asset archetypes,
                        EasyXDI provides the worlds most detailed asset-level climate risk and adaptation analysis
                        available.
                    </p>

                    <p>
                        <h2>Other XDI Products</h2>
                        EasyXDI sits within the <a href="https://xdi.systems/">XDI Platform</a>, a suite of online
                        interfaces and data products that provide the same detailed analysis in application to
                    </p>
                    <p>
                        <ul>
                            <li>multiple assets</li>
                            <li>company portfolios</li>
                            <li>counterparties and equities</li>
                            <li>sovereign risk</li>
                        </ul>
                    </p>

                    <p>
                        <h2>Standard Reporting</h2>
                        <a href="https://xdi.systems/">Standard reports</a> present analysis results for differing
                        client needs. Metrics include:
                    </p>
                    <p>
                        <ul>
                            <li>Annual Average Loss</li>
                            <li>Productivity Loss</li>
                            <li>Insurability Rating (FEMA)</li>
                            <li>Failure Probability</li>
                            <li>Number and percentage of high-risk properties</li>
                            <li>Return frequencies and severities for weather events</li>
                            <li>Synthetic vulnerability curves - loss adjustment equations for portfolios of assets</li>
                        </ul>
                    </p>

                    <p>
                        <h2>Scale and Granularity</h2>
                        Powered by the award-winning Climate Risk Engines, XDI can assess climate risk to a single asset
                        or for millions of assets worldwide. Within that scale, clients can request deep granularity on
                        any single asset, or high-level aggregate information on a portfolio spread across multiple
                        geographies.
                    </p>

                    <p className="footer-info">
                        For more information go to <a href="https://xdi.systems/">xdi.systems</a> or contact us at <a href="mailto:info@xdi.systems">info@xdi.systems</a>
                    </p>
                </Modal.Body>
            </Modal>
        );
    }
}

export default AboutModal;
