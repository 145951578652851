import React from "react"
import autoBind from "react-autobind"

import "./AssetDetail.scss"

class AssetDetail extends React.Component {
    constructor(props) {
        super(props)
        autoBind(this)
    }

    render() {
        const { analysis } = this.props

        const getDataSet = () => {
            if ( "elevationDataSet" in analysis.inputs.asset.properties ) {
                return analysis.inputs.asset.properties.elevationDataSet
            } else {
                return "Source Unknown"
            }

        }

        return (
            <React.Fragment>
                <table className="table2">
                    <tbody>
                        <tr>
                            <th>I.D.</th>
                            <td>{analysis._id}</td>
                        </tr>
                        <tr>
                            <th>Address</th>
                            <td>{analysis.properties.address}</td>
                        </tr>
                        <tr>
                            <th>Archetype</th>
                            <td>{analysis.properties.archetype}</td>
                        </tr>
                        <tr>
                            <th>Latitude</th>
                            <td>{Math.round(analysis.geometry.coordinates[1] * 1e6) / 1e6}</td>
                        </tr>
                        <tr>
                            <th>Longitude</th>
                            <td>{Math.round(analysis.geometry.coordinates[0] * 1e6) / 1e6}</td>
                        </tr>
                        <tr>
                            <th>Elevation</th>
                            <td>{Math.round(analysis.inputs.asset.properties.elevation * 1e2) / 1e2 + "m"}</td>
                        </tr>
                        <tr>
                            <th>Elevation Dataset</th>
                            <td>{getDataSet()}</td>
                        </tr>
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}

export default AssetDetail
