import React from "react"
import autoBind from "react-autobind"
import { Button, Form, FormGroup, FormControl } from "react-bootstrap"
import ReactGA from "react-ga"

import { hubSpotTracking, hubSpotFormCreateAccount } from "../../CR-Components/hubSpot.js"
import accountManager from "../CoreFiles/account"
import Loading from "../../CR-Components/Loading.jsx"
import { CVLoading } from "../../CR-Components/Loading.jsx"
import tagManager from "../tagManager.js"

import "./Account.scss"

class LoginForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: "",
            password: "",
            passwordConfirm: "",
            verificationKey: "",
            valid: false,
            validated: false,
            error: null,
            message: null,
            forcePasswordRequired: false,
            mode: "login",
            isForgotPassword: false,
            loading: false,
            newsLetter: false,
            newsLetterValue: 1,
            showRegistrationFields: true,
        }
        autoBind(this)
    }
    verify() {
        this.setState({ valid: true })
        this.setState({ mode: "verify" }, () => {
            this.handleSubmit()
        })
    }

    async handleSubmit() {
        const { username, password, valid, mode, newsLetterValue } = this.state

        this.setState({ error: null, loading: true })
        if (valid) {
            try {
                let result
                if (mode == "login") {
                    result = await accountManager.login(username, password)
                    if (result) {
                        this.setState({ loading: false })
                        // eslint-disable-next-line no-undef
                        if (crConstants.ClimateValuation) {
                            tagManager("login_successful")
                            hubSpotTracking("setPath", "/login_successful", "trackPageView")
                        }
                        this.props.onSuccessfulLogin()
                    }
                } else if (mode == "verify") {
                    result = await accountManager.authenticate(
                        this.state.username,
                        this.state.verificationKey
                    )
                    if (result) {
                        this.props.onCancel()
                    }
                } else if (mode == "register") {
                    result = await accountManager.register({ username, password, newsLetterValue })
                    //   TODO: replace this with just the login function. Add the newsletter status
                    // This would help avoid fake contacts in the CRM
                    hubSpotFormCreateAccount(username, !!newsLetterValue)
                    //to end registration form loading once registration gets completed
                    this.reset()
                    this.setState({
                        showRegistrationFields: false,
                        loading: false,
                        forcePasswordRequired: false,
                        message:
                            "Account registered successfully. Please enter verification code sent to your email address.",
                    })
                    // eslint-disable-next-line no-undef
                    if (!crConstants.ClimateValuation) {
                        //   Tracking Code
                        ReactGA.event({
                            category: "User",
                            action: "Create an Account",
                            label: "register",
                            value: 2,
                        })
                    }
                    // eslint-disable-next-line no-undef
                    if (crConstants.ClimateValuation) {
                        tagManager("registration_successful")
                        hubSpotTracking("setPath", "/registration_successful", "trackPageView")
                    }
                    window.document.title = "Register"
                    hubSpotTracking("setPath", "/register", "trackPageView")
                }
                // end tracking
                else {
                    result = await accountManager.forgotPasswordRequest(username)
                    this.setState({
                        message: "We have sent an email with further instructions.",
                        loading: false,
                    })
                }

                if (result == "password required") {
                    this.setState({
                        forcePasswordRequired: true,
                        loading: false,
                    })
                }
            } catch (e) {
                this.setState({ error: e.message || "" + e })
                this.setState({ loading: false })
                console.log(e)
                if (e === "Please verify email") {
                    this.setState({ message: true, showRegistrationFields: false })
                }
            }
        }
        // eslint-disable-next-line no-undef
        if (!crConstants.ClimateValuation) {
            window.document.title = "EasyXDI"
        } else {
            window.document.title = "Climate Valuation"
        }
    }

    /**
     *
     * @param {string} field
     * @param {string|number|object|boolean} value
     */
    handleChange(field, value) {
        if (field == "verificationKey") {
            var re = /^[0-9]*$/
            if (re.test(value)) {
                this.setState({ [field]: value, validated: false })
            }
        } else {
            this.setState({ [field]: value, validated: false })
        }
        this.validateTimeout && clearTimeout(this.validateTimeout)
        this.validateTimeout = setTimeout(this.validate, 700)
        if (field === "newsLetter") {
            if (value === true) {
                this.setState({ newsLetterValue: 1 })
            } else {
                this.setState({ newsLetterValue: 0 })
            }
        }
    }

    componentDidMount() {
        this.validate();
    }

    componentWillUnmount() {
        clearTimeout(this.validateTimeout)
    }

    reset() {
        this.setState({
            passwordConfirm: "",
            validated: false,
            error: null,
            message: null,
            forcePasswordRequired: false,
            mode: "login",
            isForgotPassword: false,
            loading: false,
            newsLetter: true,
            newsLetterValue: 1,
            showRegistrationFields: true,
        })
    }

    isComplete() {
        const {
            username,
            password,
            passwordConfirm,
            valid,
            error,
            forcePasswordRequired,
            mode,
            validated,
        } = this.state
        const { noPassword, loginButtonText, showRegister, onCancel, CV } = this.props
        const passwordRequired = forcePasswordRequired || !noPassword
        return !!(
            username &&
            (mode == "forgot" ||
                ((!passwordRequired || password) &&
                    (!passwordRequired || mode == "login" || passwordConfirm)))
        )
    }

    validate() {
        if (this.isComplete()) {
            const passwordsNotMatch =
                this.state.mode == "register" &&
                this.state.passwordConfirm &&
                this.state.password != this.state.passwordConfirm
            const valid = !!this.form && this.form.checkValidity() === true && !passwordsNotMatch
            this.setState({
                valid,
                error: passwordsNotMatch ? "Passwords do not match" : null,
                validated: true,
            })
        } else {
            this.setState({ valid: false })
        }
    }

    /**
     *
     * @param {string} mode
     */
    setMode(mode) {
        this.setState({ mode })
        this.validateTimeout && clearTimeout(this.validateTimeout)
        this.validateTimeout = setTimeout(this.validate, 0)
    }

    render() {
        const {
            verificationKey,
            username,
            password,
            passwordConfirm,
            valid,
            error,
            message,
            forcePasswordRequired,
            mode,
            validated,
            loading,
            showRegistrationFields,
        } = this.state
        const {
            noPassword,
            loginButtonText,
            showRegister,
            onCancel,
            noPasswordText,
            resetForm,
            CV,
        } = this.props
        // eslint-disable-next-line no-undef
        const crConstantFlag = `${crConstants.ClimateValuation}`
        return (
            <Form
                onSubmit={(e) => e.preventDefault()}
                className="LoginForm"
                ref={(form) => {
                    this.form = form
                }}
                noValidate
                validated={validated}>
                {!!showRegistrationFields && (
                    <FormGroup controlId="formBasicEmail">
                        <FormControl
                            type="email"
                            value={username}
                            onChange={(e) => this.handleChange("username", e.target.value)}
                            placeholder="Email address"
                            required
                            disabled={loading}
                        />
                    </FormGroup>
                )}

                {!!showRegistrationFields &&
                    mode != "forgot" &&
                    (forcePasswordRequired || !noPassword) && (
                        <React.Fragment>
                            <FormGroup controlId="formBasicPassword">
                                <FormControl
                                    type="password"
                                    value={password}
                                    onChange={(e) => this.handleChange("password", e.target.value)}
                                    placeholder="Password"
                                    required
                                    disabled={loading}
                                />
                            </FormGroup>

                            {!!showRegistrationFields && mode == "register" && (
                                <FormGroup controlId="formBasicPasswordConfirm">
                                    <FormControl
                                        type="password"
                                        value={passwordConfirm}
                                        onChange={(e) =>
                                            this.handleChange("passwordConfirm", e.target.value)
                                        }
                                        placeholder="Confirm password"
                                        required
                                        disabled={loading}
                                    />
                                    <Form.Group controlId="formBasicCheckbox">
                                        <Form.Check
                                            type="checkbox"
                                            label="Keep me updated with the latest news and announcements"
                                            checked={this.state.newsLetter}
                                            onChange={(e) =>
                                                this.handleChange(
                                                    "newsLetter",
                                                    !this.state["newsLetter"],
                                                    e
                                                )
                                            }
                                            disabled={loading}
                                        />
                                    </Form.Group>
                                </FormGroup>
                            )}
                        </React.Fragment>
                    )}

                {mode == "forgot" && !message && (
                    <p>Enter your email address and we'll send you further instructions.</p>
                )}

                {!forcePasswordRequired && noPassword && noPasswordText && <p>{noPasswordText}</p>}

                {!!message && <div className="message">{message}</div>}

                {!!error && validated && <div className="error">{error}</div>}

                {!!showRegistrationFields && mode === "login" && (
                    <span className="forgot_password" onClick={() => this.setMode("forgot")}>
                        Forgot Password?
                    </span>
                )}

                <div className="buttons">
                    {crConstantFlag == "true"
                        ? !message && loading && <CVLoading />
                        : !message && loading && <Loading />}

                    {!message && !loading && (
                        <Button
                            type="submit"
                            className={`${CV ? "btn-orange" : "btn-primary"}`}
                            onClick={this.handleSubmit}
                            disabled={!valid}>
                            {mode == "register"
                                ? "Register"
                                : mode == "forgot"
                                ? "Submit"
                                : loginButtonText || "Login"}
                        </Button>
                    )}

                    {onCancel && !message && (
                        <Button
                            variant="secondary"
                            className="btn-secondary"
                            onClick={onCancel}
                            disabled={loading}>
                            Cancel
                        </Button>
                    )}
                    {onCancel && message && (
                        <React.Fragment>
                            <FormGroup controlId="formBasicEmail">
                                <FormControl
                                    value={verificationKey}
                                    onChange={(e) =>
                                        this.handleChange("verificationKey", e.target.value)
                                    }
                                    placeholder="Verification Code"
                                    required
                                    disabled={loading}
                                />
                            </FormGroup>
                        </React.Fragment>
                    )}
                    {onCancel && message && (
                        <Button
                            variant="secondary"
                            className="btn-secondary"
                            onClick={() => {
                                this.verify()
                            }}
                            disabled={loading}>
                            Verify
                        </Button>
                    )}
                </div>

                {!!showRegistrationFields && !message && (forcePasswordRequired || !noPassword) && (
                    <div>
                        {mode === "register" && (
                            <>
                                <p className="login-text">
                                    Please create an account or{" "}
                                    <a onClick={() => this.setState({ mode: "login" })}>
                                        Log In to continue
                                    </a>.
                                </p>
                            </>
                        )}
                        {mode === "login" && (
                            <>
                                <p className="login-text">
                                    New User?{" "}
                                    <a onClick={() => this.setState({ mode: "register" })}>
                                        Click here to register
                                    </a>.
                                </p>
                            </>
                        )}
                        {/* <div className="buttons"> */}
                        {/* <Button
                                variant="secondary account_login-type"
                                onClick={() => this.setMode("login")}
                                disabled={loading || mode == "login"}
                                title="I have an account"
                            >
                                Existing account
                            </Button>

                            <Button
                                variant="secondary account_login-type"
                                onClick={() => this.setMode("register")}
                                disabled={loading || mode == "register"}
                                title="I want to create an account"
                            >
                                New account
                            </Button> */}

                        {/* <Button
                                variant="secondary"
                                onClick={() => this.setMode("forgot")}
                                disabled={loading || mode == "forgot"}
                                title="I forgot or do not know my password"
                            >
                                Forgot password
                            </Button> */}
                        {/* </div> */}
                    </div>
                )}
            </Form>
        )
    }
}

export default LoginForm
