import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button } from "react-bootstrap";
import { MdCheck, MdClear, MdEdit } from "react-icons/md";

class EIBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            loading: false,
            invalid: false,
            newValue: this.props.value,
        };
    }

    startEditing = (event) => {
        if (event && event.stopPropagation) event.stopPropagation();
        this.props.beforeStart && this.props.beforeStart();
        this.beforeStart && this.beforeStart();
        if (this.props.disabled) return;
        this.setState({ editing: true });
        setTimeout(() => this.ref && this.ref.focus(), 250);
        this.props.afterStart ? this.props.afterStart() : null;
    };

    finishEditing = () => {
        let newValue = this.state.newValue;
        if ((!this.props.value && !newValue) || this.props.value == newValue) {
            this.props.beforeFinish ? this.props.beforeFinish(newValue) : null;
            this.cancelEditing();
        } else {
            const isValid = this.doValidations(newValue);
            if (isValid) {
                this.commit();
            } else if (this.props.handleValidationFail) {
                this.props.handleValidationFail(newValue, () => this.cancelEditing());
            }
            this.props.afterFinish ? this.props.afterFinish(isValid, newValue) : null;
            this.afterFinish && this.afterFinish(isValid ? newValue : this.props.value);
        }
    };

    cancelEditing = () => {
        this.setState({
            editing: false,
            invalid: false,
            validationMessage: null,
            newValue: this.props.value,
        });
        this.afterFinish && this.afterFinish(this.props.value);
    };

    valueChanged = (value) => {
        this.setState({ newValue: value });
        this.doValidations(value);
    };

    doValidations = (value) => {
        let validationResult;
        if (this.props.validate) {
            validationResult = this.props.validate(value);
        } else if (this.validate) {
            validationResult = this.validate(value);
        }
        const isValid =
            typeof validationResult == "string" ? validationResult.length == 0 : !!validationResult;
        this.setState({
            invalid: !isValid,
            validationMessage: typeof validationResult == "string" ? validationResult : null,
        });
        return isValid;
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.value != this.props.value) {
            this.setState({ newValue: nextProps.value });
        }
    };

    commit = async () => {
        if (!this.state.invalid) {
            this.setState({ loading: true });
            await this.props.change(this.state.newValue);
            this.setState({ loading: false, editing: false });
        }
    };

    // makeClassString = () => {
    //   var classNames = [this.props.inputType];
    //   if (this.props.className) classNames.push(this.props.className);
    //   if (this.state.editing && this.props.classEditing) classNames.push(this.props.classEditing);
    //   if (this.state.loading && this.props.classLoading) classNames.push(this.props.classLoading);
    //   if (this.props.disabled && this.props.classDisabled) classNames.push(this.props.classDisabled);
    //   if (this.state.invalid && this.props.classInvalid) classNames.push(this.props.classInvalid);
    //   return classNames.join(' ');
    // };

    render = () => {
        const { loading, editing, newValue, invalid, validationMessage } = this.state;
        const {
            disabled,
            className,
            inputType,
            classEditing,
            classLoading,
            classDisabled,
            classInvalid,
            children,
            additionalElements,
            buttonsPosition,
        } = this.props;

        const buttonsBelow =
            inputType == "textarea" || inputType == "wysiwyg" || buttonsPosition == "below";

        const classes = classNames(
            className,
            buttonsBelow && "buttonsbelow",
            inputType,
            editing && (classEditing || "editing"),
            loading && (classLoading || "loading"),
            disabled && (classDisabled || "disabled"),
            invalid && (classInvalid || "invalid")
        );

        const hideButtons = this.hideButtons && this.hideButtons();

        const buttonCommit = !hideButtons && (
            <Button
                variant="success"
                title="Commit changes."
                onClick={this.finishEditing}
                tabIndex="1"
            >
                <MdCheck />
            </Button>
        );
        const buttonCancel = !hideButtons && (
            <Button
                variant="danger"
                title="Abandon changes."
                onClick={this.cancelEditing}
                tabIndex="2"
            >
                <MdClear />
            </Button>
        );

        if (editing) {
            return (
                <div className={classes}>
                    <div className="editinline-inner" onClick={(e) => e.stopPropagation()}>
                        <div className="input-wrap">
                            {this.renderEditingComponent()}
                            {!buttonsBelow && !invalid && buttonCommit}
                            {!buttonsBelow && buttonCancel}
                            {additionalElements && additionalElements(editing, newValue, invalid)}
                        </div>
                        {(!!validationMessage || buttonsBelow) && (
                            <div className="validation-wrap">
                                <div className="validation-message">{validationMessage}</div>
                                {buttonsBelow && !invalid && buttonCommit}
                                {buttonsBelow && buttonCancel}
                                {additionalElements && additionalElements(true, newValue, invalid)}
                            </div>
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <span className={classes}>
                    {children || this.renderNormalComponent()}
                    {!disabled && (
                        <div className="editinline-controls">
                            <div className="edit-icon" onClick={this.startEditing} tabIndex="0">
                                <MdEdit size="1.2em" />
                            </div>
                            {additionalElements && additionalElements(false, newValue, invalid)}
                        </div>
                    )}
                </span>
            );
        }
    };
}

EIBase.propTypes = {
    inputType: PropTypes.string.isRequired,
    value: PropTypes.any,
    change: PropTypes.func.isRequired,
    propName: PropTypes.string.isRequired,
    editProps: PropTypes.object,
    defaultProps: PropTypes.object,
    disabled: PropTypes.bool,
    validate: PropTypes.func,
    handleValidationFail: PropTypes.func,
    shouldBlockWhileLoading: PropTypes.bool,
    shouldRemainWhileInvalid: PropTypes.bool,
    classLoading: PropTypes.string,
    classEditing: PropTypes.string,
    classDisabled: PropTypes.string,
    classInvalid: PropTypes.string,
    className: PropTypes.string,
    beforeStart: PropTypes.func,
    afterStart: PropTypes.func,
    beforeFinish: PropTypes.func,
    afterFinish: PropTypes.func,
    additionalElements: PropTypes.func,
    buttonsPosition: PropTypes.string,
    // For select.
    options: PropTypes.arrayOf(PropTypes.object),
    valueKey: PropTypes.string,
    labelKey: PropTypes.string,
};

export default EIBase;
